import { TrapType } from "./config";
import { getAnimName, getSkinList } from "./spineutils";

export default class ImpactPlayer {
  x: number;
  y: number;
  scale: number;
  public sprite: any;
  constructor(scene: Phaser.Scene, type: TrapType, x: number, y: number) {
    this.x = x;
    this.y = y;
    this.sprite = scene.make
      // @ts-ignore
      .spine({
        x,
        y,
        key: "impactAnimation",
        loop: false,
      })
      .setDepth(3);
    // // @ts-ignore
    let mainSkin = this.sprite.skeleton.data.findSkin("default");
    this.sprite.skeleton.setSkin(mainSkin);
    let anim = "";
    switch (type) {
      case TrapType.Confused:
        anim = "Force_impact";
        break;
      case TrapType.Electric:
        anim = "Electric_impact";
        break;
      case TrapType.Ice:
        anim = "Ice_impact";
        break;
    }
    this.sprite.play(anim, false);
  }
}
