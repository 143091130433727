import CustomButton, { ArtButton } from "components/button";
import dayjs from "dayjs";
import { CustomerInterface, Rarity, RewardEntity, TicketRarity } from "interfaces";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { Card, Header } from "semantic-ui-react";
import { api, ui, utils } from "services";
import { RewardsDisplay } from "./rewards";
import { Ticket } from "./ticket";
import './index.css'

const RewardLayout = ({
    reward,
    icon,
    isMoney = false,
    src,
}: {
    reward: RewardEntity;
    icon?: string;
    isMoney?: boolean;
    src?: string;
}) => {
    return (
        <div>
            <div className="art-btn-yellow-content-reverse reward-box-shadow rounded-2xl reward-border border-2">
                <div className="font-cookie-run text-white-light-f text-border-brown py-2">
                    {utils.getRewardName(reward)}
                </div>
                <div className="w-[109px] h-[109px] bg-reward justify-center rounded-2xl">
                    <div className="w-full h-full top-0 left-0 flex items-center justify-center">
                        <img
                            src={src ?? `/assets/images/items/${icon}`}
                            className={`object-cover h-[56px] -translate-y-2`}
                            alt="reward"
                        />
                        {reward.rarity != null && (
                            <div className="text-red cursor-pointer absolute translate-x-11 -translate-y-8">
                                <img
                                    src={utils.getRarityIcon(reward.rarity)}
                                    alt="rarity"
                                    className="w-6 h-6"
                                />
                            </div>
                        )}
                    </div>
                    <div className="font-cookie-run -translate-y-6">
                        x{" "}
                        {isMoney
                            ? utils.convertToInternationalCurrencySystem(
                                reward.reward_quantity
                            )
                            : reward.reward_quantity}
                    </div>
                </div>
            </div>
            <div className="text-lg font-bold mt-1">{reward.rate} prizes</div>
        </div>
    );
};


export default function RafflePage() {
    const user = useSelector((state: RootState) => state.user);
    const [poolInfos, setPoolInfos] = useState([]);
    const [event, setEvent] = useState(null);
    const [trigger, setTrigger] = useState(false);
    const [site, setSite] = useState("");
    const [overRare, setOverRare] = useState(-1);
    const [time, setTime] = useState(0);
    const [isOn, setIsOn] = useState(false);
    const [openStakeUnit, setOpenStake] = useState("");
    const [openUnstakeUnit, setOpenUnstake] = useState("");

    const ticketData = [{
        field: "ticket_co",
        icon: "ticket-co.svg",
        title: "Common ticket",
        rarity: TicketRarity.Common
    }, {
        field: "ticket_uc",
        icon: "ticket-uc.svg",
        title: "Uncommon ticket",
        rarity: TicketRarity.Uncommon
    }, {
        field: "ticket_ra",
        icon: "ticket-ra.svg",
        title: "Rare ticket",
        rarity: TicketRarity.Rare
    }, {
        field: "ticket_ep",
        icon: "ticket-ep.svg",
        title: "Epic ticket",
        rarity: TicketRarity.Epic
    }, {
        field: "ticket_ge",
        icon: "ticket-ge.svg",
        title: "General ticket",
        rarity: TicketRarity.General
    }, {
        field: "ticket_gl",
        icon: "ticket-gl.svg",
        title: "Global ticket",
        rarity: TicketRarity.Global
    }
    ]

    useEffect(() => {
        api.getTicketPool().then((data: any) => {
            setPoolInfos(data);
        }).catch(console.log);
    }, [trigger]);

    useEffect(() => {
        api.getCurrentEvent().then((data: any) => {
            setEvent(data);
            setTime(dayjs(data.end_time).diff(dayjs(), "second"));
        }).catch(console.log);
        api.getPublicConfig().then((data: any) => {
            setSite(data["PUBLIC_CONFIG_EVENT_WINNER_URL"] ?? "");
        }).catch(console.log);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsOn(!isOn);
        }, 1000);
        return () => clearInterval(interval);
    }, [isOn]);

    useEffect(() => {
        let end_time = event?.end_time;
        end_time && dayjs(end_time).isAfter(dayjs()) && setTime(dayjs(end_time).diff(dayjs(), "second"));
    }, [isOn]);

    return (
        <div className="w-full dao-container" id="resize-content">
            <Header className="text-center">
                <span className="text-white text-3xl font-semibold">
                    Try your luck!
                </span>
            </Header>
            {event && (
                <div className="w-full text-white-light mt-2 text-center mx-auto">
                    Event duration: {utils.displayTime(event?.start_time)} -{" "}
                    {utils.displayTime(event?.end_time)}
                </div>
            )}
            <div className="mt-7 mb-10">
                <Card
                    fluid
                    style={{ background: "transparent", boxShadow: "none" }}
                    description={
                        <div className="grid grid-cols-12 gap-5">
                            <div className="col-span-12 lg:col-span-5 grid grid-cols-2 gap-5">
                                {ticketData.map((item) =>
                                    <Ticket
                                        key={item.title}
                                        title={item.title}
                                        icon={item.icon}
                                        // @ts-ignore
                                        amount={user[CustomerInterface[item.field]]}
                                        rarity={item.rarity}
                                        overRare={overRare}
                                        setOverRare={setOverRare}
                                        trigger={trigger}
                                        setTrigger={setTrigger}
                                        event={event}
                                        setOpenStake={() => setOpenStake(item.field)}
                                        setOpenUnstake={() => setOpenUnstake(item.field)}
                                        poolInfos={poolInfos}
                                    />
                                )}
                            </div>
                            <div className="col-span-12 lg:col-span-7 bg-black-secondary rounded-xl">
                                <div className="card-header text-white-light bg-brown py-3 px-6 rounded-t-xl flex justify-between">
                                    <div>Rewards pool</div>
                                    <div>
                                        <CustomButton
                                            disabled={!site}
                                            onClick={() => {
                                                if (site) {
                                                    window.open(site, "_blank");
                                                }
                                            }}
                                            color="yellow"
                                            className="w-[140px] mx-auto text-center"
                                        >
                                            View draw result
                                        </CustomButton>
                                    </div>
                                </div>
                                {event && (
                                    <div className="text-white w-full text-center mt-4">
                                        Draw time countdown:{" "}
                                        <div className="block md:inline-block text-red font-semibold">
                                            {utils.getTimeDisplay(time)}
                                        </div>
                                    </div>
                                )}
                                <div className={`grid grid-cols-1 md:grid-cols-2 gap-4 text-white-light pb-4 pt-1 rounded-b-lg px-6 h-max-[500px] ${event && "overflow-y-scroll"}`}>
                                    {event ? (
                                        poolInfos.map((poolInfo: any, i: number) => {
                                            if (i !== TicketRarity.Legendary) {
                                                return (
                                                    <div
                                                        key={`pool-${i}`}
                                                        className="bg-brown-bold w-full mt-8 rounded-xl pb-6"
                                                    >
                                                        <div className="bg-brown font-cookie-run p-4 ml-4 rounded-lg inline-block -translate-y-6">
                                                            {utils.getTicketRarityName(i)} rewards
                                                        </div>
                                                        <div className="grid grid-cols-3 2xl:grid-cols-4 mx-4 gap-4">
                                                            <img
                                                                src={`/assets/images/box/box_${i + 1}.png`}
                                                                alt="highest-level"
                                                                className="w-4/5 inline-block mr-1.5"
                                                            />
                                                            <div className="font-cookie-run font-semibold text-md col-span-2">
                                                                <div>
                                                                    Current chance: {(poolInfo.chance * 100).toFixed(2)} %
                                                                </div>
                                                                <div>
                                                                    Owning submitted: {poolInfo.ownedSubmmitted}
                                                                </div>
                                                                <div>
                                                                    Total submitted: {poolInfo.totalSubmitted}
                                                                </div>
                                                            </div>
                                                            <div className="2xl:col-span-1 mx-auto col-span-3 w-[100px]">
                                                                <ArtButton
                                                                    color="yellow"
                                                                    disabled={!poolInfo?.rewards?.length}
                                                                    onClick={() => {
                                                                        ui.showPopup(
                                                                            <div>
                                                                                <div className="text-xl font-extrabold">
                                                                                    {utils.getTicketRarityName(i)} rewards
                                                                                </div>
                                                                                <div className="text-md mt-1 font-normal">
                                                                                    Total:{" "}
                                                                                    {poolInfo.rewards.reduce(
                                                                                        (prev: any, cur: any) =>
                                                                                            prev + cur.rate,
                                                                                        0
                                                                                    )}{" "}
                                                                                    prizes
                                                                                </div>
                                                                            </div>,
                                                                            <div className="mx-auto w-4/5 h-full gap-y-12 mt-10 flex flex-wrap gap-4 items-center justify-center">
                                                                                <RewardsDisplay rewards={poolInfo.rewards} RewardLayout={RewardLayout} />
                                                                            </div>
                                                                        );
                                                                    }}
                                                                >
                                                                    Rewards list
                                                                </ArtButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }
                                        )
                                    ) : (
                                        <div className="w-full col-span-2 relative h-full min-h-[300px]">
                                            <div className="w-full text-center text-2xl translate-y-10">
                                                Rewards pool will come back in the next event
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    );
}
