import Config from "config";
import { SocketAction } from "interfaces";
import io from "socket.io-client";

let socket = io(Config.EventApiHost, { transports: ["websocket"] });
let callback: any = {};

socket.on("res", (msg) => {
  if (callback[msg.id]) {
    if (msg.code) {
      callback[msg.id](msg.msg, msg.rs);
    } else {
      callback[msg.id](msg.code, msg.rs);
    }
  }
});
socket.on("connect", () => {
  let token = localStorage.getItem("token");
  if (token) {
    authenticate(token);
  }
  console.log("connect");
});
socket.on("disconnect", () => {
  console.log("disconnect");
});

function genId() {
  return new Date().getTime() + "";
}

const getInstance = () => {
  if (!socket) {
    console.log(Config.EventApiHost);
    socket = io(Config.EventApiHost);
  }
  if (!socket.connected) {
    socket.connect();
  }
  return socket;
};

async function request(action: any, payload: any) {
  console.log(`request `, { action, payload });
  return new Promise((resolve, reject) => {
    let id = genId();
    callback[id] = (error: any, data: any) => {
      if (error) {
        console.log({ error });
        reject(error);
      } else {
        resolve(data);
        console.log(`res `, { data });
      }
    };
    socket.emit("msg", { action, id, payload });
  });
}

async function authenticate(token: string) {
  let rs = await request(SocketAction.Authen, { token });
  return rs;
}

async function getActiveEvent() {
  let rs = await request(SocketAction.GetActiveEvent, {});
  return rs;
}

async function getListProducts(
  offset: number,
  limit: number,
  rarity?: number,
  customer_id?: number
) {
  let rs = await request(SocketAction.GetListProducts, {
    skip: offset,
    limit,
    rarity: rarity !== null ? rarity : undefined,
    customer_id,
  });
  return rs;
}

async function joinQueue(
  event_id: string | number,
  position?: string | number
) {
  let rs = await request(SocketAction.JoinQueue, {
    event_id,
    position,
  });
  return rs;
}

async function confirm() {
  let rs = await request(SocketAction.Spin, {});
  return rs;
}

// async function reset() {
//   let rs = await request(SocketAction.ResetEvent, {});
//   return rs;
// }

async function cancel() {
  let rs = await request(SocketAction.Cancel, {});
  return rs;
}

const socketService = {
  getInstance,
  authenticate,
  getActiveEvent,
  getListProducts,
  joinQueue,
  confirm,
  // reset,
  cancel,
};

export default socketService;
