import Slider from 'rc-slider';
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { Modal } from "semantic-ui-react";
import { ModalType, removeModal } from "reducer/modals.slice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ArtButton } from "components/button";
import { PasswordInput } from "components/password-input";
import { useNavigate } from "react-router-dom";
import { TextInput } from "components/text-input";
export default function ModalManager() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modals = useSelector((state: RootState) => state.modals);
  const [confirmInput, setConfirmInput] = useState("");
  const [textInput, setTextInput] = useState("");
  const [email, setEmail] = useState("");
  const [formTextLoading, setFormTextLoading] = useState(false);
  const [actionFormLoading, setActionFormLoading] = useState(false);
  const [formInputLoading, setFormInputLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [amount, setAmount] = useState(1);
  const [formTextErr, setFormTextErr] = useState("")
  const nav = useNavigate();
  function closeModal(id: number) {
    dispatch(removeModal(id));
  }
  const confirmPass = (i: any) => {
    switch (i.confirmType) {
      case 'code':
        return confirmInput.length <= 0
      case 'password':
        return confirmInput.length <= 0
      default:
        return confirmInput !== 'confirm'
    }
  }
  function renderModal(i: any) {
    switch (i.type) {
      case ModalType.Alert:
      case ModalType.Popup:
        return (
          <Modal
            key="popup"
            open={true}
            style={{ width: "unset" }}
          >
            <div
              key={i.id}
              className="w-[650px]"
            >
              <div className="w-[650px] bg-black-secondary pb-8">
                <div>
                  <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
                    {i.title ?? "Alert"}
                  </div>
                  <img src="/assets/images/hr.svg" alt="hr" className="w-full object-cover h-1 mb-9" />
                  <div className="text-center text-white">
                    {i.msg}
                  </div>
                  <div className="flex mt-10 mb-1 text-center w-1/3 items-center mx-auto">
                    <ArtButton color="yellow"
                      onClick={() => {
                        closeModal(i.id);
                      }}
                    >
                      Close
                    </ArtButton>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        );
      case ModalType.Success:
        return (
          <Modal
            key="success-2"
            open={true}
            style={{ width: "unset" }}
          >
            <div
              key={i.id}
              className="w-[514px] alert-popup"
            >
              <div className="w-[514px] bg-black-secondary pb-8">
                <div>
                  <div className="py-8">
                    <img src="/assets/images/icons/success-icon.png" className="mx-auto" alt="success" />
                  </div>
                  <div className="text-center text-white">
                    <div className="font-extrabold text-white text-xl main-font-bold">{i.title}</div>
                    <div className="text-sm mt-1">
                      {i.msg}
                    </div>
                  </div>
                  <div className="flex mt-9 mb-1 text-center w-1/3 items-center mx-auto">
                    <ArtButton color="yellow"
                      onClick={() => {
                        closeModal(i.id);
                      }}
                    >
                      Close
                    </ArtButton>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        );
      case ModalType.Closable:
        return (
          <Modal
            key="success"
            open={true}
            style={{ width: "unset" }}
          >
            <div
              key={i.id}
              className="w-[514px] alert-popup"
            >
              <div className="w-[514px] bg-black-secondary pb-8">
                <div className="text-red cursor-pointer absolute float-right -right-2 -top-2"
                  onClick={() => {
                    closeModal(i.id);
                  }}
                >
                  <img src="/assets/images/icons/close-icon.svg" alt="close" className="" />
                </div>
                <div>
                  <div className="py-8">
                    <img src="/assets/images/icons/success-icon.png" className="mx-auto" alt="success" />
                  </div>
                  <div className="text-center text-white">
                    <div className="font-extrabold text-white text-xl main-font-bold">{i.title}</div>
                    <div className="text-sm mt-1">
                      {i.msg}
                    </div>
                  </div>
                  <div className="flex mt-9 mb-1 text-center w-1/3 items-center mx-auto">
                    <ArtButton color="yellow"
                      onClick={i.buttonFunc}
                    >
                      {i.buttonText}
                    </ArtButton>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        );
      case ModalType.Failed:
        return (
          <Modal
            key={`failed${i.id}`}
            open={true}
            style={{ width: "unset" }}
          >
            <div
              key={i.id}
              className="max-w-[514px] w-screen alert-popup"
            >
              <div className="max-w-[514px] w-screen bg-black-secondary pb-8">
                <div>
                  <div className="py-8">
                    <img src="/assets/images/icons/failed-icon.png" className="mx-auto" alt="failed" />
                  </div>
                  <div className="text-center text-white">
                    <div className="font-extrabold text-white text-xl main-font-bold">{i.title}</div>
                    <div className="text-sm mt-1">
                      <div dangerouslySetInnerHTML={{ __html: i.msg }}></div>
                      {/* {i.msg} */}
                    </div>
                  </div>
                  <div className="flex mt-9 mb-1 text-center w-1/3 items-center mx-auto">
                    <ArtButton color="yellow"
                      onClick={() => {
                        closeModal(i.id);
                      }}
                    >
                      Close
                    </ArtButton>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        );
      case ModalType.Confirm:
        return (
          <Modal
            key="confirm"
            open={true}
            style={{ width: "unset" }}
          >
            <div
              key={i.id}
              className="max-w-[564px] w-screen"
            >
              <div className="max-w-[564px] w-screen bg-black-secondary pb-8">
                <div>
                  <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
                    {i.title ?? "Confirm"}
                  </div>
                  <img src="/assets/images/hr.svg" alt="hr" className="w-full object-cover h-1 mb-9" />
                  <div className="text-center text-white">
                    {i.msg}
                  </div>
                  <div className="flex w-[360px] gap-4 mt-9 text-center items-center mx-auto">
                    <ArtButton color="red"
                      className="py-2.5"
                      onClick={() => {
                        i.cb(false);
                        closeModal(i.id);
                        // i.additionFunc && i.additionFunc()
                      }}
                    >
                      Cancel
                    </ArtButton>
                    <ArtButton color="yellow"
                      className="py-2.5"
                      onClick={() => {
                        i.cb(true);
                        closeModal(i.id);
                        i.additionFunc && i.additionFunc()
                      }}
                    >
                      Confirm
                    </ArtButton>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        );
      case ModalType.ActionConfirm:
        return (
          <Modal
            key="actionConfirm"
            open={true}
            style={{ width: "unset" }}
          >
            <div
              key={i.id}
              className="max-w-[564px] w-screen"
            >
              <div className="max-w-[564px] w-screen bg-black-secondary pb-8">
                <div>
                  <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
                    {i.title ?? "Confirm action"}
                  </div>
                  <img src="/assets/images/hr.svg" alt="hr" className="w-full object-cover h-1 mb-7" />
                  <div className="w-11/12 mx-auto">
                    <div className="text-center text-white">
                      {i.confirmMsg}
                    </div>
                  </div>
                  <div className="w-11/12 mx-auto">
                    <div className={`text-center text-white flex mx-auto justify-center w-full overflow-hidden rounded-lg relative`}>
                      {/* <div className="flex absolute inset-y-0 left-0 pl-3 pointer-events-none flex-col">
                        <span className="text-gray text-sm mt-1 capitalize">{i.fieldName}</span>
                      </div> */}
                      {i.confirmType === 'password' ?
                        <PasswordInput
                          submit={() => {
                            i.cb(confirmInput);
                            closeModal(i.id);
                            setConfirmInput("");
                          }}
                          password={confirmInput}
                          setPassword={setConfirmInput} fieldName="Password"
                          className="text-md block w-full text-black font-medium"
                        />
                        : <TextInput
                          value={confirmInput} setValue={setConfirmInput}
                          className="text-md block w-full text-black font-medium"
                          fieldName=""
                          placeholder={i.confirmType === 'code' ? 'Enter verification code' : 'confirm'}
                        />
                      }
                    </div>
                  </div>
                  <div className="flex w-[360px] gap-4 mt-6 text-center items-center mx-auto">
                    <ArtButton color="red"
                      disabled={actionFormLoading}
                      onClick={() => {
                        setConfirmInput("");
                        closeModal(i.id);
                      }}
                    >
                      <div className="text-white font-semibold">
                        Cancel
                      </div>
                    </ArtButton>
                    <ArtButton color="yellow"
                      disabled={confirmPass(i)}
                      onClick={async () => {
                        setActionFormLoading(true);
                        await i.cb(confirmInput);
                        setActionFormLoading(false);
                        closeModal(i.id);
                        setConfirmInput("");
                      }}
                      loading={actionFormLoading}
                    >
                      <div className="text-white font-semibold">
                        Confirm
                      </div>
                    </ArtButton>
                  </div>
                </div>
              </div>
            </div>
          </Modal >
        );
      case ModalType.FormTextInput:
        return (
          <Modal
            key="formText"
            open={true}
            style={{ width: "unset" }}
          >
            <div
              key={i.id}
              className="max-w-[500px] w-screen"
            >
              <div className="max-w-[500px] w-screen bg-black-secondary pb-8">
                <div>
                  <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
                    {i.title ?? ""}
                  </div>
                  <img src="/assets/images/hr.svg" alt="hr" className="w-full object-cover h-1 mb-9" />
                  <div className="w-11/12 mx-auto">
                    <div className="text-center text-white flex mx-auto justify-center w-full overflow-hidden rounded-lg border-orange relative">
                      <TextInput
                        submit={async () => {
                          setFormTextLoading(true)
                          await i.cb(textInput)
                          setFormTextLoading(false)
                          closeModal(i.id);
                          setTextInput("");
                        }}
                        setErr={setFormTextErr}
                        type={i.fieldType ?? "text"}
                        className="w-full"
                        value={textInput} setValue={setTextInput} fieldName={i.fieldName} />
                    </div>
                  </div>
                  {i.confirmMsg && <div className="w-11/12 my-3 mb-10 mx-auto">
                    <div className="text-white text-left">
                      {i.confirmMsg}
                    </div>
                  </div>}
                  <div className="flex w-[360px] gap-4 mt-5 text-center items-center mx-auto">
                    <ArtButton color="red"
                      disabled={formTextLoading}
                      onClick={() => {
                        setTextInput("");
                        closeModal(i.id);
                        setFormTextLoading(false);
                      }}
                    >
                      <div className="text-white">
                        Cancel
                      </div>
                    </ArtButton>
                    <ArtButton color="yellow"
                      disabled={!!formTextErr}
                      loading={formTextLoading}
                      onClick={async () => {
                        setFormTextLoading(true)
                        await i.cb(textInput)
                        setFormTextLoading(false)
                        closeModal(i.id);
                        setTextInput("");
                      }}
                    >
                      <div className="text-white">
                        {i.buttonText ?? "Save"}
                      </div>
                    </ArtButton>
                  </div>
                </div>
              </div>
            </div>
          </Modal >
        );
      case ModalType.EmailForm:
        return (
          <Modal
            key="emailForm"
            open={true}
            style={{ width: "unset" }}
          >
            <div
              key={i.id}
              className="max-w-[564px] w-screen"
            >
              <div className="max-w-[564px] w-screen bg-black-secondary pb-8">
                <div>
                  <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
                    {i.title ?? ""}
                  </div>
                  <img src="/assets/images/hr.svg" alt="hr" className="w-full object-cover h-1 mb-9" />
                  <div className="w-11/12 mx-auto">
                    <div className="text-center text-white">
                      {i.confirmMsg}
                    </div>
                  </div>
                  <div className="w-11/12 mx-auto">
                    <div className="text-center text-white flex mx-auto justify-center w-full overflow-hidden rounded-lg border-t-1 border-orange relative">
                      <TextInput value={email} setValue={setEmail} fieldName="Email" type="email"
                        className="text-md block w-full text-black font-medium"
                      />
                    </div>
                    <PasswordInput
                      submit={() => {
                        i.cb(email, password);
                        closeModal(i.id);
                        setEmail("");
                        setPassword("");
                      }}
                      className="mt-1" fieldName="Password" password={password} setPassword={setPassword} />
                    {/* <div className="text-orange float-right cursor-pointer"
                      onClick={() => {
                        closeModal(i.id)
                        nav('/forgot-password')
                      }}
                    >
                      Forgot password?
                    </div> */}
                  </div>
                  <div className="flex w-[360px] gap-4 mt-9 text-center items-center mx-auto">
                    <ArtButton color="red"
                      onClick={() => {
                        setEmail("");
                        setPassword("");
                        closeModal(i.id);
                      }}
                    >
                      <div className="text-white font-semibold">
                        Cancel
                      </div>
                    </ArtButton>
                    <ArtButton color="yellow"
                      onClick={() => {
                        i.cb(email, password);
                        closeModal(i.id);
                        setEmail("");
                        setPassword("");
                      }}
                    >
                      <div className="text-white font-semibold">
                        Save
                      </div>
                    </ArtButton>
                  </div>
                </div>
              </div>
            </div>
          </Modal >
        );
      case ModalType.FormAmountInput:
        return (
          <Modal
            key="amountInput"
            open={true}
            style={{ width: "unset" }}
          >
            <div
              key={i.id}
              className="max-w-[564px] w-screen"
            >
              <div className="max-w-[564px] w-screen bg-black-secondary pb-8">
                <div>
                  <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
                    {i.title ?? ""}
                  </div>
                  <img src="/assets/images/hr.svg" alt="hr" className="w-full object-cover h-1 mb-9" />
                  <div className="w-11/12 mx-auto">
                    <div className="text-center text-white">
                      {i.confirmMsg}
                    </div>
                  </div>
                  <div className="w-1/2 mx-auto flex">
                    <img src="/assets/images/icons/decrease-switch.svg" alt="decrease" className="decrease-switch cursor-pointer unselectable"
                      onClick={() => {
                        if (formInputLoading) {
                          return;
                        }
                        amount > i.min && setAmount(amount - 1);
                      }}
                    />
                    <Slider
                      min={i.min}
                      max={i.max}
                      step={1}
                      value={amount}
                      onChange={(e) => {
                        if (formInputLoading) {
                          return;
                        }
                        setAmount(Number(e));
                      }}
                      trackStyle={{
                        background: "#E89F01"
                      }}
                      dotStyle={{
                        borderColor: "unset",
                        boxShadow: "unset",
                        border: "unset !important"
                      }}
                      activeDotStyle={{
                        borderColor: "unset",
                        boxShadow: "unset",
                        border: "unset !important"
                      }}
                      style={{
                        cursor: "pointer"
                      }}
                    />
                    <img src="/assets/images/icons/increase-switch.svg" alt="decrease" className="increase-switch cursor-pointer unselectable"
                      onClick={() => {
                        if (formInputLoading) {
                          return;
                        }
                        amount < i.max && setAmount(amount + 1);
                      }}
                    />
                  </div>
                  <div className="text-center my-4 text-white">
                    {`${i.max > 0 ? amount : 0} / ${i.max}`}
                  </div>
                  <div className="flex w-[360px] gap-4 mt-9 text-center items-center mx-auto">
                    <ArtButton color="red"
                      disabled={formInputLoading}
                      onClick={() => {
                        setAmount(1);
                        closeModal(i.id);
                      }}
                    >
                      <div className="text-white font-semibold">
                        Cancel
                      </div>
                    </ArtButton>
                    <ArtButton color="yellow"
                      loading={formInputLoading}
                      disabled={amount <= 0 || i.max <= 0}
                      onClick={async () => {
                        setFormInputLoading(true);
                        await i.cb(amount);
                        setFormInputLoading(false);
                        closeModal(i.id);
                        setAmount(1);
                      }}
                    >
                      <div className="text-white font-semibold">
                        Confirm
                      </div>
                    </ArtButton>
                  </div>
                </div>
              </div>
            </div>
          </Modal >
        );
    }
  }
  return <div>{modals.map((i: any) => renderModal(i))}</div>;
}
