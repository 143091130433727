import { ArtButton } from "components/button";
import { Rarity, TicketRarity } from "interfaces";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { bulkSetUserInfo, setUserInfo } from "reducer/user.slice";
import { api, ui } from "services";
import walletConnect from "services/wallet-connect";

export const Ticket = ({
    title,
    icon,
    amount = 0,
    rarity,
    overRare,
    setOverRare,
    trigger,
    setTrigger,
    event,
    setOpenStake,
    setOpenUnstake,
    poolInfos
}: {
    title: string;
    icon: string;
    amount?: number;
    rarity: TicketRarity;
    overRare: any;
    setOverRare: any;
    trigger: any;
    setTrigger: any;
    event: any;
    setOpenStake: any;
    setOpenUnstake: any;
    poolInfos: any;
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const submitTicket = async (amount: number, rarity: TicketRarity) => {
        try {
            let data = await api
                .submitTicket(amount, rarity)
            dispatch(setUserInfo(data.customerInfo));
            setTrigger(!trigger);
            ui.alertSuccess("Submit ticket success", "");
        } catch (err: any) {
            ui.alertFailed("Submit ticket failed", t(err.message));
        }
    };

    return (
        <div
            className="bg-brown w-full rounded-2xl border-blue-brown border-4 "
            onMouseEnter={() => {
                setOverRare(rarity);
            }}
            onMouseLeave={() => {
                setOverRare(-1);
            }}
        >
            <div
                className={`card-header text-white-light py-2 px-2 sm:px-6 rounded-t-lg text-center`}
            >
                {title}
            </div>
            <div className="w-full bg-black-light justify-center rounded-2xl pt-16 relative h-[220px]">
                <div
                    className={`w-full top-0 left-0 flex items-center justify-center noHover`}
                >
                    <img
                        src={`/assets/images/items/${icon}`}
                        className="w-[121px]"
                        alt="reward"
                    />
                </div>
                {overRare !== rarity ? (
                    <div className="font-cookie-run text-center mt-12 mx-auto pb-4 ticket-quantity">
                        x {amount}
                    </div>
                ) : (
                    <div className="flex flex-col gap-3 pb-4 w-5/6 mx-auto translate-y-5">
                        <ArtButton
                            color="brown"
                            hover={false}
                            className="noHover"
                            disabled={!event || !event.rewards_pool || rarity > event.rewards_pool.length - 1 || !event.rewards_pool[rarity] || !event.rewards_pool[rarity].length}
                            onClick={() => {
                                ui.openAmountForm(
                                    "Select amount to submit to pool",
                                    async (amount: number) => await submitTicket(amount, rarity),
                                    <div
                                        className={`w-full top-0 left-0 items-center justify-center flex flex-col`}
                                    >
                                        <img
                                            src={`/assets/images/items/${icon}`}
                                            className="w-[121px]"
                                            alt="reward"
                                        />
                                        <div
                                            className={`card-header text-white-light py-2 px-6 rounded-t-lg text-center`}
                                        >
                                            {title}
                                        </div>
                                    </div>,
                                    amount > 0 ? 1 : 0,
                                    amount
                                );
                            }}
                        >
                            Submit
                        </ArtButton>
                    </div>
                )}
            </div>
        </div>
    );
};