import { MongenState } from "interfaces";
import "phaser";
import "phaser/plugins/spine/dist/SpinePlugin";
import {
  RacingAnimation,
  RacingConfig,
  RacingEffect,
  RacingMongenState,
} from "./config";
import MongenPlayer from "./mongenplayer";
import { getAnimName, getSkinList } from "./spineutils";

const SCALE = 0.4;

export default class Mongen {
  scene: Phaser.Scene;
  public sprite: any;
  speed: number;
  public state: RacingMongenState;
  basePosition: number[];
  pos: number[];
  public nextPos: number;
  race: number;
  rarity: number;
  container: any;
  status: RacingEffect[];
  statusText: any;
  dna: number[][];
  player: MongenPlayer;
  constructor(scene: Phaser.Scene, pos: number[], dna: number[][]) {
    this.dna = dna;
    this.scene = scene;
    this.pos = pos;
    this.speed = 0;
    this.basePosition = [pos[0], pos[1]];
    this.state = RacingMongenState.Idle;
    this.container = this.scene.add.container(0, 0).setDepth(3);
    this.container.setPosition(pos[0], pos[1]);
    this.status = [];
    this.statusText = {};
    this.nextPos = 0;
    this.addMongen(0, 0);
  }
  public addEffect(effect: RacingEffect) {
    let text = this.scene.add.text(0, -150, RacingEffect[effect]);
    this.scene.tweens.add({
      targets: text,
      y: { value: -300, duration: 2000 },
      alpha: { value: 0.5, duration: 2000 },
    });
    this.container.add(text);
    setTimeout(() => {
      text.destroy();
    }, 1000);
  }
  public setMongenState(state: RacingMongenState) {
    if (state === this.state) {
      return;
    }
    this.state = state;
    switch (state) {
      case RacingMongenState.Idle:
        this.player.sprite.timeScale = 1;
        this.player.playAnimation("Idle", true);
        break;
      case RacingMongenState.Run:
        this.player.playAnimation("Run", true);
        break;
      case RacingMongenState.Lose:
        this.player.sprite.timeScale = 1;
        this.player.playAnimation("Die", false);
        this.container.setPosition(
          RacingConfig.MAX_POS * RacingConfig.MapScale + 10,
          this.pos[1]
        );
        break;
      case RacingMongenState.Victory:
        this.player.sprite.timeScale = 1;
        this.player.playAnimation("Victory", true);
        this.container.setPosition(
          RacingConfig.MAX_POS * RacingConfig.MapScale + 10,
          this.pos[1]
        );
        break;
    }
  }
  public setStatus(effect: RacingEffect, time: number) {
    if (time <= 0) {
      if (this.status.includes(effect)) {
        this.status = this.status.filter((i) => i !== effect);
        this.statusText[effect].destroy();
      }
    } else {
      if (this.status.includes(effect)) {
        this.statusText[effect].text = `${RacingEffect[effect]} - ${time}`;
      } else {
        this.status.push(effect);
        this.statusText[effect] = this.scene.add.text(
          -100,
          -100,
          `${RacingEffect[effect]} - ${time}`
        );
        this.container.add(this.statusText[effect]);
      }
    }
    this.status.forEach((t: any, index: number) => {
      this.statusText[t].y = -100 - index * 20;
    });
  }
  public restart(pos: number[], dna: number[][]) {
    this.container.setPosition(pos[0], pos[1]);
    this.player.setDNA(dna);
    this.setMongenState(RacingMongenState.Idle);
    this.basePosition = [pos[0], pos[1]];
    this.nextPos = 0;
    this.speed = 0;
    this.player.sprite.timeScale = 1;
  }
  public setNextPos(p: number, dir: number) {
    if (this.state !== RacingMongenState.Run) {
      this.player.sprite.timeScale = 1;
      return;
    }
    let distance = p - this.container.x;
    if (dir === 1) {
      this.player.sprite.setScale(SCALE, SCALE);
    } else {
      this.player.sprite.setScale(-SCALE, SCALE);
    }
    this.speed = distance / (RacingConfig.FrameDuration / 1000);
    this.player.sprite.timeScale =
      Math.abs(this.speed) / RacingConfig.MapScale / 10;
    this.nextPos = p;
  }
  public setState(anim: RacingAnimation) {
    this.player.sprite.play(getAnimName(anim, this.dna), true);
  }

  update(args: any[]): void {
    const delta = args[1];
    switch (this.state) {
      case RacingMongenState.Idle:
        this.pos = [this.basePosition[0], this.basePosition[1]];
        break;
      case RacingMongenState.Run:
        let newPos = this.pos[0] + (delta / 1000) * this.speed;
        this.pos[0] = Math.min(
          RacingConfig.MAX_POS * RacingConfig.MapScale,
          newPos
        );
        break;
      case RacingMongenState.Lose:
      case RacingMongenState.Victory:
        this.pos[0] = RacingConfig.MAX_POS * RacingConfig.MapScale;
    }
    this.container.setPosition(this.pos[0], this.pos[1]);
  }
  addMongen(x: number, y: number) {
    let shadow = this.scene.add.image(0, 0, "shadow");
    shadow.setScale(0.4, 0.1);
    shadow.setTint();
    shadow.setAlpha(0.3);
    this.container.add(shadow);
    this.player = new MongenPlayer(
      this.scene,
      this.container,
      this.dna,
      0,
      0,
      -SCALE,
      SCALE
    );
  }
}
