export default function Footer(props) {
  return (
    <div className="flex flex-col justify-center items-center w-full relative bg-[#3D3125] select-none text-center pb-6">
      <div className="flex flex-wrap mt-6">
        <img
          onClick={() =>
            window.open("https://twitter.com/Monsterra_P2E ", "_blank").focus()
          }
          className="cursor-pointer react-button mt-3 mr-3 w-12 h-12 md:w-16 md:h-16"
          src="/assets/footer/Twitter.webp"
          alt="Twitter"
        />
        <img
          onClick={() =>
            window.open("https://t.me/monsterragame_chat ", "_blank").focus()
          }
          className="cursor-pointer react-button mt-3 mr-3 w-12 h-12 md:w-16 md:h-16"
          src="/assets/footer/Telegram.webp"
          alt="Telegram"
        />
        <img
          onClick={() =>
            window.open("https://discord.gg/R5uG9WCyr7", "_blank").focus()
          }
          className="cursor-pointer react-button mt-3 mr-3 w-12 h-12 md:w-16 md:h-16"
          src="/assets/footer/Discord.webp"
          alt="Discord"
        />
        <img
          onClick={() =>
            window
              .open(
                "https://www.facebook.com/Monsterra-P2E-Game-100591379141319",
                "_blank"
              )
              .focus()
          }
          className="cursor-pointer react-button mt-3 mr-3 w-12 h-12 md:w-16 md:h-16"
          src="/assets/footer/Facebook.webp"
          alt="Facebook"
        />
        <img
          onClick={() =>
            window.open("https://medium.com/@monsterra.io", "_blank").focus()
          }
          className="cursor-pointer react-button mt-3 mr-3 w-12 h-12 md:w-16 md:h-16"
          src="/assets/footer/medium.webp"
          alt="Medium"
        />
        <img
          onClick={() =>
            window
              .open("https://www.youtube.com/MonsterraNFTGame", "_blank")
              .focus()
          }
          className="cursor-pointer react-button mt-3 mr-3 w-12 h-12 md:w-16 md:h-16"
          src="/assets/footer/youtube.webp"
          alt="Youtube"
        />
        <img
          onClick={() =>
            window
              .open("https://www.tiktok.com/@monsterra_nftgame", "_blank")
              .focus()
          }
          className="cursor-pointer react-button mt-3 mr-3 w-12 h-12 md:w-16 md:h-16"
          src="/assets/footer/Tiktok.webp"
          alt="Tiktok"
        />
      </div>
      <a class=" text-lg mt-6 react-text" target="_blank">2021 Monsterra, Inc. All rights reserved.</a>
    </div>
  );
}
