import CheckRankInput from "components/check-rank-input";
import RankItem from "components/rank-item";
import Config, { SSO_META } from "config";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import {
  Modal,
  Table,
  TableBody,
  TableHeader,
  TableRow,
} from "semantic-ui-react";
import { api, utils } from "services";
import Footer from "./Footer";
import "./style.css";

export default function Referral() {
  const [currentPoints, setCurrentPoints] = useState(0);
  const [tab, setTab] = useState(1);
  const [eventInfo, setEventInfo] = useState(null);
  const [openRankCheck, setOpenRankCheck] = useState(false);
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState<Boolean>(true);
  const [rank, setRank] = useState([]);
  const [leaderboard_tab, setLeaderboardTab] = useState(0);
  const [leaderboard_idx, openLeaderboard] = useState(null);
  const [leaderboardInfo, setLeaderboardInfo] = useState([[], []]);

  const user = useSelector((state: RootState) => state.user);

  const milestones = [
    {
      name: "start",
      commission: 10,
      className: "start-container",
      box: "box-1.png",
      prize: 0,
      points: 0,
    },
    {
      name: "bronze",
      commission: 11,
      className: "bronze-container",
      box: "box-2.png",
      prize: 500,
      points: 20000,
      top: [3000, 2000, 1000],
    },
    {
      name: "silver",
      commission: 12,
      className: "silver-container",
      box: "box-3.png",
      prize: 2000,
      points: 60000,
      top: [6000, 5000, 4000],
    },
    {
      name: "golden",
      commission: 15,
      className: "golden-container",
      box: "box-4.png",
      prize: 5000,
      points: 180000,
      top: [12000, 10000, 8000],
    },
    {
      name: "diamond",
      commission: 20,
      className: "platium-container",
      box: "box-5.png",
      prize: 10000,
      points: 500000,
      top: [50000, 25000, 15000],
    },
  ];

  async function searchRank() {
    if (!address || !eventInfo?.eventInfo) {
    }
    try {
      setLoading(true);
      let rs = await api.post("/event/search-referral-event-rank", {
        event_id: eventInfo.eventInfo.id,
        search: address,
      });
      setRank([rs]);
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  }

  const getReferralInfo = async () => {
    try {
      let customer_id = user?.id;
      let rs = await api.getReferralEvent(customer_id ?? null);
      if (rs) {
        let eventInfo = rs.referralEvent;
        let leaderboardData = rs.leaderboardData;
        setEventInfo({
          eventInfo,
          leaderboardData,
          currentSeason: rs.currentSeason,
        });
        setCurrentPoints(rs?.customerScore ?? 0);
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const getLeaderboard = async () => {
    try {
      let rs = await api.getLeaderboard();
      // let test = [
      //   {
      //     "point": 100194,
      //     "name": "aaaaaaa",
      //     "rank": 1
      //   },
      //   {
      //     "point": 100194,
      //     "name": "aaaaaaa",
      //     "rank": 2
      //   },
      //   {
      //     "point": 7,
      //     "name": "aaaaaaa",
      //     "rank": 3
      //   },
      //   {
      //     "point": 34123,
      //     "name": "aaaaaaa",
      //     "rank": 4
      //   }
      // ];
      if (rs) {
        setLeaderboardInfo(rs);
        // setLeaderboardInfo([
        //   [[]],
        //   [[...test, ...test, ...test, ...test, ...test, ...test, ...test]]
        // ])
      }
    } catch (err) {
      console.log({ err });
    }
  };

  useEffect(() => {
    getReferralInfo();
  }, [user?.id]);

  function onReferClick() {
    let src = "";
    if (window.location.search) {
      let query = new URLSearchParams(window.location.search);
      src = query.get("src");
    }
    // @ts-ignore
    if (window.gtag) {
      // @ts-ignore
      window.gtag("event", "conversion", {
        send_to: "AW-10943121509/dkDeCK604oEYEOWQi-Io",
        src,
      });
    }
    window.open(SSO_META().DAOLink + "/#/referral", "_blank");
  }

  return (
    <div className="referral">
      {/* SECTION 1 */}
      <div className="relative h-screen">
        <img
          src={require("./assets/banner_desktop.png")}
          alt="banner"
          className="md:block hidden w-screen object-cover h-screen object-right absolute"
        />
        <img
          src={require("./assets/banner_mobile.png")}
          alt="banner"
          className="block md:hidden w-screen object-cover h-screen object-center absolute"
        />
        {/* section 1 laptop */}
        <div className="md:block hidden absolute font-cookie-black text-uppercase text-banner-container h-screen w-screen">
          <div className="text-banner text-5xl max-w-min font-cookie-bold">Biggest</div>
          <div className='text-banner-secondary text-8xl max-w-min font-cookie-bold'>Referral</div>
          <div className='text-banner-secondary text-8xl max-w-min font-cookie-bold'>Program</div>
          <div className="text-banner text-5xl font-cookie-bold">Of the year</div>

          <div className="">
            <img
              className="mt-8 h-[60px] cursor-pointer"
              src={require("./assets/refer_now.png")}
              onClick={() => {
                onReferClick();
              }}
              alt="refer now"
            />
          </div>

          <div className="mt-[2%] font-cookie-black text-uppercase prize-container grid grid-cols-5 w-full text-center">
            <div className="flex">
              <div>
                <div className="text-banner text-3xl lg:text-5xl font-cookie-black">
                  219,000+
                </div>
                <div className="text-banner-secondary text-2xl lg:text-4xl mb-6 lg:mb-8 font-cookie-bold mt-2">
                  USDT
                </div>
                <div className="text-[#BCBCBC] text-md lg:text-xl font-cookie">
                  TOTAL PRIZE
                </div>
              </div>
            </div>
            <div className="hr"></div>
            <div>
              <div className="text-banner text-3xl lg:text-5xl font-cookie-black">
                60,000+
              </div>
              <div className="text-banner-secondary text-2xl lg:text-4xl mb-6 lg:mb-8 font-cookie-bold mt-2">
                USDT
              </div>
              <div className="text-[#BCBCBC] text-md lg:text-xl font-cookie">
                HIGHEST PRIZE
              </div>
            </div>
            <div className="hr"></div>
            <div>
              <div className="text-banner-secondary text-2xl lg:text-4xl translate-y-3 font-cookie-black">
                COMMISION
              </div>
              <div className="text-banner-secondary text-2xl lg:text-4xl mb-6 lg:mb-8 font-cookie-bold mt-2 translate-y-6">
                UP TO
              </div>
              <div className="text-banner text-4xl lg:text-6xl font-cookie-bold">
                20%
              </div>
            </div>
          </div>
        </div>

        {/* section 1 mobile */}
        <div className="md:hidden block absolute font-cookie-run text-uppercase h-screen w-screen">
          <div className="bottom-[300px] absolute px-4">
            <div className="text-banner text-4xl font-cookie-bold">Biggest</div>
            <div className='text-banner-secondary text-6xl font-cookie-bold'>Referral</div>
            <div className='text-banner-secondary text-6xl font-cookie-bold'>Program</div>
            <div className="text-banner text-4xl font-cookie-bold">Of the year</div>
          </div>
          <div className="w-full absolute bottom-[280px] left-5">
            <div className="w-[90%] mx-auto absolute font-cookie-run text-uppercase flex gap-4 text-center justify-center text-banner-mobile-container mx-center">
              <div className="flex grid col-span-3">
                <div>
                  <div className="text-banner text-2xl font-cookie-black">
                    219,000
                  </div>
                  <div className="text-banner-secondary text-lg mb-6 font-cookie-bold">
                    USDT
                  </div>
                  <div className="text-[#BCBCBC] text-lg font-cookie-bold">
                    TOTAL PRIZE
                  </div>
                </div>
              </div>
              <div className="hr"></div>
              <div className="grid col-span-3">
                <div className="text-banner text-2xl font-cookie-black">
                  60,000
                </div>
                <div className="text-banner-secondary text-lg mb-6 font-cookie-bold">
                  USDT
                </div>
                <div className="text-[#BCBCBC] text-lg font-cookie-bold">
                  HIGHEST PRIZE
                </div>
              </div>
              <div className="hr"></div>
              <div className="grid col-span-3">
                <div className="text-banner-secondary text-lg mb-2 mt-2 font-cookie-black">
                  COMMISION
                </div>
                <div className="text-banner-secondary text-lg mb-6 font-cookie-bold">
                  UP TO
                </div>
                <div className="text-banner text-2xl font-cookie-bold">20%</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION 2 */}
      <div className="w-full relative">
        <img
          src={require("./assets/bg-roadmap.png")}
          alt="banner"
          className="w-full h-full object-cover absolute top-0"
        />
        {/* section 2 laptop */}
        <div className="w-full pt-10 relative hidden lg:block">
          <div className="mt-20"></div>
          <div className="flex flex-wrap gap-x-8 gap-y-10 mx-auto w-full self-center flex-center justify-center">
            {milestones.map((milestone) => {
              return (
                <div>
                  <div className="relative gap-4 w-[250px] min-h-min">
                    <div className="text-rank text-center font-black mb-1">{milestone.name}</div>
                    <div
                      className={`pl-[12px] py-[10px] w-[250px] ${milestone.className}`}
                    >
                      <div className="text-black text-[18px]">Commission</div>
                      <div className="text-black text-[32px] ml-[58px] font-black font-cookie-black">
                        {milestone.commission}%
                      </div>
                    </div>
                    <img
                      src={require(`./assets/${milestone.box}`)}
                      alt="box"
                      className="h-[74%] absolute right-0 top-0 translate-x-[20%] translate-y-[36%]"
                    />
                  </div>
                  <div className="relative gap-4 w-[250px] min-h-min">
                    {milestone.prize > 0 && (
                      <div className={` w-[250px] ${milestone.className} mt-5`}>
                        <div className="text-prize text-3xl">
                          {milestone.prize.toLocaleString()} USDT
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="grid grid-cols-6 mt-20 -translate-x-[32px]">
            <div></div>
            {milestones.map((milestone, index) => {
              let isMiddle = false;
              let totalMilestonePrev =
                index > 0 ? milestones[index - 1].points : 0;
              let widthPercent = "0";
              if (index + 1 === milestones.length) {
                widthPercent = "0";
                if (currentPoints >= milestone.points) {
                  isMiddle = true;
                }
              } else if (currentPoints >= milestones[index + 1].points) {
                widthPercent = "100";
              } else if (
                totalMilestonePrev <= currentPoints &&
                currentPoints >= milestone.points
              ) {
                widthPercent = `${Math.floor(
                  ((currentPoints - milestone.points) /
                    (milestones[index + 1].points - milestone.points)) *
                  100
                )}`;
                isMiddle = true;
              }
              return (
                <div>
                  <div
                    className={`${!isMiddle
                        ? "text-black text-[14px] lg:text-[28px]"
                        : "text-[#7D121A] text-[14px] lg:text-[28px]"
                      } text-milestone -translate-x-[3px] lg:-translate-x-1/4 max-w-max h-[30px] lg:h-[80px]`}
                  >
                    {utils.convertToInternationalCurrencySystem(
                      milestone.points
                    )}{" "}
                    <span className="hidden lg:inline-block">Points</span>
                  </div>
                  <div
                    className={`absolute ${isMiddle ? "-translate-y-1/2" : "-translate-y-[40%]"
                      }`}
                    style={{ zIndex: "20" }}
                  >
                    {!isMiddle ? (
                      <img
                        src={require(currentPoints >= milestone.points
                          ? "./assets/done_icon.png"
                          : "./assets/not_done_icon.png")}
                        className="w-6 h-6 lg:w-10 lg:h-10"
                        alt="milestone"
                      />
                    ) : (
                      <img
                        src={require("./assets/star-icon.png")}
                        alt="milestone"
                        className="w-8 h-8 lg:w-16 lg:h-16 -translate-x-[50%]"
                      />
                    )}
                  </div>

                  {index !== milestones.length - 1 && (
                    <div className="relative">
                      <div
                        className={`py-1 bg-[#7D121A] absolute`}
                        style={{
                          zIndex: "11",
                          width: `${widthPercent}%`,
                        }}
                      ></div>
                      <div
                        className="py-1 bg-[#CCABA3] w-full absolute"
                        style={{ zIndex: "10" }}
                      ></div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="w-full text-[#626262] text-2xl text-center mt-20">
            Your points: {Number(currentPoints ?? 0).toFixed(2)}
          </div>

          <div
            className="flex flex-wrap gap-x-8 gap-y-10 mx-auto w-full self-center flex-center justify-center mt-10"
            style={{
              height: "min-content",
            }}
          >
            {milestones.map((milestone, m_idx) => {
              return (
                <div className="relative gap-4 w-[250px] hidden md:block">
                  {milestone?.prize > 0 && (
                    <div
                      className={`p-[20px] w-[250px] ${milestone.className} ${eventInfo?.currentSeason &&
                        eventInfo?.currentSeason.index < m_idx - 1 &&
                        "disabled-event"
                        }`}
                    >
                      <div className="text-top font-cookie-bold">
                        Top {milestone.name.toUpperCase()}
                      </div>
                    </div>
                  )}
                  {milestone?.prize === 0 ? (
                    <div className={`relative w-[250px] text-center h-full`}>
                      <div
                        className={`p-[20px] w-[250px] ${milestone.className}`}
                      >
                        {eventInfo?.currentSeason &&
                          eventInfo?.eventInfo?.isActive && (
                            <div className="text-md lg:text-3xl font-cookie-black font-bold mb-4">
                              SEASON {eventInfo?.currentSeason?.index + 1}
                            </div>
                          )}
                        {!eventInfo?.eventInfo?.isActive && (
                          <div className="text-md lg:text-3xl font-cookie-black font-bold mb-4">
                            ENDED
                          </div>
                        )}

                        {eventInfo?.eventInfo?.isActive && (
                          <div className="text-top lg:mt-10">
                            {eventInfo?.currentSeason
                              ? dayjs(eventInfo.currentSeason.start)
                                .toDate()
                                .toLocaleTimeString(
                                  "en-us",
                                  Config.FormattedTime as any
                                )
                              : "--:--"}
                          </div>
                        )}
                        {!eventInfo?.eventInfo?.isActive && (
                          <div className="text-top lg:mt-10">
                            {eventInfo?.eventInfo?.start
                              ? dayjs(eventInfo?.eventInfo?.start)
                                .toDate()
                                .toLocaleTimeString(
                                  "en-us",
                                  Config.FormattedTime as any
                                )
                              : "--:--"}
                          </div>
                        )}

                        <div className="text-black text-[18px] font-semibold my-2">
                          to
                        </div>

                        {eventInfo?.eventInfo?.isActive && (
                          <div className="text-top mb-24">
                            {eventInfo?.currentSeason
                              ? dayjs(eventInfo.currentSeason.end)
                                .toDate()
                                .toLocaleTimeString(
                                  "en-us",
                                  Config.FormattedTime as any
                                )
                              : "--:--"}
                          </div>
                        )}
                        {!eventInfo?.eventInfo?.isActive && (
                          <div className="text-top mb-24">
                            {eventInfo?.eventInfo?.end
                              ? dayjs(eventInfo?.eventInfo?.end)
                                .toDate()
                                .toLocaleTimeString(
                                  "en-us",
                                  Config.FormattedTime as any
                                )
                              : "--:--"}
                          </div>
                        )}

                        <div className="w-full mx-auto text-center justify-center self-center absolute">
                          <img
                            src={require("./assets/mongen-decor-1.png")}
                            alt="mongen decor"
                            className="translate-x-1"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="relative gap-4 w-[250px] mt-4"
                      style={{ height: "calc(100% - 88px)" }}
                    >
                      <div
                        className={`p-[20px] w-[250px] ${milestone.className
                          } h-full ${eventInfo?.currentSeason &&
                          eventInfo?.currentSeason.index < m_idx - 1 &&
                          "disabled-event-laptop"
                          }`}
                      >
                        {[0, 1, 2].map((rank, idx) => {
                          let isLast = idx === [0, 1, 2].length - 1;
                          let infos =
                            eventInfo?.leaderboardData?.[m_idx - 1] ?? [];
                          let info: any =
                            infos.length > idx ? infos[idx] : null;
                          return (
                            <div
                              className={`flex gap-4 ${isLast ? "" : "rank-container"
                                } pb-4`}
                            >
                              <div className="self-center text-md lg:text-3xl text-black font-bold">
                                #{rank + 1}
                              </div>
                              <div className="text-[16px] font-cookie-run">
                                <div>{info ? info.name : "--"}</div>
                                <div className="text-[#757575]">
                                  {info ? info?.point?.toFixed(2) : "--"} points
                                </div>
                                <div className="relative mt-1 min-h-min">
                                  <img
                                    src={require("./assets/reward-bg.png")}
                                    alt="reward-bg"
                                    className="w-full h-full"
                                  />
                                  <div className="absolute top-0 h-full text-center w-full">
                                    <div className="self-center justify-center center-div">
                                      <span className="text-2xl text-[#FFDF80] font-bold font-cookie-black">
                                        {milestone.top[idx].toLocaleString()}
                                      </span>
                                      <span className="text-sm text-white font-semibold font-cookie-run ml-2">
                                        USDT
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        className="font-cookie-bold text-black absolute right-3 -translate-y-8 cursor-pointer"
                        onClick={async () => {
                          await getLeaderboard();
                          openLeaderboard(m_idx);
                        }}
                      >
                        See all {">"}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <div className="w-full mx-auto flex gap-4 lg:gap-8 mt-24 text-center mx-auto self-center justify-center pb-20">
            <img
              className="cursor-pointer w-full max-w-[180px] lg:max-w-[240px]"
              onClick={() => {
                window.open(SSO_META().DAOLink, "_blank");
              }}
              src={require("./assets/refer_btn.png")}
              alt="refer"
            />
            <img
              className="cursor-pointer w-full max-w-[180px] lg:max-w-[240px]"
              onClick={() => {
                setOpenRankCheck(true);
              }}
              src={require("./assets/check_rank_btn.png")}
              alt="rank"
            />
          </div>
        </div>

        {/* mobile section 2 */}
        <div className="w-full pt-10 relative lg:hidden">
          <div className="flex flex-wrap gap-4 gap-x-4 mx-auto w-full self-center flex-center justify-center">
            {milestones.map((milestone) => {
              return (
                <div className="relative gap-4 max-w-[115px] min-w-[50px]">
                  <div className="text-center text-rank-sm lg:text-rank">
                    {milestone.name}
                  </div>
                  <img
                    src={require(`./assets/${milestone.box}`)}
                    alt="box"
                    className="h-[25%] mx-auto mt-2 mb-4"
                  />
                  <div className="relative gap-4 w-full">
                    <div
                      className={`py-1 ${milestone.className}`}
                      style={{
                        borderRadius: "10px",
                      }}
                    >
                      <div className="text-prize-sm w-full text-[10px]">
                        Min: {milestone.points.toLocaleString()} points
                      </div>
                      <div className="text-prize-sm w-full text-[10px]">
                        Commision: {milestone.commission}%
                      </div>
                      {milestone.prize > 0 && (
                        <div className="text-prize-sm w-full text-[13px]">
                          {milestone.prize.toLocaleString()} USDT
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="grid grid-cols-5 mt-10 -translate-x-[20px]">
            <div></div>
            {milestones.map((milestone, index) => {
              let isMiddle = false;
              let totalMilestonePrev =
                index > 0 ? milestones[index - 1].points : 0;
              let widthPercent = "0";
              if (index + 1 === milestones.length) {
                widthPercent = "0";
                if (currentPoints >= milestone.points) {
                  isMiddle = true;
                }
              } else if (currentPoints >= milestones[index + 1].points) {
                widthPercent = "100";
              } else if (
                totalMilestonePrev <= currentPoints &&
                currentPoints >= milestone.points
              ) {
                widthPercent = `${Math.floor(
                  ((currentPoints - milestone.points) /
                    (milestones[index + 1].points - milestone.points)) *
                  100
                )}`;
                isMiddle = true;
              }
              return (
                <div>
                  <div
                    className={`${!isMiddle
                        ? "text-black text-[14px] lg:text-[28px]"
                        : "text-[#7D121A] text-[16px] lg:text-lg lg:text-4xl"
                      } text-milestone -translate-x-[3px] lg:-translate-x-1/4 max-w-max h-[50px] lg:h-[80px]`}
                  >
                    {utils.convertToInternationalCurrencySystem(
                      milestone.points
                    )}{" "}
                    <span className="hidden lg:block">Points</span>
                  </div>
                  <div
                    className={`absolute ${isMiddle ? "-translate-y-1/2" : "-translate-y-[40%]"
                      }`}
                    style={{ zIndex: "20" }}
                  >
                    {!isMiddle ? (
                      <img
                        src={require(currentPoints >= milestone.points
                          ? "./assets/done_icon.png"
                          : "./assets/not_done_icon.png")}
                        className="w-6 h-6 lg:w-10 lg:h-10"
                        alt="milestone"
                      />
                    ) : (
                      <img
                        src={require("./assets/star-icon.png")}
                        alt="milestone"
                        className="w-8 h-8 lg:w-16 lg:h-16"
                      />
                    )}
                  </div>

                  {index !== milestones.length - 1 && (
                    <div className="relative">
                      <div
                        className={`py-1 bg-[#7D121A] absolute`}
                        style={{
                          zIndex: "11",
                          width: `${widthPercent}%`,
                        }}
                      ></div>
                      <div
                        className="py-1 bg-[#CCABA3] w-full absolute"
                        style={{ zIndex: "10" }}
                      ></div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="w-full text-[#626262] text-center mt-8">
            Your points: {Number(currentPoints ?? 0)?.toFixed(2)}
          </div>
          <div className="px-4 mt-6">
            <div className="w-full text-[#626262] text-lg text-center font-black font-cookie-run border-brown px-2 py-2">
              {eventInfo?.currentSeason && eventInfo?.eventInfo?.isActive && (
                <div className="text-black text-lg">
                  SEASON {eventInfo?.currentSeason?.index + 1}
                </div>
              )}
              {!eventInfo?.eventInfo?.isActive && (
                <div className="text-black text-lg">ENDED</div>
              )}

              {eventInfo?.eventInfo?.isActive && (
                <div className="text-xs mt-2">
                  <span className="text-[#7D121A]">
                    {eventInfo?.currentSeason
                      ? dayjs(eventInfo.currentSeason.start)
                        .toDate()
                        .toLocaleTimeString(
                          "en-us",
                          Config.FormattedTime as any
                        )
                      : "--:--"}
                  </span>
                  <span className="text-black"> to </span>
                  <span className="text-[#7D121A]">
                    {eventInfo?.currentSeason
                      ? dayjs(eventInfo.currentSeason.end)
                        .toDate()
                        .toLocaleTimeString(
                          "en-us",
                          Config.FormattedTime as any
                        )
                      : "--:--"}
                  </span>
                </div>
              )}

              {!eventInfo?.eventInfo?.isActive && (
                <div className="text-xs mt-2">
                  <span className="text-[#7D121A]">
                    {eventInfo?.eventInfo?.start
                      ? dayjs(eventInfo?.eventInfo?.start)
                        .toDate()
                        .toLocaleTimeString(
                          "en-us",
                          Config.FormattedTime as any
                        )
                      : "--:--"}
                  </span>
                  <span className="text-black"> to </span>
                  <span className="text-[#7D121A]">
                    {eventInfo?.eventInfo?.end
                      ? dayjs(eventInfo?.eventInfo?.end)
                        .toDate()
                        .toLocaleTimeString(
                          "en-us",
                          Config.FormattedTime as any
                        )
                      : "--:--"}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 px-4">
            {milestones.map((milestone, m_idx) => {
              if (m_idx > 0) {
                return (
                  <div
                    className={`mt-6 ${eventInfo?.currentSeason &&
                      eventInfo?.currentSeason.index < m_idx - 1 &&
                      "disabled-event"
                      }`}
                  >
                    <div className={`p-2 text-center ${milestone.className}`}>
                      <div className="text-rank-sm font-cookie-bold">
                        Top {milestone.name}
                      </div>
                      <div
                        className={`text-center text-black font-cookie-bold text-sm`}
                      >
                        Min: {milestone.points.toLocaleString()} points
                      </div>
                    </div>

                    <div
                      className={`p-2 mt-4 rank-container-wrap ${milestone.className}`}
                      style={{ height: "calc(100% - 50px)" }}
                    >
                      <div className="relative gap-4 w-full">
                        {[0, 1, 2].map((rank, idx) => {
                          let isLast = idx === [0, 1, 2].length - 1;
                          let infos =
                            eventInfo?.leaderboardData?.[m_idx - 1] ?? [];
                          let info: any =
                            infos.length > idx ? infos[idx] : null;
                          return (
                            <div
                              className={`flex gap-4 justify-center ${isLast ? "" : "rank-container"
                                } py-2`}
                            >
                              <div className="self-center px-3 text-md text-black font-bold">
                                #{rank + 1}
                              </div>
                              <div className="text-[16px] font-cookie-run">
                                <div>{info ? info.name : "--"}</div>
                                <div className="text-[#757575] text-sm">
                                  {info ? info.point?.toFixed(2) : "--"} points
                                </div>
                                <div className="relative mt-1 min-h-min">
                                  <img
                                    src={require("./assets/reward-bg.png")}
                                    alt="reward-bg"
                                    className="w-full h-full"
                                  />
                                  <div className="absolute top-0 h-full text-center w-full">
                                    <div className="self-center justify-center center-div">
                                      <span className="text-xl text-[#FFDF80] font-bold font-cookie-black">
                                        {milestone.top[idx].toLocaleString()}
                                      </span>
                                      <span className="text-sm text-white font-semibold font-cookie-run ml-2">
                                        USDT
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div
                          className="font-cookie-bold text-black absolute right-1 cursor-pointer"
                          onClick={async () => {
                            await getLeaderboard();
                            openLeaderboard(m_idx);
                          }}
                        >
                          See all {">"}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div className="px-4 w-full mx-auto flex gap-4 lg:gap-8 mt-10 text-center mx-auto self-center justify-center pb-20">
            <img
              className="cursor-pointer w-full min-w-[80px] max-w-[240px]"
              onClick={() => {
                window.open(SSO_META().DAOLink, "_blank");
              }}
              src={require("./assets/refer_btn.png")}
              alt="refer"
            />
            <img
              className="cursor-pointer w-full min-w-[80px] max-w-[240px]"
              onClick={() => { }}
              src={require("./assets/check_rank_btn.png")}
              alt="rank"
            />
          </div>
        </div>
      </div>

      {/* SECTION 3 */}
      <div className="w-full relative">
        <img
          src={require("./assets/bg-rule.png")}
          alt="banner"
          className="w-full h-full object-cover absolute top-0"
        />
        <div className="w-full pt-10 md:pt-16 relative min-h-screen">
          <div className="px-6 md:px-24">
            <div className="text-uppercase text-white font-bold font-cookie-run text-3xl md:text-5xl text-center">
              How it work?
            </div>
            <div className="text-white font-bold font-cookie-run text-md md:text-3xl text-center mt-4">
              Just EASILY invite your friends to play Monsterra and both of you
              receive huge benefits
            </div>
            <div className="mt-8 md:mt-12 grid grid-cols-11 h-[11.2vw] md:h-[9vw] w-full mx-auto md:w-5/6">
              <div className="grid col-span-3">
                <TextBanner title={"INVITE YOUR FRIENDS"} />
              </div>
              <div className="w-full text-center translate-y-[33.33%]">
                <img
                  src={require("./assets/referral-arrow.png")}
                  alt="reward-bg"
                  className="h-1/3 object-contain text-center mx-auto px-1"
                />
              </div>
              <div className="grid col-span-3">
                <TextBanner
                  title={
                    <div className="px-2 py-4">
                      YOU GET POINTS FROM <br /> YOUR FRIEND’S ACTIVITIES
                    </div>
                  }
                />
              </div>
              <div className="w-full text-center translate-y-[33.33%]">
                <img
                  src={require("./assets/referral-arrow.png")}
                  alt="reward-bg"
                  className="h-1/3 object-contain text-center mx-auto px-1"
                />
              </div>
              <div className="grid col-span-3">
                <TextBanner title={"YOU GET BENEFITS"} />
              </div>
            </div>
          </div>

          <div className="mt-20 md:mt-12 w-full md:w-4/5 mx-auto">
            <div className="header text-center grid grid-cols-3 min-h-min justify-center self-center">
              <div>
                <img
                  className={`${tab !== 0 && "opacity-0"
                    } text-center mx-auto w-[16px] md:w-[40px]`}
                  src={require("./assets/table-indicator.png")}
                  alt="indicator"
                />
              </div>
              <div>
                <img
                  className={`${tab !== 1 && "opacity-0"
                    } text-center mx-auto w-[16px] md:w-[40px]`}
                  src={require("./assets/table-indicator.png")}
                  alt="indicator"
                />
              </div>
              <div>
                <img
                  className={`${tab !== 2 && "opacity-0"
                    } text-center mx-auto w-[16px] md:w-[40px]`}
                  src={require("./assets/table-indicator.png")}
                  alt="indicator"
                />
              </div>
            </div>
            <div className="header text-center grid grid-cols-3 min-h-min justify-center self-center">
              <div
                onClick={() => setTab(0)}
                className={`${tab === 0 ? "tab_active" : "tab-disabled"
                  } cursor-pointer text-sm md:text-lg lg:text-2xl md:py-3 lg:py-5 h-full text-[#7D121A]`}
              >
                <div className="center-div">How to invite your friends</div>
              </div>
              <div
                onClick={() => setTab(1)}
                className={`${tab === 1 ? "tab_active" : "tab-disabled"
                  } cursor-pointer text-sm md:text-lg lg:text-2xl md:py-3 lg:py-5 h-full text-[#7D121A]`}
              >
                <div className="center-div">Reward points by activities</div>
              </div>
              <div
                onClick={() => setTab(2)}
                className={`${tab === 2 ? "tab_active" : "tab-disabled"
                  } cursor-pointer text-sm md:text-lg lg:text-2xl md:py-3 lg:py-5 h-full text-[#7D121A]`}
              >
                <div className="center-div">Track your benefits by points</div>
              </div>
            </div>
            <div className="bg-[#ffdf80] min-h-[300px]">
              {tab === 1 && (
                <div className="px-4 py-8 grid grid-cols-1 md:grid-cols-2 gap-x-6">
                  {[11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((level) => {
                    return (
                      <div className="grid grid-cols-3 text-center gap-3 mt-3 min-h-min">
                        <div className="rounded-lg col-span-2 bg-[#4C2525] p-4 md:py-5 font-bold md:text-2xl text-white">
                          Your friend reaches level {level}
                        </div>
                        <div className="rounded-lg bg-[#7D121A] p-4 md:py-5 font-bold md:text-2xl text-[#FFDF80] h-full">
                          <div className="center-div">{level}</div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="grid grid-cols-3 text-center gap-3 mt-3 md:col-span-2">
                    <div className="rounded-lg col-span-2 bg-[#4C2525] p-6 py-8 md:py-10 font-bold md:text-2xl text-white">
                      Your friend spends 1 MSTR in-game
                    </div>
                    <div className="rounded-lg bg-[#7D121A] p-6 md:py-10 font-bold md:text-2xl text-[#FFDF80] h-full relative">
                      <img
                        src={require("./assets/mstr.png")}
                        className="absolute bottom-0 right-0 w-[30%] md:w-[15%]"
                      />
                      <div className="center-div">{1}</div>
                    </div>
                  </div>
                  {/* <div className="grid grid-cols-3 text-center gap-3 mt-3">
                  <div className="rounded-lg col-span-2 bg-[#4C2525] p-6 md:py-10 font-bold text-[13px] md:text-2xl text-white">Your friend spends 100 MAG</div>
                  <div className="rounded-lg bg-[#7D121A] p-6 md:py-10 font-bold md:text-2xl text-[#FFDF80] h-full relative">
                    <img src={require("./assets/mag.png")} className="absolute bottom-0 right-0 w-[30%]" />
                    <div className="center-div">{1}</div>
                  </div>
                </div> */}
                </div>
              )}
              {tab === 0 && (
                <div>
                  <div className="px-3 py-8 lg:grid lg:grid-cols-7 lg:text-xl lg:gap-12 min-h-min">
                    <div className="lg:col-span-3 px-12">
                      <div className="font-bold mb-2">For you (Referer): </div>

                      <div>
                        <span className="font-bold">Step 1:</span> Invite Your
                        Friends Log in/ Sign up (if you are a newbie) to
                        Monsterra DAO {"->"} Go to the tab
                        <span
                          className="text-link mx-1 inline-block"
                          onClick={() =>
                            window.open(
                              "https://dao.monsterra.io/referral",
                              "_blank"
                            )
                          }
                        >
                          “Referral”
                        </span>
                        to get Invitation Link/ Code {"->"} Send to your friends
                      </div>
                      <div className="mt-1">
                        <span className="font-bold">Step 2:</span> Promote Your
                        Referral Link/ Code Just promote your unique referral
                        link or send your unique CODE to your friends and
                        others. Let’s play Monsterra together and both of you
                        receive huge cashback.
                      </div>
                      <div className="mt-2">
                        <span className="font-bold">Notes:</span> You can
                        broadcast invitation by using Share button
                      </div>
                    </div>
                    <div className="hr-tab h-full"></div>
                    <div className="mt-6 lg:mt-0 lg:col-span-3 px-12">
                      <div className="font-bold mb-2">
                        For your friends (Referees):{" "}
                      </div>
                      <div>
                        1. Register account via the link that you sent/shared{" "}
                      </div>
                      <div className="mt-1">
                        2. Fill the Referral code from you in the
                        <span
                          className="text-link mx-1 inline-block"
                          onClick={() =>
                            window.open(
                              "https://dao.monsterra.io/#/account",
                              "_blank"
                            )
                          }
                        >
                          Account tab
                        </span>
                        if he/she registered account before
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {tab === 2 && (
                <div className="pb-10 overflow-scroll md:text-xl">
                  <Table celled unstackable className="referral-table">
                    <TableHeader>
                      <Table.Row>
                        <Table.HeaderCell rowSpan="1"></Table.HeaderCell>
                        <Table.HeaderCell rowSpan="1"></Table.HeaderCell>
                        <Table.HeaderCell colSpan="1">
                          Forever benefit
                        </Table.HeaderCell>
                        <Table.HeaderCell colSpan="2">
                          Benefit in this event
                        </Table.HeaderCell>
                      </Table.Row>
                      <Table.Row>
                        <Table.HeaderCell>Milestone</Table.HeaderCell>
                        <Table.HeaderCell>Points</Table.HeaderCell>
                        <Table.HeaderCell>Commission</Table.HeaderCell>
                        <Table.HeaderCell>Claim USDT</Table.HeaderCell>
                        <Table.HeaderCell>Competition</Table.HeaderCell>
                      </Table.Row>
                    </TableHeader>
                    <TableBody>
                      {milestones.map((milestone, idx) => {
                        return (
                          <TableRow>
                            <Table.Cell>{idx + 1}</Table.Cell>
                            <Table.Cell>
                              {milestone.points.toLocaleString()}
                            </Table.Cell>
                            <Table.Cell>{milestone.commission}%</Table.Cell>
                            <Table.Cell>
                              {milestone.prize.toLocaleString()}
                            </Table.Cell>
                            {idx !== 0 ? (
                              <Table.Cell>
                                Eligible to join{" "}
                                <span className="capitalize">
                                  {milestone.name}
                                </span>{" "}
                                competition
                              </Table.Cell>
                            ) : (
                              <Table.Cell></Table.Cell>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              )}
            </div>
          </div>

          <div className="mx-auto text-center w-full mt-10 md:mt-20">
            <img
              onClick={() => {
                window.open(
                  "https://medium.com/@monsterra.io/de4dd35939a1",
                  "_blank"
                );
              }}
              src={require("./assets/guideline_btn.png")}
              alt="guideline"
              className="max-w-[300px] w-1/2 min-w-[140px] mx-auto text-center cursor-pointer"
            />
          </div>
          <div className="mt-10"></div>
        </div>
      </div>

      {/* SECTION 4 */}
      <div className="w-full relative">
        <img
          src={require("./assets/bg-roadmap.png")}
          alt="banner"
          className="w-full h-full object-cover absolute top-0"
        />
        <div className="relative pt-10 md:pt-16 lg:pt-20">
          <div className="text-[#7D121A] text-center text-xl md:text-3xl lg:text-5xl text-uppercase font-black font-cookie-black">
            YOUR FRIEND’S BENEFITS
          </div>
          <div className="text-black text-md lg:text-3xl text-center font-bold font-cookie-run mt-2 lg:mt-3">
            For the first month from the invitation
          </div>
          <div className="flex flex-wrap gap-6 lg:gap-10 mx-center w-full justify-center self-center mt-4 md:mt-16 lg:mt-20 lg:px-2">
            <TextCashback
              title={"Gift back 2% "}
              body={
                <span className="font-cookie">
                  total valuation (in $MSTR)
                  <br /> of the first 5 transactions
                  <br /> on the marketplace
                </span>
              }
            />
            <TextCashback
              title={"5% cashback "}
              body={
                <span className="font-cookie">
                  (in $MSTR) on
                  <br /> total in-game $MSTR <br />
                  spending{" "}
                </span>
              }
            />
            <TextCashback
              title={"5% cashback "}
              body={
                <span className="font-cookie">
                  (in $MSTR) from
                  <br /> your own marketplace
                  <br /> transaction fee
                </span>
              }
            />
          </div>
          <div className="mt-10">
            <img
              src={require("./assets/mongen-decor-2.png")}
              alt="mongen decor"
              className="text-center mx-auto"
            />
          </div>
        </div>
      </div>

      <Footer />
      <Modal
        open={openRankCheck}
        onClose={() => {
          setAddress("");
          setOpenRankCheck(false);
          setRank([]);
        }}
        className="top-[20vh] "
      >
        <div
          className="w-full max-h-full max-w-full bg-black-primary px-6 z-[20] py-3 w-full"
          style={{ border: "0.1px solid #0F1011", transform: "scale(1)" }}
        >
          <div
            className="text-red cursor-pointer float-right translate-x-10 -translate-y-5"
            onClick={() => setOpenRankCheck(false)}
          >
            <img
              src="/assets/images/icons/close-icon.svg"
              alt="close"
              className=""
            />
          </div>
          <div>
            <CheckRankInput
              onChange={(val: string) => {
                setAddress(val);
              }}
              onSearch={() => {
                searchRank();
              }}
            />
            <div className="min-h-[400px]">
              {!loading &&
                rank.map((r: any, index: number) => <RankItem data={r} />)}
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={leaderboard_idx !== null}
        onClose={() => {
          openLeaderboard(null);
          setLeaderboardTab(0);
        }}
        className="top-[20vh] "
      >
        <div
          className="w-full max-h-full max-w-full bg-black-primary px-6 z-[20] py-3 w-full"
          style={{ border: "0.1px solid #0F1011", transform: "scale(1)" }}
        >
          <div
            className="text-red cursor-pointer float-right translate-x-10 -translate-y-5"
            onClick={() => {
              setLeaderboardTab(0);
              openLeaderboard(null);
            }}
          >
            <img
              src="/assets/images/icons/close-icon.svg"
              alt="close"
              className=""
            />
          </div>
          <div>
            <div className="grid grid-cols-2 border-b-2 border-black pt-1 pb-3">
              <div
                className={`${leaderboard_tab === 0
                    ? "active-leaderboard text-[#F9C744]"
                    : "text-white"
                  } py-2 font-cookie-bold cursor-pointer text-center`}
                onClick={() => {
                  setLeaderboardTab(0);
                }}
              >
                Current Rank
              </div>
              <div
                className={`${leaderboard_tab === 1
                    ? "active-leaderboard text-[#F9C744]"
                    : "text-white"
                  } py-2 font-cookie-bold cursor-pointer text-center`}
                onClick={() => {
                  setLeaderboardTab(1);
                }}
              >
                Previous season winners
              </div>
            </div>
            <div className="min-h-[400px] max-h-[400px] overflow-y-scroll">
              {leaderboard_tab === 0 ? (
                <div>
                  {leaderboardInfo[0]?.length > 0 &&
                    leaderboardInfo[0][leaderboard_idx - 1]?.map(
                      (data: any) => {
                        return (
                          <div className="w-full p-1">
                            <div className="bg-[#212025] py-4 px-6 flex gap-4 justify-between rounded-sm items-center">
                              <div className="flex gap-10 items-center">
                                <p className="text-2xl text-white">
                                  #{data?.rank}
                                </p>
                                <div>
                                  <p className="text-xl text-white text-ellipsis whitespace-nowrap w-44 overflow-hidden mx-auto">
                                    {data?.name}
                                  </p>
                                  <p className="text-[#BBBDC6] mt-2">
                                    {data?.identity}
                                  </p>
                                </div>
                              </div>
                              <p className="text-xl text-[#FFCF78] font-semibold">
                                {Number(data?.point?.toFixed(2))}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              ) : (
                <div>
                  {leaderboardInfo[1]?.length > 0 &&
                    leaderboardInfo[1][leaderboard_idx - 1]
                      ?.slice(0, 3)
                      ?.map((data: any) => {
                        return (
                          <div className="w-full p-1">
                            <div className="bg-[#212025] py-4 px-6 flex gap-4 justify-between rounded-sm items-center">
                              <div className="flex gap-10 items-center">
                                <p className="text-2xl text-white">
                                  #{data?.rank}
                                </p>
                                <div>
                                  <p className="text-xl text-white text-ellipsis whitespace-nowrap w-44 overflow-hidden mx-auto">
                                    {data?.name}
                                  </p>
                                  <p className="text-[#BBBDC6] mt-2">
                                    {data?.identity}
                                  </p>
                                </div>
                              </div>
                              <p className="text-xl text-[#FFCF78] font-semibold">
                                {Number(data?.point?.toFixed(2))}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const TextBanner = ({ title }: any) => {
  return (
    <div
      className="relative w-full"
      style={
        {
          // width: "416px",
          // height: "178px",
        }
      }
    >
      <img
        src={require("./assets/text-bg-referrel.png")}
        className="w-full h-full object-cover mx-auto absolute"
        alt="event-banner"
      />
      <div className="absolute text-center w-full center-div">
        <div className="text-white text-sm md:text-2xl font-bold">{title}</div>
      </div>
    </div>
  );
};

const TextCashback = ({ title, body }: any) => {
  return (
    <div
      className="relative w-full"
      style={{
        width: "327px",
        height: "250px",
      }}
    >
      <img
        src={require("./assets/text-bg-1.png")}
        className="w-full h-full object-cover mx-auto absolute"
        alt="event-banner"
      />
      <div className="absolute text-center w-full center-div px-8 font-cookie-run font-black">
        <span className="text-[#7D121A] text-lg font-black md:text-md lg:text-3xl font-cookie-bold">
          {title}
        </span>
        <br />
        <span className="text-black text-sm md:text-xl font-cookie">
          {body}
        </span>
      </div>
    </div>
  );
};
