import { useEffect, useState } from "react";
import Header from "./header";
import Main from "./main";

export default function Layout() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      setLoading(false);
      return;
    }
    setLoading(false);
  }, []);
  return (
    <div>
      {loading ? (
        <p></p>
      ) : (
        // Loading
        <>
          <Header />
          <div className="w-full pt-20">
            <Main />
          </div>
        </>
      )}
    </div>
  );
}
