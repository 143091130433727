import { useNavigate } from "react-router-dom";
import { SSO_META } from "config";
import { useEffect, useMemo, useState } from "react";
import { Checkbox, Pagination } from "semantic-ui-react";
import socketService from "services/socket";
import { ui, utils } from "services";
import { RootState } from "reducer/store";
import { useSelector } from "react-redux";
import { CustomerInterface, Rarity } from "interfaces";
import { MongenCard } from "./mongen-card";
import '../index.css';
import { useQuery } from "services/useQuery";

export function ChaosMongenList({ detailUrl, backUrl }: { detailUrl: string, backUrl: string }) {
    const nav = useNavigate();
    const [eventInfo, setEventInfo] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(3000);
    const [loading, setLoading] = useState(false);
    const user: any = useSelector((state: RootState) => state.user);
    const [mongenComponents, setMongenComponent] = useState(<></>)
    const [rarity, setRarity] = useState(Rarity.Epic)
    const [owned, setOwned] = useState(false);
    const [trigger, setTrigger] = useState(false);
    const [itemPerPage, setItemPerPage] = useState(10);
    const query = useQuery();

    const authenticate = async () => {
        try {
            let token = localStorage.getItem("token");
            if (!token) {
                ui.alertFailed("Forbidden", "You must login first !");
                nav("/")
                return;
            }
            let data: any = await socketService.authenticate(token);
            if (!data?.userInfo) {
                ui.alertFailed("Forbidden", "You must login first !");
                nav("/")
                return;
            }
            let socketData: any = await socketService.getActiveEvent();
            let eventInfo = socketData?.eventInfo;
            if (eventInfo?.is_active) {
                setEventInfo(eventInfo);
            }
            await getListProducts();
        } catch (err) {
            console.log({ err })
        }
    }

    const getListProducts = async () => {
        if (eventInfo?.is_active) {
            setLoading(true)
            try {
                let data: any = await socketService.getListProducts(itemPerPage * (page - 1), itemPerPage, rarity === Rarity.All ? undefined : rarity, owned ? user?.id : undefined);
                let mongenInfos: any[] = data.products;
                if (mongenInfos?.length >= 0) {
                    setTotal(data.total)
                    setMongenComponent(
                        <div className={`text-center w-full h-full text-white z-20 
                        flex flex-wrap justify-center gap-y-4 gap-x-6 self-center mx-auto`} style={{
                                alignContent: "baseline"
                            }}>
                            {mongenInfos.map((mongenInfo, idx) => <MongenCard
                                key={`mongen-card-${idx}`}
                                mongenInfo={mongenInfo.data}
                                disable={mongenInfo.is_pool}
                                detailUrl={detailUrl}
                            />)}
                        </div>
                    )
                } else {
                    setTotal(0);
                    setPage(1);
                }
            } catch (err) {
                setTotal(0);
                setPage(1);
                console.log(err)
            } finally {
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        authenticate();
    }, [])

    // useEffect(() => {
    //     setPage(1)
    //     setTrigger(!trigger)
    // }, [rarity])

    useMemo(() => {
        getListProducts();
        nav({
            search: `?page=${page}&rarity=${rarity}&owned=${owned}`,
        });
    }, [eventInfo, page, trigger])

    useEffect(() => {
        let page = query.get("page")
        let rarity = query.get("rarity")
        let owned = query.get("owned")

        if (page !== null) {
            setPage(Number(page));
        }
        if (rarity !== null) {
            setRarity(Number(rarity));
        }
        if (owned !== null) {
            setOwned(owned === 'true' ? true : false)
        }
    }, [])

    return (
        <div className="w-full h-full relative">
            <img src={require('../assets/chaos-event.webp')} alt="event" className='absolute w-screen object-cover h-full min-h-screen' />
            <div className="fixed top-10 text-event text-xl flex gap-2 left-[40px] cursor-pointer z-20 min-w-min max-w-min absolute"
                onClick={() => {
                    nav(backUrl)
                }}>
                <img src={require("../assets/back_btn.webp")} className="w-8 object-contain cursor-pointer" alt="back" />
                <div className="font-bold text-2xl cursor-pointer">
                    Back
                </div>
            </div>
            <div className="relative">
                <div>
                    <img src={require('../assets/chaos-special-text.webp')} alt="text" className="mx-auto pt-10" />
                </div>
                <div className="w-full h-full">
                    <div className="w-full z-10 relative h-full pb-20 max-w-[980px] mx-auto px-8">
                        <div className="h-full relative z-10 flex justify-between w-full mx-auto">
                            <div className="bg-[#F5Bd63] px-4 flex checkbox-container py-1 gap-2 h-[32px] self-center">
                                <div className="font-cookie-run text-number-event-2">Owned</div>
                                <Checkbox className="text-white relative custom-checkbox translate-y-[2px]" style={{ zIndex: "102" }}
                                    checked={owned}
                                    onChange={(e, value) => {
                                        setOwned(value.checked)
                                        if (value.checked) {
                                            setRarity(Rarity.All)
                                            setPage(1)
                                            setTrigger(!trigger)
                                        } else {
                                            setRarity(Rarity.Epic)
                                            setPage(1)
                                            setTrigger(!trigger)
                                        }
                                    }}
                                />
                            </div>
                            {<div>
                                <div className="text-xl text-number-event-chaos">Rarity</div>
                                <div className="flex gap-2 mt-2">
                                    {[Rarity.Common, Rarity.Uncommon, Rarity.Rare, Rarity.Epic, Rarity.All].map((r) => {
                                        return (
                                            <div className={`${rarity === r ? "opacity-100" : "opacity-40"} cursor-pointer`} key={r}
                                                onClick={() => {
                                                    setRarity(r)
                                                    setPage(1)
                                                    setTrigger(!trigger)
                                                }}
                                            >
                                                <img src={`/assets/images/rarity/${Rarity[r].toLocaleLowerCase()}.png`} alt="rarity" className="w-8 h-8" />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            }
                        </div>
                        <div>
                            <div className="text-center w-full h-full text-white mt-10 z-20 gap-10 self-center mx-auto align-center">
                                {loading ? <div className="text-white mx-auto self-center justify-center w-full translate-y-[130px]">
                                    <div className="loader-white mx-auto text-center"></div>
                                </div> :
                                    <>
                                        {mongenComponents}
                                    </>
                                }
                            </div>

                            {!loading && total > 0 && <div className="w-full mt-10" style={{ zIndex: 99 }}>
                                <Pagination
                                    defaultActivePage={page}
                                    totalPages={Math.ceil(total / itemPerPage)}
                                    onPageChange={(event: any, pageInfo) => {
                                        setPage(Number(pageInfo.activePage));
                                    }}
                                    firstItem={null}
                                    lastItem={null}
                                    className='bg-transparent custom-pagination mx-auto'
                                />
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
