export const WheelConfig = { Width: 1422, Height: 800 };
export enum WheelOption {
  "x0.5",
  "x0.75",
  "x0.9",
  "x1.1",
  "x1.25",
  "x1.5",
  "x2",
  "x3",
  "x5",
  "x1.5 sMAG",
  "x2 sMAG",
  "x3 sMAG",
  "x5 sMAG",
  "Jackpot",
}
