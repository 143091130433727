import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { api } from "services";

export default function TermPage() {
  const [term, setTerm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [check, setCheck] = useState<boolean>(false);
  const userInfo = useSelector((state: RootState) => state.user);

  async function acceptTerm() {
    if (!check) return;
    await api.postEventDeal("/customer/accept-term", {});
    setTerm(true);
  }
  useEffect(() => {
    async function checkTerm() {
      let rs = await api.postEventDeal("/customer/get-customer", {});
      setLoading(false);
      setTerm(rs.accept_term);
    }
    checkTerm();
  }, []);
  return loading ? (
    <></>
  ) : (
    !term && (
      <div className="absolute top-0 left-0 w-full h-full z-[999] bg-black bg-opacity-70">
        <div className="flex justify-center items-center h-full w-full">
          <div>
            <div className="border-2 border-[#562C1D] bg-[#CB8D57] text-[#5B3F2C] rounded-xl w-[600px] mx-auto">
              <div className="mt-4">
                <img src="assets/game/ui/term.png" className="mx-auto" />
              </div>
              <div className="bg-[#FDE8B1] p-4 rounded-xl mt-4">
                <div className="h-[400px]  overflow-y-auto">
                  <pre className="h-full max-h-[400px] overflow-x-scroll overflow-y-scroll px-10 text-term pb-8">
                    <p className="p-text-term-header">
                      <strong className="text-term-header">
                        {"["}MONSTERRA EVENT{"]"} TERMS AND CONDITIONS
                      </strong>
                    </p>
                    <p>
                      Participation in {"["}Monsterra Event{"]"}, website at{" "}
                      {"["}event.monsterra.io{"]"} hereinafter referred to as
                      &ldquo;<strong>operator</strong>&rdquo; or &ldquo;
                      <strong>organizer</strong>&rdquo;, is free and only based
                      on these terms and conditions of participation
                      (&ldquo;Terms&rdquo;). These Terms are not a prospectus or
                      offer document and are not a solicitation for investment
                      in Monsterra or in any product, project, or property of
                      Monsterra in any jurisdiction. These Terms do not
                      constitute an offer of securities in any jurisdiction.
                      Nothing in these Terms should be considered as a
                      recommendation for any person to participate in the sale
                      of MSTR Tokens or MAG Tokens, Monsterra NFTs, or
                      Monsterra&rsquo;s proposed product, or service. Entry into{" "}
                      {"["}
                      Monsterra Event{"]"} will be deemed as acceptance of these
                      terms and conditions.&nbsp;
                    </p>
                    <p className="p-text-term-header">
                      <strong className="text-term-header">
                        The procedure of {"["}Monsterra Event{"]"}
                      </strong>
                    </p>
                    <p>
                      The duration of {"["}Monsterra Event{"]"} extends over the
                      period stated in the initial competition post published on
                      the respective social media platforms. During this period
                      participants have the opportunity to take part in {"["}
                      Monsterra Event{"]"} online.
                    </p>
                    <p className="p-text-term-header">
                      <strong className="text-term-header">
                        Participation
                      </strong>
                    </p>
                    <p>
                      The instructions to participate in {"["}Monsterra Event
                      {"]"} will be stipulated in the initial {"["}Monsterra
                      Event{"]"} social post. {"["}Participants will need to
                      have a Monsterra account on the platform on which {"["}
                      Monsterra Event{"]"} has been posted and will have to
                      follow all of the steps as instructed on the post{"]"}
                      .&nbsp;
                    </p>
                    <p>
                      Participation is only possible within the participation
                      period. Participations received after the closing date
                      will not be considered.
                    </p>
                    <p>
                      {"["}The participant can only participate with a single
                      Monsterra account on the designated platform where the{" "}
                      {"["}
                      Monsterra Event{"]"} is posted. It is strictly forbidden
                      to use multiple email addresses or multiple profiles to
                      increase the chances of winning. Multiple entries from the
                      same person will be disqualified.&nbsp;
                    </p>
                    <p className="p-text-term-header">
                      <strong className="text-term-header">
                        Eligibility Requirements
                      </strong>
                    </p>
                    <p>
                      Eligible are natural persons who have reached the age of
                      18 years. Country-specific laws are to be observed. Your
                      participation in {"["}Monsterra Event{"]"} and your
                      claiming, receiving, using, holding, trading, buying,
                      selling, or transferring the rewards of {"["}Monsterra
                      Event
                      {"]"} is not prohibited, restricted, or regulated by any
                      law or regulation applicable to you. You are solely
                      responsible (i) to determine if there are any such laws or
                      regulations (including foreign exchange restrictions)
                      applicable to you and to comply with them and (ii) to
                      determine if there are any governmental or other consents
                      or approvals which you need to obtain, and to obtain and
                      maintain. &nbsp;
                    </p>
                    <p>
                      Should a participant be restricted in his legal capacity,
                      the consent of his legal representative is required.
                    </p>
                    <p>
                      {"["}Not eligible to participate in {"["}Monsterra Event
                      {"]"} are all persons involved in the conception and
                      implementation of {"["}Monsterra Event{"]"} and employees
                      of the organizer and their family members{"]"}. In
                      addition, the operator reserves the right, in its sole
                      discretion, to exclude persons from participation if there
                      are valid reasons, for example (a) manipulation through
                      having direct access to {"["}Monsterra Event{"]"} or
                      manipulation of the procedure of {"["}Monsterra Event
                      {"]"}, (b) a breach of these terms of participation, (c)
                      unfair behavior or (d) providing of false or misleading
                      information in connection with the participation in {"["}
                      Monsterra Event{"]"}. No responsibility can be accepted
                      for entries not received for whatever reason.
                    </p>
                    <p>
                      Eligibility to participate in {"["}Monsterra Event
                      {"]"} will also be determined by geographical location.
                      The geographical restrictions of {"["}Monsterra Event{"]"}{" "}
                      will be specified on the initial {"["}Monsterra Event
                      {"]"} post.&nbsp;
                    </p>
                    <p className="p-text-term-header">
                      <strong className="text-term-header">
                        Prize, Notification and Transmission of the winnings
                      </strong>
                    </p>
                    <p>
                      The distributed prize is described within the initial{" "}
                      {"["}
                      Monsterra Event{"]"} post, published on the respective
                      social media platforms.&nbsp;
                    </p>
                    <p>
                      {"["}The winners will be determined after the closing date
                      of {"["}Monsterra Event{"]"}.{"]"}
                    </p>
                    <p>
                      {"["}The winners of {"["}Monsterra Event{"]"} will be
                      informed about the win contemporary via {"["}&hellip;{"]"}{" "}
                      through the social platform on which {"["}Monsterra Event
                      {"]"} was posted.{"]"}&nbsp;
                    </p>
                    <p>
                      The delivery of the prize will be made exclusively to the
                      winner or to the legal representative of the winner. An
                      exchange, a transfer, a pickup, or a cash payment of the
                      winnings is not possible.&nbsp;
                    </p>
                    <p>
                      Any costs incurred for the delivery of the winnings are
                      covered by the organizer. Any additional costs associated
                      with the claiming of the prize shall be borne by the
                      winner. The winner is responsible for possible taxation of
                      the winnings.
                    </p>
                    <p>
                      If circumstances occur that are beyond the control of the
                      organizer, the respective winner accepts a reasonable
                      replacement prize. Such unenforceable circumstances are in
                      particular those that lie with the sponsors of the prizes.
                    </p>
                    <p>
                      {"["}If the winner cannot be contacted&nbsp;or does not
                      respond within three weeks after being contacted twice,
                      the prize can be transferred to another participant (if
                      any).{"]"}
                    </p>
                    <p>
                      The organizer&rsquo;s decision in respect of all matters
                      to do with {"["}Monsterra Event{"]"} will be final and no
                      correspondence will be entered into.
                    </p>
                    <p className="p-text-term-header">
                      <strong className="text-term-header">
                        Termination of {"["}Monsterra Event{"]"}&nbsp;
                      </strong>
                    </p>
                    <p>
                      The organizer expressly reserves the right, at its own
                      discretion, to terminate {"["}Monsterra Event{"]"} without
                      prior announcement and without providing any reasons. This
                      applies in particular to any reasons that would disrupt a
                      planned procedure of {"["}Monsterra Event{"]"} or would
                      prevent it from doing so. The organizer reserves the right
                      in its discretion to supplement or amend any part of these
                      Terms at any time.
                    </p>
                    <p className="p-text-term-header">
                      <strong className="text-term-header">Data Privacy</strong>
                    </p>
                    <p>
                      {"["}To consider keeping/removing or adjusting as
                      appropriate. We should collect the necessary information
                      for {"["}Monsterra Event{"]"} since those may be subject
                      to GDPR and privacy regulations of other jurisdictions and
                      further actions may be needed to take for what we collect.
                      {"]"}
                    </p>
                    <p>
                      For participation in {"["}Monsterra Event{"]"}, the
                      indication of personal data may be necessary. {"["}The
                      participant confirms, that the information provided about
                      his person, especially {"["}Game ID{"]"}
                    </p>
                    <p>
                      The organizer points out that all personal data of the
                      participant will not be passed on to third parties without
                      consent, nor will it be made available for use by third
                      parties.&nbsp;
                    </p>
                    <p>
                      In the event of winning, the winner agrees to the
                      publication of {"["}Monsterra account ID and Monsterra
                      account name{"]"} in the advertising media used by the
                      organizer. This includes the announcement of the winner on
                      the operator&rsquo;s website and social media platforms.
                    </p>
                    <p>
                      The participant can revoke his declared consent at any
                      time. The revocation must be submitted in writing to the
                      operator ({"["}at support.monsterra.io{"]"}). After
                      revoking the consent, the collected and stored personal
                      data of the participant will be deleted immediately.
                    </p>
                    <p>
                      The organizer may, if necessary or required by law,
                      provide the participant&rsquo;s personal data to law
                      enforcement agencies, regulatory organizations, courts, or
                      other public authorities. Where appropriate, the organizer
                      will attempt to notify the participant about legal demands
                      for his personal data. The participant agrees that the
                      organizer may not be able to do so if such notification is
                      prohibited by law or court order, when the request is an
                      emergency, or when the burden or expense of notifying the
                      participant would be unreasonable to the organizer. The
                      organizer may dispute such demands when the organizer
                      believes that the requests are disproportionate, vague or
                      lack proper authority, but the organizer do not promise or
                      undertake to challenge every demand.
                    </p>
                    <p className="p-text-term-header">
                      <strong className="text-term-header">
                        Facebook, Twitter, Instagram &amp; other Social Media
                        Platforms/Networks Disclaimer
                      </strong>
                    </p>
                    <p>
                      This promotion is not affiliated with Facebook, Instagram,
                      Twitter, or other similar social media platforms&nbsp;in
                      any way and is not sponsored, supported, or organized by
                      any of these parties.
                    </p>
                    <p className="p-text-term-header">
                      <strong className="text-term-header">
                        Questions or complaints&nbsp;
                      </strong>
                    </p>
                    <p>
                      Questions or complaints in connection with {"["}Monsterra
                      Event{"]"} must be addressed to the operator. Contact
                      information can be found at&nbsp;{"["}support.monsterra.io
                      {"]"}.
                    </p>
                    <p className="p-text-term-header">
                      <strong className="text-term-header">
                        Severability clause
                      </strong>
                    </p>
                    <p>
                      If any provision of these terms of participation is or
                      becomes invalid as a whole or as a part, this shall not
                      affect the validity of these terms and conditions. Instead
                      of the ineffective provision, the legally permissible
                      provision that most closely approximates the meaning and
                      purpose expressed in the invalid provision shall apply.
                      The same applies in the event of the existence of a
                      loophole in these terms of participation.
                    </p>
                    <p className="p-text-term-header">
                      <strong className="text-term-header">
                        Monsterra team wishes you Good Luck and Success!
                      </strong>
                    </p>
                    <p>
                      <br />
                    </p>
                  </pre>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <div
                className="flex mx-auto mt-4 gap-2 cursor-pointer"
                onClick={() => {
                  setCheck(!check);
                }}
              >
                {check ? (
                  <img src="assets/game/ui/checked.png" />
                ) : (
                  <img src="assets/game/ui/checkbox.png" />
                )}
                <p className="font-cookie text-white">
                  I have read, understand and agree to the terms and conditions
                </p>
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <div
                className="relative inline-block cursor-pointer"
                onClick={() => {
                  acceptTerm();
                }}
              >
                <img
                  src="assets/game/ui/button.png"
                  className={`${!check && "grayscale"}`}
                />
                <p className="mt-3 text-xl w-full h-full absolute text-white top-0 left-0 text-center font-cookie">
                  I Agree
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
