import { useState } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { api, utils } from "services";
import { Modal } from "semantic-ui-react";
import { Input, Button, Form } from "antd";
import { DatePicker } from "components";
import AuctionImage from "../auction-image";
import { useTranslation } from "react-i18next";

export default function AuctionCreate({
  setActiveTab,
  setShowCreateAuction,
  showCreateAuction,
  data,
  trigger,
  setTrigger
}: {
  setActiveTab: Function,
  setShowCreateAuction: Function,
  showCreateAuction: boolean,
  data: any,
  trigger?: boolean,
  setTrigger?: Function
}) {
  const user = useSelector((state: RootState) => state.user);
  const [form] = Form.useForm();
  const initialValues = {
    price: "",
    date: "",
    shortDescription: "",
    longDescription: ""
  }
  const { t } = useTranslation()

  const rules = {
    price: [
      {
        validator: async (_: any, value: any) => {

          if (value == "") {
            return Promise.reject(new Error("This field is required"));
          }
          if (value >= 0) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(`Amount must be greater than or equal to 0`));
        },
      }
    ],
    date: [
      {
        validator: async (_: any, value: any) => {

          if (value == "") {
            return Promise.reject(new Error("This field is required"));
          }
          if (value > 24 * 60 * 60 * 1000 + Date.now()) {
            return Promise.reject(new Error(t('invalid_time')));
          }
          if (value < Date.now()) {
            return Promise.reject(new Error(t('invalid_time')));
          }

          return Promise.resolve();

        },
      }
    ],
  }

  const [formData, setFormData] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);


  function closeModal() {
    setShowCreateAuction(false)
  }

  const createAuction = async (value: any) => {
    setIsLoading(true)
    try {
      let obj = {
        user_id: user?.id,
        end_time: 24 * 60 * 60 * 1000 + dayjs(formData.date).valueOf(),
        start_time: dayjs(formData.date).valueOf(),
        init_price: formData.price,
        short_description: formData.shortDescription,
        long_description: formData.longDescription,
        auction_type: data.dna ? 1 : 0, //0-landplot, 1-mongen
        item_id: data.id
      }
      await api.createAuctionP2P(obj)
      setTrigger(!trigger)
    } finally {
      setConfirm(false)
      setIsLoading(false)
      closeModal()
      setActiveTab("Open Auctions")
      form.resetFields();
    }
  }

  const renderConfirm = () => {
    return (<div>
      <div className="text-white text-[24px] font-bold mb-[24px]">{data?.name}</div>
      <div className="px-[12px] py-[18px] bg-[#1C1914] rounded-[6px] mb-[25px]">
        <div className="flex text-[#E1E2E9] text-normal text-[14px] mb-[14px] items-center">
          <div className="w-[135px]">
            Price:
          </div>
          <div className=" w-[215px] flex items-center text-bold text-[24px]">
            <img className="w-[24px] mr-[4px]" src={require("./mstr.png")} />
            {formData.price}
          </div>
        </div>
        <div className="flex text-[#E1E2E9] text-normal text-[14px]  mb-[14px]">
          <div className="w-[135px]">
            Start time:
          </div>
          <div className="w-[215px]">
            {dayjs(formData.date).format("YYYY/MM/DD HH:mm")}
          </div>
        </div>
        <div className="flex text-[#E1E2E9] text-normal text-[14px]  mb-[14px]">
          <div className="w-[135px]">
            Duration:
          </div>
          <div className="w-[215px]">
            1 day
          </div>
        </div>
        <div className="flex text-[#E1E2E9] text-normal text-[14px]  mb-[14px]">
          <div className="w-[135px]">
            Short description:
          </div>
          <div className="w-[215px]">
            {formData.shortDescription}
          </div>
        </div>
        <div className="flex text-[#E1E2E9] text-normal text-[14px]">
          <div className="w-[135px]">
            Long description:
          </div>
          <div className="w-[215px]">
            {formData.longDescription}
          </div>
        </div>
      </div>
      <div className="flex text-[#E1E2E9] text-[12px] font-normal items-center">
        <div className="w-[5px] h-[5px] rounded-[3px] bg-[#E1E2E9] m-[7px]" />
        Creation fee: 20 MSTR
      </div>
      <div className="flex text-[#E1E2E9] text-[12px] font-normal items-center mt-[5px]">
        <div className="w-[5px] h-[5px] rounded-[3px] bg-[#E1E2E9] m-[7px]" />
        You will receive 96% of the final bid
      </div>
      <Button
        htmlType="submit"
        loading={isLoading}
        onClick={createAuction}
        className="text-[14px] w-full h-[50px] bg-[#F9C744] btn-yellow font-semibold text-[#E89F01] flex items-center justify-center px-[24px] mt-[21px]">
        {!isLoading && <div className=" w-full grid grid-cols-2 items-center">{"Confirm"}
          <div className="flex text-[24px]">
            <img className="w-[24px] mr-[4px]" src={require("./mstr.png")} />
            20
          </div></div>}
      </Button>
    </div>)
  }

  return <Modal
    key="formText"
    open={showCreateAuction}
    style={{ width: "unset" }}
    onClose={closeModal}
    closeIcon
  >
    <div
      className="max-w-[732px] w-screen"
    >
      <div className="max-w-[732px] w-screen bg-black-secondary pb-8">
        <div>
          <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
            {confirm ? "Confirmation auction" : "Create an auction"}
          </div>
          <img src="/assets/images/hr.svg" alt="hr" className="w-full object-cover h-1 mb-9" />
          <div className="flex w-full pl-[63px]">
            <div className="w-[180px] mr-[20px]">
              <AuctionImage itemData={data} width={180} height={180} />
            </div>
            <div className="w-[352px]">
              {confirm ? renderConfirm() : <Form form={form}
                initialValues={initialValues}
                onValuesChange={(changedValues, allValues) => {
                  if (Object.keys(changedValues)[0] == "price") {
                    let price = utils.formatInputNumber(Object.values(changedValues)[0].toString(), 2, 10)
                    form.setFieldValue("price", price)
                    setFormData({
                      ...allValues,
                      price: price
                    });
                  } else {
                    setFormData({
                      ...allValues,
                    });
                  }

                }}
                onFinish={() => setConfirm(true)}>
                <div className="text-white text-[24px] font-bold mb-[24px]">{data?.name}</div>
                <div className=" flex flex-col text-[12px] py-[8px] px-[16px] w-full min-h-[56px] rounded-md border-t-2 border-[#e89f01] flex bg-white text-[#6C6D76] input-bid mb-[20px]">
                  Price:
                  <div className="flex">
                    <img
                      className="w-[20px] h-[20px] mr-[5px] mt-[5px]"
                      src={require("./mstr.png")}
                    />
                    <Form.Item className="m-0 mb-0 w-full" name="price" rules={rules.price}>
                      <Input
                        // onChange={onChangePrice}
                        className="text-black text-[14px] font-medium "
                      />
                    </Form.Item>

                  </div>
                </div>

                <div className=" flex flex-col text-[12px] py-[8px] px-[16px] w-full h-[70px] sm:h-[56px] rounded-md border-t-2 border-[#e89f01] flex bg-white text-[#6C6D76] input-bid mb-[12px]">
                  Start time ({t('invalid_time')}):
                  <div className="flex mt-[5px]">
                    <img
                      className="w-[20px] h-[20px] mr-[5px] "
                      src={require("./calendar.png")}
                    />
                    <Form.Item className="w-full" name="date" rules={rules.date}>
                      <DatePicker
                        placeholderText="Select date"
                        showTimeSelect
                        selected={formData.date && new Date(formData.date)}
                        minDate={new Date(Date.now())}
                        maxDate={new Date(Date.now() + 24 * 60 * 60 * 1000)}
                        onChange={() => { }}
                        dateFormat="yyyy/MM/dd HH:mm"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="text-white text-[12px] font-normal mb-[20px] ">
                  Duration: 1 day
                </div>
                <div className=" flex flex-col py-[8px] px-[16px] w-full min-h-[56px] rounded-md border-t-2 border-[#e89f01] flex bg-white text-[#6C6D76] input-bid mb-[20px] text-[12px]">
                  Short description (optional)
                  <Form.Item className="w-full h-full" name="shortDescription" >
                    <Input
                      type="text"
                      maxLength={32}
                      className="w-full h-full mt-[4px] text-black"
                    />
                  </Form.Item>
                </div>
                <div className=" flex flex-col pt-[8px] px-[16px] w-full min-h-[80px] rounded-md border-t-2 border-[#e89f01] flex bg-white text-[#6C6D76] input-bid mb-[20px] text-[12px] ">
                  Detail description (optional)
                  <Form.Item className="w-full h-full" name="longDescription" >
                    <Input.TextArea
                      maxLength={180}
                      className="w-full h-[60px] mt-[4px] text-[#00000]"
                    />
                  </Form.Item>
                </div>
                <div className="py-[24px] border-t border-[#3C4042]">
                  <div className="flex text-[#E1E2E9] text-[12px] font-normal items-center">
                    <div className="w-[5px] h-[5px] rounded-[3px] bg-[#E1E2E9] m-[7px]" />
                    Auction creation fee: 20 MSTR
                  </div>
                  <div className="flex text-[#E1E2E9] text-[12px] font-normal items-center">
                    <div className="w-[5px] h-[5px] rounded-[3px] bg-[#E1E2E9] m-[7px]" />
                    You will receive 96% of the final bid
                  </div>
                </div>

                <Button
                  htmlType="submit"
                  className="text-[18px] w-full text-white h-[50px] bg-[#F9C744] btn-yellow flex items-center justify-center text-[18px] font-semibold text-[#E89F01]">
                  {"Create"}
                </Button>
              </Form>}

            </div>
          </div>

        </div>
      </div>
    </div>
  </Modal >
}
