import dayjs from "dayjs";
import { EventClaimType, EventEntity, EventGroupEntity, FeatureMapping } from "interfaces";
import { useEffect, useState } from "react";
import { Modal } from "semantic-ui-react";
import { data } from "services";
import Reward from "./reward";
import { useNavigate } from "react-router-dom";
import { SSO_META } from "config";
export default function EventItem({ event }: { event: EventGroupEntity }) {
  const nav = useNavigate();
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [rankEvent, setRankEvent] = useState<EventEntity>(null);
  const [showRank, setShowRank] = useState<boolean>(false);
  function calculateTimeLeft() {
    let left = dayjs(event.end_time).diff(dayjs(), "second");
    if (left > 0) {
      setTimeLeft(left);
    }
  }
  useEffect(() => {
    calculateTimeLeft();
    let time = setInterval(() => {
      calculateTimeLeft();
    }, 1000);
    return () => {
      clearInterval(time);
    };
  }, [event]);
  const [openRule, setOpenRule] = useState<boolean>(false);
  const [openReward, setOpenReward] = useState<boolean>(false);
  function onGoClick() {
    let meta = data.getMeta();
    switch (event.feature_mapping) {
      case FeatureMapping.None:
        window.open(event.feature_mapping_url, "_blank");
        break;
      case FeatureMapping.DAOHome:
        window.open(SSO_META().DAOLink, "_blank");
        break;
      case FeatureMapping.GameHome:
        window.open(SSO_META().GameLink, "_blank");
        break;
      case FeatureMapping.MarketPlace:
        window.open(SSO_META().MarketLink, "_blank");
        break;
      case FeatureMapping.LeaderBoardChampionship:
        nav("/championship");
        break;
      case FeatureMapping.LeaderBoardHome:
        nav("/");
        break;
    }
  }
  return (
    <div className="mt-10 flex flex-col gap-2 w-full relative">
      <Modal
        basic
        open={openRule}
        onClose={() => {
          setOpenRule(false);
        }}
      >
        <div className="bg-transparent">
          <div className="bg-[#0F1011] relative rounded-lg">
            <img
              onClick={() => {
                setOpenRule(false);
              }}
              src={require("./btn-close-modal.png")}
              className="absolute -top-3 -right-3 cursor-pointer"
            />
            <p className="text-center text-2xl text-white font-cookie font-semibold p-6">
              Rule
            </p>
            <img src={require("./modal-separate.png")} className="mx-auto" />
            <pre className="text-white py-4 px-6 break-word">{event.rule}</pre>
            <div className="bg-[#292929] mt-4 p-4 rounded-b-lg">
              <img
                src={require("./btn-go.png")}
                className="h-12 mx-auto cursor-pointer"
                onClick={() => {
                  onGoClick();
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        basic
        open={openReward}
        onClose={() => {
          setOpenReward(false);
        }}
      >
        <div className="bg-transparent">
          <div className="bg-[#1c2023] relative rounded-md ">
            <img
              onClick={() => {
                setOpenReward(false);
              }}
              alt="close"
              src={require("./btn-close-modal.png")}
              className="absolute -top-3 -right-3 cursor-pointer"
            />
            <p className="text-center text-2xl text-white font-cookie font-semibold p-6">
              Rewards list
            </p>
            <img src={require("./modal-separate.png")} className="mx-auto" alt="separate" />
            <div className='py-4 px-6'>
              <div className='bg-[#1c1914] py-4 px-6'>
                <div className='flex justify-between items-center'>
                  <p className="text-white break-word font-bold">Rule:</p>
                  <img
                    src={require("./btn-go.png")}
                    className="h-8 cursor-pointer"
                    onClick={() => {
                      onGoClick();
                    }}
                  />
                </div>
                <pre className="text-white  break-word font-italic text-sm mt-2">{event.rule}</pre>

              </div>
            </div>

            {/* {event?.eventInfos[0]?.claim_type === EventClaimType.Rank && <img
              onClick={() => {
                setShowRank(true);
                setRankEvent(event.eventInfos[0]);
              }}
              alt="leaderboard"
              src={require("./btn-leaderboard.png")}
              className="mx-auto text-center mb-4 cursor-pointer"
            />} */}
            <Reward groupInfo={event} setShowRank={setShowRank} showRank={showRank} rankEvent={rankEvent} setRankEvent={setRankEvent} />
          </div>
        </div>
      </Modal>
      <img
        alt="avatar"
        src={event.avatar || require("./avatar.png")}
        className="hidden lg:block absolute left-5 bottom-5 w-full lg:w-[320px] lg:h-[210px] object-cover border-[#EBC276] border-4 rounded-xl border-solid"
      />
      <div className="border-[#563D2C] border-2 rounded-md bg-[#212025] w-full lg:pl-[350px]  p-7  justify-between flex-col lg:flex-row">
        <div className="flex flex-col lg:flex-row">
          <img
            alt="event avatar"
            src={event.avatar || require("./avatar.png")}
            className="lg:hidden w-full object-cover border-[#EBC276] border-4 rounded-xl border-solid"
          />
          <div className="flex flex-col md:flex-row justify-between w-full">
            <div className="mt-4">
              <p className="text-[#eac17a] text-2xl font-cookie">
                {event.name}
              </p>
              <p className="mt-6">
                <span className="text-[#a7a8ae] w-24 inline-block">
                  Start time:
                </span>{" "}
                <span className="text-[#e1e2e9]">
                  {dayjs(event.start_time).format("MMM-DD-YYYY HH:mm")}
                </span>
              </p>
              <p>
                <span className="text-[#a7a8ae] w-24 inline-block">
                  End time:
                </span>{" "}
                <span className="text-[#e1e2e9]">
                  {" "}
                  {dayjs(event.end_time).format("MMM-DD-YYYY HH:mm")}
                </span>
              </p>
              <div className="flex gap-2 mt-6">
                {/* <img
                  onClick={() => {
                    setOpenRule(true);
                  }}
                  src={require("./btn-view-more.png")}
                  className="h-10 cursor-pointer"
                /> */}
                <img
                  onClick={() => {
                    setOpenReward(true);
                  }}
                  src={require("./btn-reward.png")}
                  className="h-10 cursor-pointer"
                />
              </div>
            </div>
            {timeLeft > 0 && (
              <div className="items-center font-cookie flex mt-4">
                <div>
                  <p className="text-[#9b8c73] ml-3 font-cookie text-xl">
                    Time left
                  </p>
                  <div className="flex gap-2 mt-4">
                    <div className="w-20">
                      <p className="text-[#e6cea2] text-5xl text-center font-cookie">
                        {String(Math.floor(timeLeft / 86400)).padStart(2, "0")}
                      </p>
                      <p className="text-[#9b8c73] text-center mt-2">DAYS</p>
                    </div>
                    <div className="w-20">
                      <p className="text-[#e6cea2] text-5xl text-center font-cookie">
                        {String(Math.floor((timeLeft % 86400) / 3600)).padStart(
                          2,
                          "0"
                        )}
                      </p>
                      <p className="text-[#9b8c73] text-center mt-2">HOURS</p>
                    </div>
                    <div className="w-20">
                      <p className="text-[#e6cea2] text-5xl text-center font-cookie">
                        {String(Math.floor((timeLeft % 3600) / 60)).padStart(
                          2,
                          "0"
                        )}
                      </p>
                      <p className="text-[#9b8c73] text-center mt-2">MINUTES</p>
                    </div>
                    <div className="w-20">
                      <p className="text-[#e6cea2] text-5xl text-center font-cookie">
                        {String(Math.floor(timeLeft % 60)).padStart(2, "0")}
                      </p>
                      <p className="text-[#9b8c73] text-center mt-2">SECONDS</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
