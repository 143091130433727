import dayjs from "dayjs";
import {
  BodyPart,
  ChaosAnim,
  ChaosState,
  EventState,
  Rarity,
  SocketNotify,
} from "interfaces";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";
import { api, ui, utils } from "services";
import socketService from "services/socket";
import StaticAnim from "services/staticanim";
import { bulkSetUserInfo, clearUserInfo } from "reducer/user.slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducer/store";

export default function Chaos() {
  const [remain, setRemain] = useState(0);
  const [tops, setTops] = useState([]);
  const [config, setConfig] = useState(null);
  const [notifications, setNotification] = useState([]);
  const [eventInfo, setEventInfo] = useState<any>(null);
  const dispatch = useDispatch();

  const nav = useNavigate();
  const { t } = useTranslation();
  const [rewardQueue, setRewardQueue] = useState<any>({});
  const [productInfos, setProductInfos] = useState<any[]>([]);
  const [rarity, setRarity] = useState(Rarity.All);
  const [timeDiff, setTimeDiff] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openPopupBuy, setOpenBuyPopup] = useState(-1);
  const [timeOpen, setTimeOpen] = useState(0);
  const [timeOpenComponent, setTimeOpenComponent] = useState(<></>);
  const user = useSelector((state: RootState) => state.user);
  const bg_sound = useRef<any>(new Audio("/audio/xmas.mp3"));

  async function loadUserInfo() {
    let token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    let rs = await api.post("/event/get-customer-info", {});
    dispatch(
      bulkSetUserInfo({
        id: rs.A,
        name: rs.D,
        ...rs,
      })
    );
  }

  const dynamicPos = {
    x: -3.5,
    y: -7,
    y1: -1,
  };

  const width = 933.8;

  const fixedPosition = [
    { x: -58, y: -12 + dynamicPos.y1, text: "1" },
    { x: -46, y: -11.5 + dynamicPos.y1, text: "2" },
    { x: -34, y: -12 + dynamicPos.y1, text: "3" },
    { x: -21, y: -12 + dynamicPos.y1, text: "4" },
    { x: -8, y: -11.5 + dynamicPos.y1, text: "5" },
    { x: -52.5, y: -1.6, text: "6" },
    { x: -40, y: -2, text: "7" },
    { x: -28, y: -2, text: "8" },
    { x: -16, y: -2, text: "9" },
    { x: -2, y: -2, text: "10" },
  ];

  const scrollNoti = () => {
    let notiContainer = document.getElementById("noti-container");
    if (notiContainer) {
      notiContainer.scrollTop = notiContainer.scrollHeight;
    }
  };

  const [animMode, setAnimMode] = useState(ChaosAnim.Idle);

  let listener = async (data: any) => {
    console.log("notify ", data);
    let notifyType = data.notify;
    let payload = data.payload;
    switch (notifyType) {
      case SocketNotify.CustomerJoinPool:
        if (payload?.customerPool) {
          setRewardQueue(payload.customerPool || {});
        }
        if (payload?.eventInfo) {
          setEventInfo(payload.eventInfo);
          setTimeDiff(
            dayjs(payload.eventInfo.next_pool_at).diff(
              payload.eventInfo.now,
              "second"
            )
          );
        }
        break;
      case SocketNotify.OpenConfirm:
        if (payload?.eventInfo) {
          setEventInfo(payload.eventInfo);
          setTimeDiff(
            dayjs(payload.eventInfo.next_pool_at).diff(
              payload.eventInfo.now,
              "second"
            )
          );
        }
        if (payload?.remainProduct !== null) {
          setRemain(payload.remainProduct);
        }
        resetPosition();
        break;
      case SocketNotify.EventStart:
        await getEventInfo();
        resetPosition();
        break;
      case SocketNotify.EventOpen:
        await getEventInfo();
        resetPosition();
        break;
      case SocketNotify.EventFinish:
        await getEventInfo();
        resetPosition();
        break;
      case SocketNotify.SpinResult:
        if (payload?.noti) {
          let noties = payload.noti ?? [];
          let newNotis = noties.map((data: any) => {
            let formatValue = {
              name: data.name,
              dna: data.productInfo.data.dna,
              id: data.productInfo.data.id,
            };
            return formatValue;
          });
          setNotification(newNotis);
          scrollNoti();
        }
        if (payload) {
          setRemain(payload.remainProduct);
          setProductInfos(payload.productInfos);
          setAnimMode(ChaosAnim.Opened);
          setEventInfo(payload.eventInfo);
          setTimeDiff(
            dayjs(payload.eventInfo.next_pool_at).diff(
              payload.eventInfo.now,
              "second"
            )
          );
          setTimeout(() => {
            console.log("anim changed");
            setAnimMode(ChaosAnim.Mongen);
          }, 3 * 1000);
          setTops(payload.leaderboardCustomer ?? []);
        }
        break;
    }
  };

  const handleNotify = async () => {
    let socketInstance = socketService.getInstance();
    if (socketInstance) {
      socketInstance.on("notify", listener);
    }
  };

  const getEventInfo = async () => {
    let dataSocket: any = await socketService.getActiveEvent();
    let eventInfo = dataSocket?.eventInfo;

    setConfig(dataSocket?.config);
    if (eventInfo?.is_active) {
      setEventInfo(eventInfo);
    }

    if (dataSocket.noti?.length > 0 && notifications.length === 0) {
      setNotification(
        dataSocket.noti.reverse().map((noti: any) => {
          return {
            name: noti.name,
            dna: noti.productInfo.data.dna,
            id: noti.productInfo.data.id,
          };
        })
      );
      scrollNoti();
    }

    setRemain(dataSocket.remainProduct);
    setTops(dataSocket.leaderboardCustomer ?? []);
    setRewardQueue(dataSocket.customerPool || {});
    setTimeDiff(dayjs(eventInfo.next_pool_at).diff(eventInfo.now, "second"));
    if (eventInfo.state === ChaosState.Pending) {
      setTimeOpen(dayjs(eventInfo.open_at).diff(eventInfo.now, "second"));
    }
  };

  const authenticate = async () => {
    try {
      let token = localStorage.getItem("token");
      if (!token) {
        ui.alertFailed("Forbidden", "You must login first !");
        nav("/");
        return;
      }
      let data: any = await socketService.authenticate(token);
      if (!data?.userInfo) {
        ui.alertFailed("Forbidden", "You must login first !");
        nav("/");
        return;
      }

      await getEventInfo();
    } catch (err) {
      console.log({ err });
    }
  };

  const joinQueue = useCallback(
    async (position: number) => {
      try {
        if (!eventInfo?.id) {
          return;
        }
        await socketService.joinQueue(eventInfo.id, position);
        await loadUserInfo();
      } catch (err: any) {
        console.log({ err });
        ui.alertFailed("Failed to join", t(err?.message ?? err));
      }
    },
    [eventInfo?.id]
  );

  const clear = () => {
    // bg_sound.current.pause();
    // bg_sound.current.currentTime = 0;
  };

  useEffect(() => {
    // bg_sound.current.play();
    return () => {
      socketService.getInstance().removeAllListeners("notify");
      // clear();
    };
  }, []);

  const productComponents = useMemo(() => {
    const innerWidth = window.innerWidth;
    let mobileAffected = { x: 0, y: 0 };
    if (innerWidth <= 955) {
      mobileAffected = {
        x: 3,
        y: -1,
      };
    }

    return (
      <div className="">
        {Array.from({ length: 10 }).map((_, pos) => {
          let itemInfo = rewardQueue[pos];
          let customerName = itemInfo?.customerName;
          let productInfo = productInfos[pos]?.data;
          let x =
            ((fixedPosition[pos].x + dynamicPos.x + mobileAffected.x) * width) /
            100;
          let y =
            ((fixedPosition[pos].y + dynamicPos.y + mobileAffected.y) * width) /
            100;

          let isStarted = eventInfo?.state === ChaosState.Start;
          return (
            <div
              className="w-[95px] absolute right-0 bottom-0"
              style={{
                transform: `translateX(${x}px) translateY(${y}px)`,
              }}
            >
              {productInfo && animMode === ChaosAnim.Mongen && (
                <div
                  className={`mongen-${animMode} text-white max-w-[200px] absolute z-40 translate-y-[32px] translate-x-[15px] cursor-pointer`}
                  onClick={() => {
                    nav(`/chaos/list/${productInfo.id}`);
                  }}
                >
                  <div>
                    <StaticAnim lock={[]} dna={productInfo.dna} />
                  </div>
                  {customerName && (
                    <div
                      className={`text-sm truncate text-ellipsis text-center text-black cursor-pointer font-bold -translate-y-[20px] bg-black rounded-lg text-${
                        Rarity[productInfo.dna[BodyPart.Aura][0]]
                      }`}
                    >
                      Player {customerName}
                    </div>
                  )}
                  {!customerName && (
                    <div
                      className={`text-sm truncate text-ellipsis text-center text-black cursor-pointer font-bold -translate-y-[20px] bg-black rounded-lg text-burned`}
                    >
                      Burned
                    </div>
                  )}
                </div>
              )}

              {
                <div
                  className="text-white text-2xl cursor-pointer px-10 justify-center w-[120px] my-10 relative flex anim-box"
                  key={`box-${pos}`}
                  onClick={async () => {
                    let isAvailable = true;
                    if (customerName || !isStarted) {
                      return;
                    }
                    Object.keys(rewardQueue).map((i) => {
                      let data = rewardQueue[i];
                      if (data && data?.customerId === user?.id) {
                        isAvailable = false;
                      }
                      return i;
                    });
                    if (!isAvailable) {
                      console.log("fe blocked");
                      ui.alertFailed("", t("customer_joined_pool_before"));
                      return;
                    }
                    setOpenBuyPopup(pos);
                  }}
                >
                  {!productInfo && animMode === ChaosAnim.Idle && isStarted && (
                    <>
                      <img
                        src={require("../assets/giftbox.webp")}
                        alt="box"
                        className={`absolute h-[95px] w-[95px] glow unselectable giftbox-${animMode}`}
                      />
                      {customerName && animMode === ChaosAnim.Idle && (
                        <div className="text-white self-center p-1 text-center text-sm truncate text-ellipsis max-w-[90px] bg-black rounded-lg w-max-min z-[60] absolute">
                          {customerName}
                        </div>
                      )}
                    </>
                  )}
                  <img
                    src={require("../assets/openedbox.webp")}
                    alt="box"
                    className={`absolute h-[95px] w-[120px] -translate-x-[20px] glow unselectable openedbox-${animMode}`}
                  />
                  {animMode !== ChaosAnim.Mongen && isStarted && (
                    <div className="text-center text-white font-bold absolute top-[17px]">
                      {pos + 1}
                    </div>
                  )}
                </div>
              }
            </div>
          );
        })}
      </div>
    );
  }, [rewardQueue, productInfos, joinQueue, animMode]);

  const resetPosition = () => {
    setProductInfos([]);
    setRewardQueue({});
    setAnimMode(ChaosAnim.Idle);
  };

  useEffect(() => {
    authenticate();
  }, []);

  useEffect(() => {
    let socketInstance = socketService.getInstance();
    socketInstance.off("notify", listener);
    socketService.getInstance().removeListener("notify", listener);
    handleNotify();
    return () => {
      let socketInstance = socketService.getInstance();
      socketInstance.off("notify", listener);
      socketService.getInstance().removeListener("notify", listener);
    };
  }, [notifications.length]);

  useEffect(() => {
    let interval: any;
    if (timeDiff > 0) {
      interval = setInterval(() => {
        setTimeDiff(timeDiff - 1);
      }, 1000);
    } else {
      setTimeDiff(0);
    }
    return () => {
      clearInterval(interval);
    };
  }, [timeDiff]);

  useEffect(() => {
    let interval: any;
    if (timeOpen > 0) {
      interval = setInterval(() => {
        setTimeOpen(timeOpen - 1);
      }, 1000);
    } else {
      setTimeOpen(0);
    }

    let startText = utils.getCountdownTextBySeconds(timeOpen);
    if (startText) {
      setTimeOpenComponent(
        <div className="text-[18px]">
          {startText["hours"] +
            "h " +
            startText["minutes"] +
            "m " +
            startText["seconds"] +
            "s"}
        </div>
      );
    }

    return () => {
      clearInterval(interval);
    };
  }, [timeOpen]);

  useEffect(() => {
    let scrollAuto = document.getElementById("chaos-section");
    if (scrollAuto) {
      window.scrollTo({
        behavior: "smooth",
        left: 0,
        top: scrollAuto.offsetTop - 80,
      });
    }
    window.addEventListener("focus", async () => await getEventInfo());
    return () => {
      window.removeEventListener("focus", async () => await getEventInfo());
    };
  }, [!!document.getElementById("chaos-section")]);

  useEffect(() => {
    let interval = setInterval(() => {
      let audio = document.getElementById("audio");
      // @ts-ignore
      if (audio.paused) {
        // @ts-ignore
        audio.play();
      }
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="w-full h-full relative">
      <audio controls autoPlay loop className="hidden" id="audio">
        <source src="/audio/xmas.mp3" type="audio/mpeg" />
      </audio>
      <Modal
        open={openPopupBuy > -1}
        onClose={() => {
          return false;
        }}
        className="top-[25vh] "
      >
        <div className="bg-celest-event max-w-[92vw] w-[630px] h-[401px] relative md:mx-auto">
          <img
            src={require("../assets/brightness-board.webp")}
            alt="board"
            className="w-[630px] h-[401px] absolute"
          />
          <div
            className={`flex gap-4 justify-center self-center align-center absolute w-full top-[100px]`}
            style={{ zIndex: "30" }}
          >
            <img
              src={require("../assets/giftbox.webp")}
              alt="box"
              className="absolute h-[120px] mx-auto text-center self-center"
            />
          </div>
          <div className="absolute w-full text-center top-10 text-black font-bold font-cookie-run text-center mx-auto">
            <div className="mt-40 font-cookie-run">
              <span className="block w-full">Do you want to</span>
              <span>spend</span>{" "}
              <span className="text-2xl text-[#C33D16] font-cookie-run">
                {eventInfo?.fee}
              </span>{" "}
              <span> MSTR to purchase this gift box ?</span>
            </div>
          </div>
          <div
            className={`flex gap-4 justify-center self-center align-center absolute w-full bottom-10 ${
              eventInfo && !loading
                ? "cursor-pointer"
                : "cursor-not-allowed opacity-60"
            }`}
            style={{ zIndex: "30" }}
          >
            <img
              src={require("../assets/cancel_btn.webp")}
              className="h-10 object-cover cursor-pointer"
              onClick={async () => {
                if (loading) {
                  return;
                }
                setOpenBuyPopup(-1);
              }}
              alt="btn"
            />
            <div className="relative">
              <img
                src={require("../assets/confirm_btn.webp")}
                className={`h-10 object-cover ${
                  eventInfo && !loading
                    ? "cursor-pointer"
                    : "cursor-not-allowed opacity-60"
                }`}
                onClick={async () => {
                  if (loading) {
                    return;
                  }
                  setLoading(true);
                  try {
                    await joinQueue(openPopupBuy);
                  } catch (err: any) {
                    console.log({ err });
                    ui.alertFailed("Confirm failed!", t(err?.message ?? err));
                  } finally {
                    setLoading(false);
                    setOpenBuyPopup(-1);
                  }
                }}
                alt="btn"
              />
              {loading && (
                <div className="absolute text-white mx-auto self-center justify-center w-full -translate-y-[30px]">
                  <div className="loader-sm mx-auto text-center"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <img
        src={require("../assets/chaos-event.webp")}
        alt="event"
        className="absolute w-screen object-cover h-full"
      />
      <div className="w-full z-30 relative h-full">
        <img
          src={require("../assets/banner.webp")}
          alt="event"
          className="w-screen"
        />

        <div
          className="ipadpro:flex ipadpro:w-screen gap-3 justify-center self-center text-center"
          id="chaos-section"
        >
          <div
            className="mt-4 md:mt-0 px-10 md:px-0 mx-auto ipadpro:mr-0 ipadpro:ml-0"
            style={{
              maxWidth: "955px",
              maxHeight: "546px",
              minWidth: "955px",
              minHeight: "546px",
            }}
          >
            <img
              src={require("../assets/chaos-text.webp")}
              alt="text"
              className="max-w-[80vw] text-center"
            />
            <div
              className="relative w-full h-full mx-auto self-center scale-[0.4] -translate-x-[32%] -translate-y-[25%] md:-translate-x-[0%] md:-translate-y-[0%] md:scale-[1]"
              style={{}}
            >
              <img
                src={require("../assets/play-bg.webp")}
                className="h-[560px]"
                alt="bg"
              />
              <img
                src={require("../assets/snow-top.webp")}
                className="absolute top-0 -translate-y-[20%] left-0 z-20"
                alt="bg"
              />
              <img
                src={require("../assets/snow-bot.webp")}
                className="absolute bottom-0 hidden sm:block md:translate-y-[50%] right-0 z-20"
                alt="bg"
              />

              <div className="absolute top-0 px-3 pt-6 h-full w-full">
                <img
                  src={require("../assets/banner_xmas_chaosevent.webp")}
                  className="w-full h-full object-cover"
                  alt="bg"
                />
              </div>
              <div className="absolute top-[20px] left-[10px]">
                <img src={require("../assets/countdown-bg.webp")} alt="bg" />
                {[
                  ChaosState.Open,
                  ChaosState.RollingPool,
                  ChaosState.Start,
                ].includes(eventInfo?.state) && (
                  <>
                    <div className="absolute top-[50px] text-center w-full text-number-event-4">
                      Count Down
                    </div>
                    {!(
                      eventInfo?.state === ChaosState.Start &&
                      !Object.keys(rewardQueue).filter((i) => !!rewardQueue[i])
                        .length
                    ) ? (
                      <div className="absolute top-[80px] text-center w-full text-countdown-event text-3xl">
                        {Math.floor(timeDiff / 60)}m {timeDiff % 60}s
                      </div>
                    ) : (
                      <div className="absolute top-[80px] text-center w-full text-countdown-event text-3xl">
                        Ready
                      </div>
                    )}
                  </>
                )}
                {eventInfo?.state === ChaosState.Finish && (
                  <div className="absolute top-[60px] text-center w-full text-countdown-event text-3xl">
                    Sold Out
                  </div>
                )}
                {eventInfo?.state === ChaosState.Pending && (
                  <>
                    <div className="absolute top-[50px] text-center w-full text-number-event-4">
                      Open in
                    </div>
                    <div className="absolute top-[80px] text-center w-full text-countdown-event">
                      {timeOpenComponent}
                    </div>{" "}
                    :
                  </>
                )}
              </div>
              {![ChaosState.Finish, ChaosState.Pending].includes(
                eventInfo?.state
              ) && (
                <div className="absolute top-[20px] right-[10px]">
                  <img src={require("../assets/countdown-bg.webp")} alt="bg" />
                  <div className="absolute top-[50px] text-center w-full text-number-event-4">
                    Mongen left
                  </div>
                  <div className="absolute top-[80px] text-center w-full text-countdown-event text-3xl">
                    {remain + 10 ?? 0}
                  </div>{" "}
                  :
                </div>
              )}

              {[ChaosState.Start, ChaosState.RollingPool].includes(
                eventInfo?.state
              ) && productComponents}
            </div>

            <div
              className="hidden md:block mt-10 text-center w-full mx-auto gap-4 justify-center self-center align-center w-full"
              style={{ zIndex: "30" }}
            >
              <img
                src={require("../assets/reward_list_btn.webp")}
                className="h-12 object-cover cursor-pointer mx-auto"
                onClick={() => {
                  nav("/chaos/list");
                }}
                alt="btn"
              />
            </div>
          </div>

          <div className="md:mt-60 ipadpro:mt-0 relative h-full ipadpro:h-[600px] md:flex ipadpro:block">
            <div className="mx-auto text-center w-full text-white text-[10px] relative w-[305px] translate-x-[4px] h-[430px]">
              <img
                src={require("../assets/top-bg.webp")}
                alt="noti"
                className="absolute w-full"
              />
              <div className="absolute w-full w-[276px] px-2 mx-[20px] text-center top-[20px] max-h-[400px] overflow-y-scroll font-cookie">
                <div className="text-4xl text-top-ten font-cookie-bold font-bold">
                  Top 10
                </div>
                <div className="flex flex-wrap justify-between mt-2">
                  <div className="text-xl text-number-event-chaos">Rarity</div>
                  <div className="flex gap-2">
                    {[
                      Rarity.Common,
                      Rarity.Uncommon,
                      Rarity.Rare,
                      Rarity.Epic,
                      Rarity.All,
                    ].map((r) => {
                      return (
                        <div
                          className={`${
                            rarity === r ? "opacity-100" : "opacity-60"
                          } cursor-pointer`}
                          key={r}
                          onClick={() => {
                            setRarity(r);
                          }}
                        >
                          <img
                            src={`/assets/images/rarity/${Rarity[
                              r
                            ].toLocaleLowerCase()}.png`}
                            alt="rarity"
                            className="w-8 h-8"
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div>
                  <div className="flex flex-wrap justify-between mt-2 text-lg text-[#D6D1D1]">
                    <div>Player name</div>
                    <div>Received</div>
                  </div>
                  {tops[rarity]?.map((i: any, idx: any) => (
                    <div className="flex flex-wrap justify-between mt-2 text-[13px] text-white">
                      <div>
                        {idx + 1}. {i?.name}
                      </div>
                      <div>{i?.score ?? 0} Mongens</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="mx-auto text-center w-full text-white text-[10px] relative w-[296px] h-[430px]">
              <img
                src={require("../assets/noti-bg.webp")}
                alt="noti"
                className="absolute hidden ipadpro:block"
              />
              <img
                src={require("../assets/top-bg.webp")}
                alt="noti"
                className="absolute w-full block ipadpro:hidden"
              />
              <div className="absolute w-full ipadpro:w-[256px] mx-[20px] text-center max-h-[360px] ipadpro:max-h-[140px] top-[30px] overflow-y-scroll">
                {notifications.map((noti) => {
                  return (
                    <div
                      className="mb-2 text-left cursor-pointer"
                      onClick={() => {
                        nav(`/chaos/list/${noti.id}`);
                      }}
                    >
                      {noti.name} received
                      <span
                        className={`text-${
                          Rarity[noti.dna[BodyPart.Aura][0]]
                        } font-bold`}
                      >
                        {" "}
                        01 {Rarity[noti.dna[BodyPart.Aura][0]]} Chaos #{noti.id}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div
            className="block md:hidden mt-10 text-center w-full mx-auto gap-4 justify-center self-center align-center w-full"
            style={{ zIndex: "30" }}
          >
            <img
              src={require("../assets/reward_list_btn.webp")}
              className="h-12 object-cover cursor-pointer mx-auto"
              onClick={() => {
                nav("/chaos/list");
              }}
              alt="btn"
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-center mt-20 ipadpro:mt-32 gap-x-40 gap-y-20 pb-10">
          <div className="max-w-[600px] px-10 md:px-0">
            <img src={require("../assets/rule-text.webp")} alt="bg" />
            <div
              className="text-white mt-4 text-lg"
              style={{
                lineHeight: "20px",
              }}
            >
              <p>
                There are 10 Christmas gift boxes on the screen, each gift box
                will contain 1 Chaos Mongen with random rarity. The fastest 10
                players use {eventInfo?.fee ?? 0} MSTR to pick out a gift box
                for themselves.
              </p>
              <br className="" />
              <p>
                After 10 people choose the gift box or the 5 minute countdown
                ends, the gift box will be automatically opened and prepared for
                the next gift selection.
              </p>
              <br className="" />
              <p>
                Each round of gift opening, players will only be able to choose
                a single gift box.
              </p>
              <br className="" />
              <p>
                If more than 1 player chooses the same gift box, the person who
                confirms using MSTR the earliest will get the gift box. Other
                people will have to choose another gift box.
              </p>
              <br className="" />
              <p>The gift will be sent to the player's in-game inventory.</p>
            </div>
          </div>

          <div className="relative w-[80%] max-w-[500px]">
            <img
              src={require("../assets/video-background.webp")}
              alt="bg"
              className="w-full h-full"
            />
            <img
              src={require("../assets/play_btn.webp")}
              alt="bg"
              className="w-[50px] mx-auto absolute text-center cursor-pointer"
              style={{
                top: "calc(50%)",
                left: "calc(50% - 25px)",
              }}
              onClick={() => {
                window.open(
                  "https://www.youtube.com/embed/pvSGEjgQ-JQ",
                  "_blank"
                );
              }}
            />
            <div
              className="absolute top-[28.5%] left-[10%]"
              style={{
                width: "calc(100% - 19%)",
                height: "63%",
              }}
            >
              <iframe
                title="tutorial"
                className="w-full h-full"
                src="https://www.youtube.com/embed/pvSGEjgQ-JQ"
              ></iframe>
            </div>
          </div>
        </div>
        {/* <div className="absolute sticky right-10 bottom-10 w-[150px] h-[100px] ml-auto">
          <iframe width="1"
            height="1"
            src="https://www.youtube.com/embed/i-0wP_951dM?autoplay=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          >
          </iframe>
        </div> */}
        <img
          src={require("../assets/footer.webp")}
          className="w-screen"
          alt="bg"
        />
      </div>
    </div>
  );
}
