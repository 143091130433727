import { getAnimName, getSkinList } from "./spineutils";

export default class MongenPlayer {
  dna: number[][];
  x: number;
  y: number;
  scale: number;
  public sprite: any;
  constructor(
    scene: Phaser.Scene,
    container: Phaser.GameObjects.Container,
    dna: number[][],
    x: number,
    y: number,
    scaleX: number,
    scaleY: number
  ) {
    this.dna = dna;
    this.x = x;
    this.y = y;
    this.dna = dna;
    this.sprite = scene.make
      // @ts-ignore
      .spine({
        width: 10,
        height: 10,
        x,
        y,
        key: "mongenAnimation",
        loop: true,
      })
      .setDepth(3);
    this.sprite.setScale(scaleX, scaleY);
    container.add(this.sprite);
    let skins = getSkinList(dna);
    // @ts-ignore
    let mainSkin = this.sprite.skeleton.data.findSkin(skins[0]);
    for (var i = 1; i < skins.length; i++) {
      mainSkin.addSkin(this.sprite.skeleton.data.findSkin(skins[i]));
    }
    this.sprite.skeleton.setSkin(mainSkin);
    this.sprite.play(getAnimName("Idle", dna), true);
  }
  public setDNA(dna: number[][]) {
    console.log("set dna ", dna);
    let skins = getSkinList(dna);
    // @ts-ignore
    let mainSkin = this.sprite.skeleton.data.findSkin(skins[0]);
    for (var i = 1; i < skins.length; i++) {
      mainSkin.addSkin(this.sprite.skeleton.data.findSkin(skins[i]));
    }
    this.sprite.skeleton.setSkin(mainSkin);
  }
  public playAnimation(name: string, loop: boolean) {
    this.sprite.play(getAnimName(name, this.dna), loop);
  }
}
