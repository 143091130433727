import "dayjs"
import { BodyPart, MongenRace } from "interfaces";
import { useNavigate } from "react-router-dom";
import { utils } from "services";
import StaticAnim from "services/staticanim";

export const MongenCard = ({ mongenInfo, disable, detailUrl }: { mongenInfo: any, disable: any, detailUrl: string }) => {
  const nav = useNavigate();
  const stats = utils.getMongenStat(mongenInfo.dna, mongenInfo.level, mongenInfo.evolution);

  return (
    <div className={`w-[144px] h-[214px] mx-auto cursor-pointer ${disable ? "mongen-bought" : "chaos-card"}`} onClick={() => {
      nav(`${detailUrl}/${mongenInfo.id}`)
    }}>
      <div className="w-full relative">
        <img src={require("../assets/mongen-card-chrismas.png")} className={`w-full h-full object-cover`} alt="bg" />
        <div className="w-full absolute self-center -translate-y-[160px] force-image-chaos">
          {mongenInfo?.dna && <StaticAnim
            lock={[]}
            dna={mongenInfo.dna}
          />}
          <div className="text-[12px] text-number-event-4 text-semibold">Mongen #{mongenInfo.id}</div>
        </div>
        <div className="">
          <img src={utils.getRarityIcon(mongenInfo.dna[BodyPart.Aura][0] ?? 0)}
            alt="rarity"
            className="absolute right-[8.2px] top-[24px] w-7 h-7" />
          <img src={`/assets/images/race/${MongenRace[mongenInfo.dna[BodyPart.Form][0]].toString().toLowerCase()}.png`}
            alt="race"
            className="absolute right-[7px] top-[59px] w-8 h-8" />
          <img src={`/assets/images/icons/star-full.png`}
            alt="race"
            className="absolute left-[8px] top-[22px] w-7 h-7" />
          {/* {stats && <div
            style={{ transform: "scale(0.9) translateY(-37px)" }}
            className="text-black font-bold absolute justify-center mx-auto w-full text-[12px] grid grid-cols-4 self-center">
            {stats.length > 0 && <div className="flex gap-1 text-black justify-center">
              <img src="/assets/images/stats/hp.svg" alt="hp" className="translate-y-[2px] object-contain h-4" />
              <span className="-translate-y-[6px]">{stats[0]}</span>
            </div>}
            {stats.length > 1 && <div className="flex gap-1 text-black justify-center">
              <img src="/assets/images/stats/intel.svg" alt="intel" className="translate-y-[2px] object-contain h-4" />
              <span className="-translate-y-[6px]">{stats[1]}</span>
            </div>}
            {stats.length > 2 && <div className="flex gap-1 text-black justify-center">
              <img src="/assets/images/stats/shield.svg" alt="shield" className="translate-y-[2px] object-contain h-4" />
              <span className="-translate-y-[6px]">{stats[2]}</span>
            </div>}
            {stats.length > 3 && <div className="flex gap-1 text-black justify-center">
              <img src="/assets/images/stats/magic.svg" alt="magic" className="translate-y-[2px] object-contain h-4" />
              <span className="-translate-y-[6px]">{stats[3]}</span>
            </div>}
          </div>} */}
        </div>
      </div>
    </div >
  )
}
