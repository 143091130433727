const RACES = ["Beast", "Tectos", "Mythic", "Celest", "Chaos"];
const TIERS = ["D", "C", "B", "A", "S"];
const BodyPart = {
  Form: 0,
  Head: 1,
  Eyes: 2,
  Horns: 3,
  Tail: 4,
  Back: 5,
  FrontLeg: 6,
  BackLeg: 7,
  Mouth: 8,
  Aura: 9,
};
const BackMap: any = { 0: 3, 2: 2, 1: 1, 3: 4, 4: 5 };
export function getSkinList(dna: number[][]) {
  function gen(bodypart: number, name: string) {
    return `${RACES[dna[bodypart][0]]}/${TIERS[dna[bodypart][1]]}/${String(
      dna[bodypart][2]
    ).padStart(2, "0")}/${name}`;
  }
  function genBack(bodypart: number, name: string) {
    return `${RACES[dna[bodypart][0]]}/${TIERS[dna[bodypart][1]]}/${String(
      dna[bodypart][2]
    ).padStart(2, "0")}/${name}_${BackMap[dna[BodyPart.Form][0]]}`;
  }
  function genMouth() {
    return `Decor/Mouth/Mouth_${(dna[BodyPart.Mouth][2] + "").padStart(
      2,
      "0"
    )}`;
  }
  //form
  return [
    //mouth
    // gen(BodyPart.Mouth, "Mouth"),
    gen(BodyPart.Form, "Body"),
    genBack(BodyPart.Head, "Head"),
    genBack(BodyPart.Eyes, "Eyes"),
    gen(BodyPart.Horns, "Horns"),
    genBack(BodyPart.Tail, "Tail"),
    genBack(BodyPart.Back, "Back"),
    gen(BodyPart.FrontLeg, "FrontLegs"),
    gen(BodyPart.BackLeg, "BackLegs"),
    genMouth(),
  ];
}

export function getAnimName(anim: string, dna: number[][]) {
  let name = RACES[dna[BodyPart.Form][0]];
  return `${name}/${name}_${anim}`;
}
