import "App.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-image-lightbox/style.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "layout";
import ModalManager from "modal";
import { useEffect, useState } from "react";
import { api } from "services";
import { useDispatch, useSelector } from "react-redux";
import { bulkSetUserInfo, clearUserInfo } from "reducer/user.slice";
import { clearAuthInfos } from "reducer/wallet.slice";
import { CustomerInterface } from "interfaces";
import { RootState } from "reducer/store";
function App() {
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const user: any = useSelector((state: RootState) => state.user);

  async function loadUserInfo() {
    let token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    let rs = await api.post("/event/get-customer-info", {});
    dispatch(bulkSetUserInfo({
      id: rs.A,
      name: rs.D,
      ...rs
    }));
  }

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      dispatch(clearUserInfo());
      dispatch(clearAuthInfos());
    }
  }, [localStorage.getItem("token")])

  useEffect(() => {
    api.getSSOMeta().then(() => {
      setIsLoading(false);
    }).catch(() => {
      setTimeout(() => {
        setTrigger(!trigger)
      }, 5000)
    });
  }, [trigger])

  useEffect(() => {
    window.addEventListener("focus", loadUserInfo);
    return () => {
      window.removeEventListener("focus", loadUserInfo);
    }
  }, [user?.[CustomerInterface.id]])

  if (isLoading) return <></>

  return (
    <div>
      <Router basename="/">
        <ModalManager />
        <Routes>
          <Route path="/*" element={<Layout />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
