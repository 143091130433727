import e from "express";
import {
  CustomerClaimEventLogEntity,
  EventClaimType,
  EventEntity,
  EventGroupEntity,
  EventMilestone,
  EventPropertyValue,
  RewardOpenType,
  RewardType,
} from "interfaces";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { Modal } from "semantic-ui-react";
import { api } from "services";
import EventRank from "./event-rank";
import ListReward from "./list-reward";

export default function Reward({
  groupInfo,
  showRank,
  setShowRank,
  rankEvent,
  setRankEvent,
  eventId,
}: {
  groupInfo: EventGroupEntity;
  showRank: any;
  setShowRank: any;
  rankEvent: any;
  setRankEvent?: Function;
  eventId?: number;
}) {
  const [claimInfos, setClaimInfos] =
    useState<CustomerClaimEventLogEntity[]>(null);
  const [propertyInfos, setPropertyInfos] =
    useState<EventPropertyValue[]>(null);
  const [received, setReceived] = useState<any[]>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const user = useSelector((state: RootState) => state.user);
  async function loadClaimInfo() {
    if (!user?.id) {
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      let rs = await api.post("/event/get-event-claim-info", {
        event_ids: groupInfo?.eventInfos?.map((e: EventEntity) => e.id),
      });
      setClaimInfos(rs.claimInfos);
      setPropertyInfos(rs.propertyInfos);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadClaimInfo();
  }, [groupInfo]);
  async function claimMilestone(event: EventEntity, milestoneIndex: number) {
    let rs = await api.post("/event/claim-event-milestone", {
      event_id: event.id,
      milestone: milestoneIndex,
    });
    if (rs.rewards) {
      setReceived(rs.rewards);
    }
    await loadClaimInfo();
  }
  function renderClaimButton(event: EventEntity, milestoneIndex: number) {
    if (loading) {
      return <p>Loading...</p>;
    }
    let btnType: "disable" | "claim" | "claimed" = "disable";
    let claimed = claimInfos?.find(
      (c: CustomerClaimEventLogEntity) => c.event_id === event.id
    );
    let property = propertyInfos?.find(
      (p: EventPropertyValue) => p.event_id === event.id
    );
    if (user?.id) {
      if (property) {
        if (!property?.claimables[milestoneIndex]) {
          btnType = "disable";
        }
        if (property?.claimables[milestoneIndex]) {
          if (
            claimed?.claimed[milestoneIndex] &&
            claimed?.claimed[milestoneIndex] >=
              property.claimables[milestoneIndex]
          ) {
            btnType = "claimed";
          } else {
            btnType = "claim";
          }
        }
      } else {
        btnType = "disable";
      }
      if (
        (event.claim_type === EventClaimType.Final ||
          event.claim_type === EventClaimType.Rank) &&
        new Date(event.end_time) > new Date()
      ) {
        btnType = "disable";
      }
    }
    let reward: any = null;
    if (
      event.milestones &&
      event.milestones[milestoneIndex] &&
      event.milestones[milestoneIndex].rewards &&
      event.milestones[milestoneIndex].rewards[0]
    ) {
      reward = event.milestones[milestoneIndex].rewards[0];
    }
    if (reward && reward.reward_type !== RewardType.AWish) {
      switch (btnType) {
        case "disable":
          return (
            <img
              src={require("./btn-claim.png")}
              className="h-12 object-contain opacity-50 w-full"
            />
          );
        case "claim":
          return (
            <div>
              <img
                src={require("./btn-claim.png")}
                className="h-12 object-contain cursor-pointer"
                onClick={() => {
                  claimMilestone(event, milestoneIndex);
                }}
              />
              <p className="text-center text-xs mt-1">
                {claimed?.claimed[milestoneIndex] || 0}/
                {property.claimables[milestoneIndex]}
              </p>
            </div>
          );
        case "claimed":
          if (event.claim_type === EventClaimType.DuringRepeat) {
            return (
              <div>
                <img
                  src={require("./btn-claim.png")}
                  className="h-12 object-contain  opacity-50 w-full"
                />
                <p className="text-center text-xs mt-1">
                  {claimed?.claimed[milestoneIndex] || 0}/
                  {property.claimables[milestoneIndex]}
                </p>
              </div>
            );
          } else {
            return (
              <img
                src={require("./btn-claimed.png")}
                className="h-12 object-contain  w-full"
              />
            );
          }
      }
    }
  }
  // function onEventNameClick(event: EventEntity) {
  //   if (event.claim_type === EventClaimType.Rank) {
  //     setShowRank(true);
  //     setRankEvent(event);
  //   }
  // }
  function notifyText() {
    if (!user?.id) {
      return (
        <p className="text-red text-center">
          You're not signing in, please sign-in first!
        </p>
      );
    }
    if (
      (groupInfo?.eventInfos[0]?.claim_type === EventClaimType.Rank ||
        groupInfo?.eventInfos[0]?.claim_type === EventClaimType.Final) &&
      new Date(groupInfo?.eventInfos[0].end_time) > new Date()
    ) {
      return (
        <p className="text-yellow text-center">
          Rewards can be claimed when the event ends
        </p>
      );
    }
  }
  function getColor(percent: number): string {
    let start = [220, 38, 38];
    let end = [22, 163, 74];
    let c = [
      start[0] + Math.round((end[0] - start[0]) * percent),
      start[1] + Math.round((end[1] - start[1]) * percent),
      start[2] + Math.round((end[2] - start[2]) * percent),
    ];
    if (percent >= 1) {
      c = end;
    }
    return `rgb(${c[0]}, ${c[1]}, ${c[2]})`;
  }
  function getProgress(event: any): number {
    if (!event) {
      return 0;
    }
    if (!propertyInfos) {
      return 0;
    }
    let p = propertyInfos.find((p: any) => p.event_id === event.id);
    if (!p) {
      return 0;
    }
    return Number(p.value);
  }
  function drawProgress(event: any, milestone: EventMilestone) {
    let val = getProgress(event);
    let total = milestone.require;
    let percent = val / total;
    if (event.claim_type === EventClaimType.DuringRepeat) {
      return "";
    }
    if (event.claim_type === EventClaimType.Rank) {
      return "";
    }
    return (
      <div className="w-full mt-2 px-1">
        <div className="w-full h-[4px] bg-[#6a6a6a] rounded-md overflow-hidden">
          <div
            className="h-[4px]"
            style={{
              width: `${percent * 100}%`,
              backgroundColor: getColor(percent),
            }}
          ></div>
        </div>
        <p className="text-left text-gray-100 text-xs mt-1">
          Progress: {Math.min(val, total)}/{total}
        </p>
      </div>
    );
  }
  return (
    <div className="p-10">
      <Modal
        basic
        open={showRank}
        onClose={() => {
          setShowRank(false);
        }}
      >
        <div className="bg-transparent">
          <div className="bg-[#0F1011] relative rounded-lg ">
            <img
              onClick={() => {
                setShowRank(false);
              }}
              alt="close"
              src={require("../btn-close-modal.png")}
              className="absolute -top-3 -right-3 cursor-pointer"
            />
            <p className="text-center text-2xl text-white font-cookie font-semibold p-6">
              {rankEvent?.name}
            </p>
            <img
              src={require("../modal-separate.png")}
              className="mx-auto"
              alt="separate"
            />
            <div>
              <EventRank event={rankEvent} />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        size="mini"
        open={received !== null}
        onClose={() => {
          setReceived(null);
        }}
      >
        <div className="bg-transparent">
          <div className="bg-[#0F1011] relative rounded-lg text-white p-4">
            <p className="text-center text-3xl">Congratulation!</p>
            <img src={require("../modal-separate.png")} className="mt-4" />
            <p className="text-center mb-8 mt-4 text-base">
              You have received:
            </p>
            {received !== null && <ListReward rewards={received} />}
            <img
              onClick={() => {
                setReceived(null);
              }}
              src={require("../btn-close.png")}
              className="w-28 mx-auto mt-5 cursor-pointer"
            />
          </div>
        </div>
      </Modal>
      {notifyText()}
      {groupInfo?.eventInfos
        ?.filter((e: EventEntity) => {
          if (!eventId) {
            return true;
          }
          return e.id === eventId;
        })
        .map((e: EventEntity) => {
          return (
            <div className="mt-4">
              {e?.claim_type === EventClaimType.Rank && !eventId && (
                <img
                  onClick={() => {
                    setShowRank(true);
                    if (setRankEvent) {
                      setRankEvent(e);
                    }
                  }}
                  alt="leaderboard"
                  src={require("../btn-leaderboard.png")}
                  className="text-center mb-4 cursor-pointer h-10"
                />
              )}
              {e?.milestones?.map((m: EventMilestone, mIndex: number) => (
                <div>
                  <div className="flex flex-col lg:flex-row gap-4 items-center w-full justify-between mt-2 mb-2">
                    <div className="w-64 flex-grow-0 flex-shrink-0">
                      <p className="text-xl text-white font-cookie hover:text-[#E89F01] cursor-pointer">
                        {m?.name}
                      </p>
                    </div>
                    <div className="w-full ">
                      <ListReward
                        rewards={m.rewards}
                        showPercent={m.open_type === RewardOpenType.Random}
                      />
                    </div>
                    <div className="w-36 flex-grow-0 flex-shrink-0">
                      {renderClaimButton(e, mIndex)}
                      {drawProgress(e, m)}
                    </div>
                  </div>
                  <div className="w-full bg-[#323232] h-[1px] mx-auto my-4"></div>
                </div>
              ))}
            </div>
          );
        })}
    </div>
  );
}
