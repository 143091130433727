import { MongenRace } from "interfaces";
import { useEffect, useState } from "react";
import StaticAnim from "services/staticanim";
import { EventAction, RacingConfig, sendGameMessage } from "./game/config";
import Mongen from "./game/mongen";
import { Progress } from "./home";
const COLORS = ["#dc2626", "#f59e0b", "#84cc16", "#0ea5e9", "#a855f7"];
let allData: any = null;
export default function GameUI({ onPageChange }: { onPageChange: Function }) {
  const [data, setData] = useState<any>(null);
  const [follow, setFollow] = useState<number>(0);
  const [pos, setPos] = useState<number[]>([]);
  const [rank, setRank] = useState<number[]>([]);
  const [page, setPage] = useState<"run" | "finish">("run");
  useEffect(() => {
    sendGameMessage(EventAction.SetFollow, { index: follow });
  }, [follow]);
  useEffect(() => {
    document.addEventListener("racing_game_event", processGameEvent);
    function processGameEvent(payload: any) {
      let { detail } = payload;
      switch (detail.action) {
        case EventAction.StartGame:
          setData(detail.data);
          allData = detail.data;
          break;
        case EventAction.UpdateMongenPosition:
          setPos(detail.data.position);
          let a: any[] = [];
          detail.data.position.forEach((p: any, index: number) => {
            if (p >= RacingConfig.MAX_POS * RacingConfig.MapScale) {
              a.push({
                index,
                p:
                  RacingConfig.MAX_POS * RacingConfig.MapScale +
                  (5 - allData.rank.indexOf(index)) * 1000000,
              });
            } else {
              a.push({ index, p });
            }
          });

          a.sort((x: any, y: any) => y.p - x.p);

          let r: number[] = [];
          a.forEach((i: any, index: number) => {
            r[i.index] = index;
          });
          setRank(r);
          break;
        case EventAction.FinishGame:
          setPage("finish");
      }
    }
    return () => {
      document.removeEventListener("racing_game_event", processGameEvent);
    };
  }, []);
  function onFinishClick() {
    window.location.reload();
  }
  return (
    <div className="relative top-0 left-0 z-10  w-full h-full">
      {data && (
        <>
          {page === "run" && (
            <div className="absolute bottom-0 w-full left-0 p-2">
              <div className="w-full h-full p-2 flex justify-between">
                <div className="relative gap-4">
                  {data.mongen_infos.map((m: any, index: number) => (
                    <RenderMongen
                      rank={rank[index]}
                      index={index}
                      dna={m.dna}
                      active={follow === index}
                      onClick={() => {
                        setFollow(index);
                      }}
                    />
                  ))}
                </div>
                <div className="flex gap-2 flex-col mt-10">
                  <div className="rounded-md w-[300px] relative h-[19px] p-[2px] bg-black bg-opacity-20">
                    {data.mongen_infos.map((m: any, index: number) => (
                      <div
                        className="duration-100 mt-[2px] absolute rounded-full top-0 w-[15px] h-[15px]"
                        style={{
                          backgroundColor: COLORS[index],
                          left: `${
                            (pos[index] /
                              RacingConfig.MAX_POS /
                              RacingConfig.MapScale) *
                            100
                          }%`,
                        }}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {page === "finish" && (
            <div
              className="w-full h-full pt-[80px]"
              style={{ backgroundImage: "url(assets/game/finish.jpg)" }}
            >
              <img className="block mx-auto" src="assets/game/logo.png" />
              <button
                className="mx-auto block mt-20 relative"
                onClick={() => {
                  onFinishClick();
                }}
              >
                <img src="assets/game/ui/button.png" />
                <p className="absolute top-0 left-0 w-full pt-2 text-3xl text-white font-cookie">
                  FINISH
                </p>
              </button>
              <div className="flex justify-center pt-[10px] mx-auto w-[1000px]">
                <div className="w-[350px] mt-[40px]">
                  <StaticAnim
                    dna={data.mongen_infos[data.rank[1]].dna}
                    lock={[]}
                  />
                </div>
                <div className="w-[350px]">
                  <StaticAnim
                    dna={data.mongen_infos[data.rank[0]].dna}
                    lock={[]}
                  />
                </div>
                <div className="w-[350px] mt-[60px]">
                  <StaticAnim
                    dna={data.mongen_infos[data.rank[2]].dna}
                    lock={[]}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
function RenderMongen({
  dna,
  onClick,
  active,
  index,
  rank,
}: {
  rank: number;
  index: number;
  active: boolean;
  dna: number[][];
  onClick: Function;
}) {
  return (
    <div
      style={{ borderColor: COLORS[index], left: `${rank * 120}px` }}
      className={`transform bottom-2 duration-300 absolute w-[100px] h-[100px] border-t-8 rounded-md hover:brightness-125 bg-black ${
        active ? "scale-110 bg-opacity-40" : " bg-opacity-10"
      } pt-4`}
      onClick={() => {
        onClick();
      }}
    >
      <p className="absolute top-0 left-0 text-xl text-white font-cookie w-full bg-black bg-opacity-50 text-center">
        {rank + 1}
      </p>
      <StaticAnim dna={dna} lock={[]} />
    </div>
  );
}
