export default function CustomerName({ name }: { name: string }) {
  function renderPrefix() {
    let arr = name.split("</color>");
    if (arr.length < 2) {
      return <></>;
    }
    let prefix = arr[0] + "</color>";
    if (!prefix) return null;
    let clanName = prefix.substring(
      prefix.indexOf("["),
      prefix.indexOf("]") + 1
    );
    let color = prefix.substring(prefix.indexOf("#"), prefix.indexOf("#") + 7);
    return <span style={{ color }}>{clanName}</span>;
  }
  return (
    <span className="">
      {renderPrefix()}
      <span>{name.split("</color>").pop()}</span>
    </span>
  );
}
