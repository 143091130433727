import { AuctionEntity, Rarity, BodyPart } from "interfaces";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { utils } from "services";
import Dayjs from "dayjs";
import relativeTIme from "dayjs/plugin/relativeTime";
import { socketAuction } from "services";
import { Button } from "antd";
import AuctionImage from "../auction-image";
import AuctionCreate from "../auction-create";
Dayjs.extend(relativeTIme);

export default function AuctionItem({
  setActiveTab,
  auction,
  tabInfo,
  activeTab,
  itemUpdate,
  trigger,
  setTrigger
}: {
  setActiveTab?: Function,
  auction: AuctionEntity,
  tabInfo?: any,
  activeTab?: string,
  itemUpdate?: any,
  trigger?: boolean,
  setTrigger?: Function
}) {
  const nav = useNavigate();
  const location = useLocation()
  const [data, setData] = useState<any>();
  const [text, setText] = useState<any>(utils.getCountdownText(auction.end_time));
  const [updatePrice, setUpdatePrice] = useState();
  const [showCreateAuction, setShowCreateAuction] = useState(false)

  useEffect(() => {
    let info = auction.auction_item ? auction.auction_item : auction
    let price = auction.price ? auction.price : (info.current_price > 0 ? info.current_price : info.init_price)
    let dna = info?.item_data?.dna ? info.item_data.dna : info.dna
    let newData: any = {
      ...info,
      _id: info._id,
      image: "",
      auction_name: info.auction_name || info.name || info.item_data.name,
      start_time: info.start_time,
      end_time: info.end_time,
      current_price: auction.back_amount >= 0 ? Number(Math.round((auction.back_amount - price) * 100) / 100) : price,
      title_current_price: tabInfo?.title_current_price,
      next_price: auction.back_amount >= 0 ? auction.back_amount : Number(Math.round(price * 110) / 100),
      title_next_price: tabInfo?.title_next_price,
      total_bid: info.total_bid,
      token_id: info.token_id,
      short_description: activeTab == "Open Auctions" && info.short_description,
      long_description: info.long_description,
      time: activeTab == "My Winning Bids" ? info.end_time : Number(auction.update_time) > 0 ? auction.update_time : auction.time,
      isStart: info.start_time <= Date.now(),
      sent: info.sent,
      ...info.item_data,
      dna: Array.isArray(dna) ? JSON.stringify(dna) : dna,
    }
    setData(newData)
  }, [auction])

  useEffect(() => {
    let timer: any = null;
    if (data) {
      let res = utils.getCountdownText(data.isStart ? data.end_time : data.start_time);
      setText(res);
    }
    timer = setInterval(() => {
      let res = utils.getCountdownText(data.isStart ? data.end_time : data.start_time);
      setText(res);
    }, 1000);
    return () => {
      clearInterval(timer);
    }
  }, [data]);

  useEffect(() => {
    if (itemUpdate?.id == data?._id) {

      setUpdatePrice(itemUpdate.price)
    }
  }, [itemUpdate?.price])

  const onClickItem = () => {
    if (activeTab != "My Inventory") {
      socketAuction.disconnectSocket()
      nav(`${location.pathname}/${data?._id || data?.id}`)
    }
  }

  const createAnAuction = async () => {
    setShowCreateAuction(true)
  }

  const renderTime = () => {
    return `${Dayjs(data.time).fromNow(true)} ago`
  }

  return (
    <div
      className="w-[338px] bg-[#1C2023] h-[200px] rounded-lg text-white font-normal relative sm:mr-[30px] cursor-pointer auction-item mb-[30px]"
    >
      <div onClick={onClickItem} className="m-[10px] w-[318px] h-[318px] mb-3 rounded-lg  bg-[#141416]">
        <div className={`w-full h-[30px] text-[20px] font-normal p-[10px] pb-0 text-right ${data?.isStart && "text-[#E89F01]"}`}>
          {text && `${data?.isStart ? "Ends in " : "Starts in "} ${(text["days"] > 0 ? text["days"] + "d " : "") + text["hours"] + ":" + text["minutes"] + ":" + text["seconds"]}`}
        </div>

        <div className={`${data?.decoration_list ? "w-[70%] m-auto pt-[85px]" : "w-full"} h-[280px] flex items-center justify-center `}>
          <div className="h-[260px] w-full flex items-center justify-center">
            {data && <AuctionImage itemData={data} width={400} height={200} />}
          </div>
        </div>
      </div>
      <div onClick={onClickItem} className="mx-[10px] text-left font-semibold text-xl6 leading-4 mb-1.5 flex justify-between items-center py-[10px] h-[48px]">
        {data?.auction_name}
        {(data?.dna_raw || data?.dna) ? <img src={`/assets/images/rarity/${Rarity[JSON.parse(data?.dna_raw || data?.dna)[BodyPart.Aura][0]]?.toLowerCase()}-icon.svg`} alt="rarity" className="w-[28px] h-[28px]" />
          : ((data?.rarity_index || data?.rarity) && <img className="w-[28px] h-[28px]" src={require(`../auction-detail/rarity-icon/${Rarity[data?.rarity_index >= 0 ? data?.rarity_index : data?.rarity]?.toLowerCase()}.png`)} />)
        }
      </div>
      {data?.title_current_price && <div onClick={onClickItem} className="flex justify-between items-start border-t border-[#494949]">
        <div className="w-full flex flex-col text-center items-center">
          <div className="pl-[10px] text-[14px] opacity-70 leading-4 mt-[20px] mb-[6px]">{data?.title_current_price}</div>
          <div className="pl-[10px]  items-center text-[32px] font-semibold flex"><img className=" w-[44px] h-[40px] pr-[6px]" src={require("./mstr-2.png")} />{updatePrice ? updatePrice : data?.current_price} </div>
        </div>
      </div>}
      {activeTab == "My Inventory" &&
        <Button
          onClick={createAnAuction}
          className="text-[12px] text-white w-[123px] h-[37px] bg-[#F9C744] btn-yellow flex items-center justify-center font-normal mx-auto mb-[13px] mt-[26px]"
        >
          Create an auction
        </Button>}
      {(data?.short_description || data?.time || data?.total_bid >= 0) && <div className="w-full h-[42px] text-base py-3 px-[10px] bg-[#24211F] mt-7 rounded-b-lg text-white flex justify-between items-center">
        <div className=" text-sm opacity-70 leading-4">{data?.time ? renderTime() : `${updatePrice ? data?.total_bid + 1 : data?.total_bid} Bids`}</div>
        <div>{data?.short_description}</div>
      </div>}
      <AuctionCreate
        setActiveTab={setActiveTab}
        showCreateAuction={showCreateAuction}
        setShowCreateAuction={setShowCreateAuction}
        data={data}
        trigger={trigger}
        setTrigger={setTrigger}
      />
    </div>
  );
}
