import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducer/store";
import ScrollToTop from "./scrollToTop";
import ui from "../../src/services/ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HomePage from "home";
import ChampionShip from "championship";
import Referral from "referral";
import { MENU_AUCTION_B2C, MENU_AUCTION_P2P } from "config";
import { DetailMongenPage } from "event/detail-mongen";
import { ChaosMongenList } from "event/chaos/mongen-list";
import AuctionPage from "auction";
import AuctionDetailPage from "auction/auction-detail";

import Chaos from "event/chaos";
import Racing from "page/racing/index";
import { api } from "services";
import { setLoadingSpine } from "reducer/ssometa.slice";
import { initPlayer } from "services/staticanim/player";
import LuckyWheel from "page/wheel";
import RafflePage from "raffle";

function RequireAuth({ children }: any) {
  const userInfo = useSelector((state: RootState) => state.user);
  const { t } = useTranslation();
  const [isLogin, setIsLogin] = useState(null);
  useEffect(() => {
    if (!userInfo?.id) {
      setIsLogin(false);
      ui.alertFailed(t("must_login_first"), "");
    }
  }, []);

  if (userInfo?.id) {
    return children;
  } else if (isLogin === false) {
    return <Navigate to="/" />;
  } else {
    return <></>;
  }
}

export default function Main() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.user);

  useEffect(() => {
    initPlayer();
    document.addEventListener("tab_active_event", refreshCustomer);
    document.addEventListener("spine_loading_completed", setSpineLoading);
    function setSpineLoading() {
      console.log("load complete");
      dispatch(setLoadingSpine({ loading: false }));
    }

    function refreshCustomer() {
      if (userInfo?.id) {
        api.refreshCustomerInfo();
      }
    }

    refreshCustomer();
    return () => {
      document.removeEventListener("tab_active_event", refreshCustomer);
      document.removeEventListener("spine_loading_completed", setSpineLoading);
    };
  }, []);
  return (
    <div>
      <div className={``}>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<HomePage type="home" />} />
            <Route path="/completed" element={<HomePage type="completed" />} />
            <Route path="/championship" element={<ChampionShip />} />
            <Route path="/referral" element={<Referral />} />
            <Route path="/raffle" element={
              <RequireAuth>
                <RafflePage />
              </RequireAuth>
            } />
            {/* <Route path="/celest" element={
              <RequireAuth>
                <Celest />
              </RequireAuth>
            } />
            <Route path="/celest/queue" element={
              <RequireAuth>
                <CelestQueue />
              </RequireAuth>
            } />
            <Route path="/celest/list" element={
              <RequireAuth>
                <CelestMongenList detailUrl="/celest/list" />
              </RequireAuth>
            } />
            <Route path="/celest/list/:id" element={
              <RequireAuth>
                <DetailMongenPage backUrl="/celest/list" backUrl="/celest" />
              </RequireAuth>
            } /> */}

            <Route
              path="/chaos"
              element={
                <RequireAuth>
                  <Chaos />
                </RequireAuth>
              }
            />
            <Route
              path="/chaos/list"
              element={
                <RequireAuth>
                  <ChaosMongenList detailUrl="/chaos/list" backUrl="/chaos" />
                </RequireAuth>
              }
            />
            <Route
              path="/chaos/list/:id"
              element={
                <RequireAuth>
                  <DetailMongenPage backUrl="/chaos/list" />
                </RequireAuth>
              }
            />
            <Route
              path="/auction-b2c"
              element={<AuctionPage menuAuction={MENU_AUCTION_B2C} />}
            />
            <Route
              path="/auction-p2p"
              element={
                <AuctionPage menuAuction={MENU_AUCTION_P2P} isP2P={true} />
              }
            />
            <Route path="/auction-b2c/:id" element={<AuctionDetailPage backUrl={"/auction-b2c"} />} />
            <Route path="/auction-p2p/:id" element={<AuctionDetailPage backUrl={"/auction-p2p"} />} />
            <Route path="/racing" element={<Racing />} />
            <Route path="/wheel" element={<LuckyWheel />} />
          </Routes>
        </ScrollToTop>
      </div>
    </div>
  );
}
