import "phaser";
import "phaser/plugins/spine/dist/SpinePlugin";
import {
  EventAction,
  RacingConfig,
  RacingEffect,
  RacingMongenState,
  sendGameMessage,
  TrapType,
} from "./config";
import ImpactPlayer from "./impactplayer";
import Mongen from "./mongen";
import MongenInfo from "./mongen";
import { getAnimName, getSkinList } from "./spineutils";
import Thumbnail from "./thumbnail";
const game = require("./mock.json");
let gameInstance: Phaser.Game = null;
const Position = {
  StartY: 410,
  DistanceY: 75,
};
export enum GameState {
  Idle,
  Run,
  End,
}
class RacingGame extends Phaser.Scene {
  constructor() {
    super("demo");
    this.state = GameState.Idle;
  }
  currentTime: number;
  far: Phaser.GameObjects.TileSprite;
  near: Phaser.GameObjects.TileSprite;
  land: Phaser.GameObjects.TileSprite;
  sky: Phaser.GameObjects.TileSprite;
  mongens: any[];
  lastTime: number;
  currentFrame: number;
  state: GameState;
  traps: any;
  trapInfos: any;
  thumbnails: Thumbnail[];
  gameData: any;
  follow: number;
  updateInterval: any = null;
  rank: number[] = [];
  preload() {
    this.gameData = { game };
    // this.load.pack("spine");
    this.load.image("bg_sky", "assets/game/background/sky.png");
    this.load.image("bg_near", "assets/game/background/near.png");
    this.load.image("bg_far", "assets/game/background/far.png");
    this.load.image("bg_land", "assets/game/background/land.png");
    this.load.image("btn", "assets/game/btn.png");
    this.load.image("trap_electric", "assets/game/trap/electric.png");
    this.load.image("trap_ice", "assets/game/trap/ice.png");
    this.load.image("trap_confuse", "assets/game/trap/confuse.png");
    this.load.image("shadow", "assets/game/mongen/shadow.png");
    this.load.image("line", "assets/game/line.png");
    this.follow = -1;
    // @ts-ignore
    this.load.spine(
      "mongenAnimation",
      "assets/game/mongen/Pets.json",
      "assets/game/mongen/Pets.atlas.txt",
      false
    );
    // @ts-ignore
    this.load.spine(
      "impactAnimation",
      "assets/game/impact/Impact.json",
      "assets/game/impact/Impact.atlas.txt",
      false
    );
  }
  processEvent(data: any) {
    let { detail } = data;
    switch (detail.action) {
      case EventAction.StartGame:
        this.gameData = detail.data;
        this.rank = detail.data.rank;
        this.startGame();
        break;
      case EventAction.SetFollow:
        this.follow = detail.data.index;
        break;
    }
  }
  create() {
    document.addEventListener(
      "racing_game_event",
      this.processEvent.bind(this)
    );
    // @ts-ignore
    const start = 550,
      distance = 80;
    this.renderBackground();
    this.add.image(100, 620, "line");
    this.add.image(
      RacingConfig.MAX_POS * RacingConfig.MapScale + 50,
      620,
      "line"
    );
  }
  destroyTrap(id: number, x: number, y: number) {
    if (this.traps[id]) {
      this.traps[id].destroy();
    }
    new ImpactPlayer(this, this.trapInfos[id].type, x, y);
  }
  finishGame(): void {
    this.state = GameState.End;
    setTimeout(() => {
      sendGameMessage(EventAction.FinishGame, {});
    }, 5000);
  }

  startGame(): void {
    this.currentTime = 0;
    this.follow = 0;
    if (!this.mongens) {
      this.mongens = this.gameData.mongen_infos.map(
        (m: any, index: number) =>
          new Mongen(
            this,
            [0, Position.StartY + Position.DistanceY * index],
            m.dna
          )
      );
    }
    const start = 100;
    this.mongens.forEach((m) => {
      m.setMongenState(RacingMongenState.Idle);
    });
    this.updateInterval = setInterval(() => {
      sendGameMessage(EventAction.UpdateMongenPosition, {
        position: this.mongens.map((m: any) => m.container.x),
      });
    }, 500);
    this.lastTime = 0;
    this.currentTime = 0;
    this.currentFrame = -1;

    this.state = GameState.Run;
    this.traps = {};
    this.trapInfos = {};

    this.gameData.game.trapInfos.map((listTraps: any, index: number) => {
      listTraps.forEach((t: any) => {
        this.trapInfos[t.id] = t;
        let x = t.pos * RacingConfig.MapScale;
        let y = Position.StartY + Position.DistanceY * index;
        switch (t.type) {
          case TrapType.Ice:
            this.traps[t.id] = this.add
              .image(x, y, "trap_ice")
              .setScale(0.5, 0.5)
              .setDepth(1);
            break;
          case TrapType.Electric:
            this.traps[t.id] = this.add
              .image(x, y, "trap_electric")
              .setScale(0.5, 0.5)
              .setDepth(1);
            break;
          case TrapType.Confused:
            this.traps[t.id] = this.add
              .image(x, y, "trap_confuse")
              .setScale(0.5, 0.5)
              .setDepth(1);
            break;
        }
      });
    });
  }
  nextFrame(): void {
    this.currentFrame++;

    let frame = this.gameData.game.frames[this.currentFrame];
    if (this.currentFrame === 1) {
      this.mongens.forEach((m: any, index: number) => {
        m.setMongenState(RacingMongenState.Run);
      });
      //test
      // this.currentFrame = this.gameData.game.frames.length - 50;
    }
    if (!frame) {
      this.finishGame();
    } else {
      this.mongens.forEach((m: any, index: number) => {
        let nextPos = Math.min(
          RacingConfig.MAX_POS * RacingConfig.MapScale,
          frame[index].pos * RacingConfig.MapScale
        );

        if (m.nextPos >= RacingConfig.MAX_POS * RacingConfig.MapScale) {
          //finish
          if (this.rank.indexOf(index) === 0) {
            m.setMongenState(RacingMongenState.Victory);
          } else {
            m.setMongenState(RacingMongenState.Lose);
          }
        }
        m.setNextPos(nextPos, frame[index].dir);
        if (frame[index].recv_trap && frame[index].recv_trap.length) {
          frame[index].recv_trap.forEach((t: any) => {
            this.destroyTrap(t.id, m.container.x, m.container.y);
            if (t.is_missed) {
              m.addEffect(RacingEffect.Missed);
            } else {
              let trapInfo = this.trapInfos[t.id];
              switch (trapInfo.type) {
                case TrapType.Ice:
                  m.addEffect(RacingEffect.Slow);
                  break;
                case TrapType.Electric:
                  m.addEffect(RacingEffect.Stun);
                  break;
                case TrapType.Confused:
                  m.addEffect(RacingEffect.Confused);
                  break;
              }
            }
          });
        }
        m.setStatus(RacingEffect.Boost, frame[index].boost_time);
      });
    }
  }
  update(...args: any): void {
    const delta = args[1];
    this.near.tilePositionX = this.cameras.main.scrollX;
    this.far.tilePositionX = this.cameras.main.scrollX * 0.5;
    this.land.tilePositionX = this.cameras.main.scrollX;
    if (this.follow > -1) {
      let scrollCam =
        this.mongens[this.follow].container.x - RacingConfig.Width / 2;
      scrollCam = Math.max(scrollCam, -500);
      this.cameras.main.scrollX = scrollCam;
    }
    if (this.mongens) {
      this.mongens.forEach((m) => m.update(args));
    }

    switch (this.state) {
      case GameState.Idle:
        break;
      case GameState.Run:
        this.currentTime += delta;
        if (
          Math.floor(this.currentTime / RacingConfig.FrameDuration) -
            Math.floor(this.lastTime / RacingConfig.FrameDuration) >=
          1
        ) {
          this.nextFrame();
        }
        this.lastTime = this.currentTime;
        break;
      case GameState.End:
        break;
    }
  }
  renderBackground(): void {
    // this.add.image(0, 0, "bg_sky");
    // this.add.image(0, 0, "bg_tree");
    this.sky = this.add.tileSprite(
      0,
      0,
      RacingConfig.TileWidth,
      RacingConfig.Height,
      "bg_sky"
    );
    this.sky.setScrollFactor(0);
    this.sky.setOrigin(0, 0);
    this.far = this.add.tileSprite(
      0,
      0,
      RacingConfig.TileWidth,
      RacingConfig.Height,
      "bg_far"
    );
    this.far.setScrollFactor(0);
    this.far.setOrigin(0, 0);
    this.land = this.add.tileSprite(
      0,
      0,
      RacingConfig.TileWidth,
      RacingConfig.Height,
      "bg_land"
    );
    this.land.setScrollFactor(0);
    this.land.setOrigin(0, 0);
    this.near = this.add.tileSprite(
      0,
      0,
      RacingConfig.TileWidth,
      RacingConfig.Height,
      "bg_near"
    );
    this.near.setScrollFactor(0);
    this.near.setOrigin(0, 0);
  }
  addMongen(x: number, y: number) {
    const dna = [
      [0, 0, 2],
      [2, 0, 1],
      [1, 0, 3],
      [2, 0, 3],
      [1, 0, 2],
      [0, 0, 2],
      [0, 0, 2],
      [0, 0, 2],
      [2, 0, 4],
      [0],
    ];
    // @ts-ignore
    let mongen = this.make.spine({
      x,
      y,
      key: "mongenAnimation",
      loop: true,
    });
    mongen.setScale(-0.3, 0.3);
    let skins = getSkinList(dna);
    // @ts-ignore
    let mainSkin = mongen.skeleton.data.findSkin(skins[0]);
    for (var i = 1; i < skins.length; i++) {
      mainSkin.addSkin(mongen.skeleton.data.findSkin(skins[i]));
    }
    mongen.skeleton.setSkin(mainSkin);
    mongen.play(getAnimName("Idle", dna), true);
    return mongen;
  }
}

export function startGame(container: any) {
  const config: Phaser.Types.Core.GameConfig = {
    type: Phaser.AUTO,
    backgroundColor: "#000000",
    parent: container.current,
    width: RacingConfig.Width,
    height: RacingConfig.Height,
    scene: RacingGame,
    plugins: {
      scene: [
        {
          // @ts-ignore
          key: window.SpinePlugin.name,
          // @ts-ignore
          plugin: window.SpinePlugin,
          mapping: "spine",
        },
      ],
    },
  };
  gameInstance = new Phaser.Game(config);
  return gameInstance;
}
export function destroyGame() {
  gameInstance.destroy(true);
}
