import { useEffect, useRef, useState } from "react";
import { destroyGame, startGame } from "./game";
import { FitToViewport } from "react-fit-to-viewport";
import { RacingConfig } from "./game/config";
import Term from "./term";
import Home from "./home";
import { api } from "services";
import GameUI from "./gameui";
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import Waiting from "./waiting";
import TermPage from "components/term";
export default function Racing() {
  const containerRef = useRef();
  const { loadingSpine } = useSelector((state: RootState) => state.ssometa);

  const [page, setPage] = useState<"home" | "game">("home");
  useEffect(() => {
    if (!containerRef) {
      return;
    }
    startGame(containerRef);
    return () => {
      destroyGame();
    };
  }, []);
  return (
    <div className="w-full flex justify-center">
      <FitToViewport
        width={RacingConfig.Width}
        height={RacingConfig.Height}
        minZoom={0.1}
        maxZoom={1}
        className="overflow-hidden"
      >
        <TermPage />
        {page !== "game" && !loadingSpine && (
          <div
            className={`w-full h-full  relative m-0`}
            style={{
              backgroundImage: `${"url(assets/game/racing-bg.jpg)"}`,
            }}
          >
            <img
              src="assets/game/logo.png"
              className="mx-auto block pt-[80px] scale-[90%]"
            />
            <div className="mx-auto mt-[20px] w-[850px]">
              {page === "home" && <Home page={page} onPageChange={setPage} />}
            </div>
          </div>
        )}
        <div
          className={`w-full h-full relative ${page !== "game" && "hidden"}`}
        >
          <GameUI onPageChange={setPage} />
          <div
            id="racing-player"
            className={` ${
              page !== "game" && "hidden"
            } absolute top-0 left-0  z-[1]`}
            ref={containerRef}
          ></div>
        </div>
        {loadingSpine && <Waiting />}
      </FitToViewport>
    </div>
  );
}
