export const RacingConfig = {
  FrameDuration: 1000, //milisecond
  MapScale: 10,
  TileWidth: 1925,
  Width: 1422,
  Height: 800,
  MAX_POS: 4000,
};
export enum RacingEffect {
  Missed,
  Confused,
  Stun,
  Slow,
  Boost,
}
export enum TrapType {
  Ice,
  Electric,
  Confused,
}
export enum RacingAnimation {
  Idle = "Idle",
  Run = "Run",
  Die = "Die",
}
export enum RacingMongenState {
  Idle,
  Run,
  Lose,
  Victory,
}
export enum EventAction {
  StartGame,
  SetFollow,
  UpdateMongenPosition,
  FinishGame,
}
export enum BetStatus {
  Pending,
  Win,
  Lose,
}
export function sendGameMessage(action: EventAction, data: any) {
  let ev = new CustomEvent("racing_game_event", {
    detail: { action, data },
  });
  document.dispatchEvent(ev);
}
