import { Progress } from "./home";

export default function Waiting() {
  return (
    <div className="w-full bg-black h-full absolute top-0 left-0">
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-[600px]">
          <img src="assets/game/logo.png" />
          <p className="text-white text-2xl text-center font-cookie">
            Loading...
          </p>
        </div>
      </div>
    </div>
  );
}
