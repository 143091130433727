import CustomerName from "components/customer-name";
import TermPage from "components/term";
import dayjs from "dayjs";
import {
  BarrackType,
  BombardType,
  CampType,
  MongenRace,
  PlotType,
  RewardEntity,
  RewardType,
  TicketRarity,
  TowerType,
  TrapType,
} from "interfaces";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { FitToViewport } from "react-fit-to-viewport";
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { api, ui, utils } from "services";
import { getBoxIcon } from "services/box";
import { UseInterval } from "services/use-interval";
import { WheelConfig, WheelOption } from "./wheel-config";
interface WheelInterface {
  end_time: Date;
  id: number;
  is_active: boolean;
  name: string;
  racing_burn: number;
  racing_duration: number;
  racing_revenue: number;
  spin_cost: number;
  start_time: Date;
  type: number;
  updated_at: Date;
  wheel_back_jackpot: number;
  wheel_jackpot_to_jackpot: number;
  wheel_jackpot_to_revenue: number;
  wheel_pool: RewardEntity[];
  wheel_revenue: number;
  currentJackpot: number;
}
const ACCELERATE = 30;
const SLOW_DOWN = 40;
let timeCounter = 0;
let waitTime = 0;
let timer = 0;
const PAGE_SIZE = 4;
interface HistoryItem {
  amount: number;
  created_at: Date;
  customer_id: number;
  earned_mag: number;
  event_gacha_id: number;
  id: number;
  recv_reward: RewardEntity;
  reward_type: RewardType;
  updated_at: Date;
  index: number;
}
interface NotiItem {
  cost: number;
  name: string;
  win_reward: RewardEntity;
}
let waitTimeout: any = null;
export default function LuckyWheel() {
  const [current, setCurrent] = useState<number>(0);
  const [wheel, setWheel] = useState<number>(0);
  const [allWheel, setAllWheel] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [auto, setAuto] = useState<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(false);
  const [totalStep, setTotalStep] = useState<number>(200);
  const [items, setItems] = useState<any[]>([]);
  const [newTransaction, setNewTransaction] = useState<HistoryItem>(null);
  const [noti, setNoti] = useState<NotiItem[]>([]);
  const [currentJackpot, setCurrentJackpot] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [showRule, setShowRule] = useState<boolean>(false);
  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    async function loadHistory() {
      let rs = await api.postEventDeal("/wheel/get-history-bet", {
        limit: PAGE_SIZE,
        offset: (page - 1) * PAGE_SIZE,
      });
      setItems(rs.data);
      setTotal(rs.total);
    }
    if (page >= 1) {
      loadHistory();
    }
  }, [page]);
  useEffect(() => {
    if (allWheel) {
      refreshNoti();
    }
  }, [allWheel]);
  async function refreshNoti() {
    if (!allWheel[wheel]) {
      return;
    }
    let rs = await api.postEventDeal(`/wheel/get-notifications`, {
      event_id: allWheel[wheel].id,
    });
    setNoti(rs);
  }
  UseInterval(() => {
    if (!playing) {
      refreshNoti();
    }
  }, 5000);
  UseInterval(() => {
    if (!playing) {
      return;
    }
    timeCounter++;
    if (timeCounter >= waitTime) {
      // @ts-ignore
      window.slotSound.cloneNode(true).play();
      timeCounter = 0;
      timer++;
      waitTime = fn(timer);
      setCurrent((current + 1) % 14);
      if (timer === totalStep) {
        setPlaying(false);
        refreshNoti();
        let tmp = _.cloneDeep(items);
        tmp.unshift(newTransaction);
        tmp.length = Math.min(4, tmp.length);
        setItems(tmp);
      }
    }
  }, 5);
  useEffect(() => {
    if (waitTimeout) {
      clearTimeout(waitTimeout);
      waitTimeout = null;
    }
    if (!playing && auto) {
      waitTimeout = setTimeout(() => {
        spin();
      }, 2000);
    }
  }, [auto, playing]);
  function fn(x: number) {
    // if (x < ACCELERATE) {
    //   return Math.round(-x + 40);
    // }
    if (x > totalStep - SLOW_DOWN) {
      return Math.round(x - totalStep + 50);
    }
    return 10;
  }
  async function spin() {
    if (playing || spinLoading) return;
    setSpinLoading(true);
    try {
      let rs = await api.postEventDeal("/wheel/spin-wheel", {
        wheel_id: allWheel[wheel].id,
      });
      api.refreshCustomerInfo();
      let { transactionInfo } = rs;
      setNewTransaction(transactionInfo);
      setTotalStep(70 + (14 - current + transactionInfo.index));
      waitTime = 10;
      timer = 0;
      timeCounter = 0;
      setPlaying(true);
      setCurrentJackpot(rs.currentJackpot);
    } catch (error: any) {
      setPlaying(false);
      setAuto(false);
      ui.showPopup("", error.message);
    } finally {
      setSpinLoading(false);
    }
  }
  useEffect(() => {
    async function loadWheel() {
      setLoading(true);
      let rs = await api.postEventDeal("/wheel/get-all-active-wheels", {});
      let { gachaEvents, currentJackpot } = rs;
      setAllWheel(gachaEvents);
      setCurrentJackpot(currentJackpot);
      setLoading(false);
    }
    loadWheel();
  }, []);
  function toggleAuto() {
    if (!auto && !playing) {
      spin();
    }
    setAuto(!auto);
  }

  function changeWheel(index: number) {
    if (playing) {
      return;
    }
    setWheel(index);
  }
  function renderRule() {
    return (
      <div className="absolute top-0 left-0 z-[10] bg-black bg-opacity-80 h-full w-full">
        <div className="w-full flex justify-center">
          <div className="w-[500px] bg-[#2D3151] text-white p-4 rounded-xl border-4 border-black mt-16">
            <div className="flex justify-between">
              <p className="text-4xl">Rule</p>
              <img
                src="assets/game/ui/close.png"
                className="h-12 cursor-pointer"
                onClick={() => {
                  setShowRule(false);
                }}
              />
            </div>
            <div className="p-4">
              <p className="mt-8">- Includes 3 types: Silver, Gold, Diamond</p>
              <p>
                - Players use MAG to spin and have a chance to hit the wheel's
                rewards
              </p>
              <p>
                - 5% of the spin cost will be added to MAG revenue to serve MAG
                Vault and MAG Staking Interest
              </p>
              <p>- 5% of the spin cost will be added to the jackpot.</p>
              <p>
                - Players who hit the Jackpot box will receive 80% of that
                Jackpot's reward. 10% Jackpot will be counted as MAG revenue to
                serve MAG Vault and MAG Staking Interest, and the remaining 10%
                will be added back to the next Jackpot pool.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex justify-center">
      <FitToViewport
        width={WheelConfig.Width}
        height={WheelConfig.Height}
        minZoom={0.1}
        maxZoom={1}
        className="overflow-hidden"
      >
        <TermPage />
        {showRule && renderRule()}
        <div
          className="w-full  py-4 px-16 h-full"
          style={{ backgroundImage: "url(assets/wheel/bg.jpg" }}
        >
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              {!allWheel[wheel] ? (
                <p className="text-white text-2xl mt-10 text-center w-full font-cookie">
                  There's no event. Please come back later.
                </p>
              ) : (
                <>
                  <div className="flex gap-16">
                    <div className="w-3/12">
                      <div className="flex gap-1">
                        {allWheel.map((w: WheelInterface, index: number) => (
                          <div
                            className="w-full cursor-pointer"
                            onClick={() => {
                              changeWheel(index);
                            }}
                          >
                            <WheelCell active={wheel === index} name={w.name} />
                          </div>
                        ))}
                      </div>
                      <div className="relative">
                        <img src="assets/wheel/jackpot.png" className="mt-2" />
                        <div className="absolute bottom-0 left-0 w-full p-2 bg-black bg-opacity-50">
                          <p className=" text-4xl text-white bottom-14 font-cookie-black font-bold w-full text-center flex gap-2 justify-center items-center text-outline">
                            {Math.round(currentJackpot).toLocaleString()}
                            MAG
                          </p>
                        </div>
                      </div>
                      <button
                        className="relative w-full mt-4"
                        onClick={() => {
                          setShowRule(!showRule);
                        }}
                      >
                        <img
                          src="assets/wheel/btn_yellow.png"
                          className="w-full"
                        />
                        <div className="items-center flex gap-2 justify-center absolute top-0 left-0 w-full h-full text-white text-4xl">
                          <p className="text-center font-cookie">Rule</p>
                        </div>
                      </button>
                    </div>
                    <div className="w-9/12">
                      <div className="flex">
                        <div className="w-1/5">
                          <RewardCell
                            index={0}
                            current={current}
                            wheel={allWheel[wheel]}
                          />
                        </div>
                        <div className="w-1/5">
                          <RewardCell
                            index={1}
                            current={current}
                            wheel={allWheel[wheel]}
                          />
                        </div>
                        <div className="w-1/5">
                          <RewardCell
                            index={2}
                            current={current}
                            wheel={allWheel[wheel]}
                          />
                        </div>
                        <div className="w-1/5">
                          <RewardCell
                            index={3}
                            current={current}
                            wheel={allWheel[wheel]}
                          />
                        </div>
                        <div className="w-1/5">
                          <RewardCell
                            index={4}
                            current={current}
                            wheel={allWheel[wheel]}
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <div className="w-1/5">
                          <div className="w-full">
                            <RewardCell
                              index={13}
                              current={current}
                              wheel={allWheel[wheel]}
                            />
                          </div>
                          <div className="w-full">
                            <RewardCell
                              index={12}
                              current={current}
                              wheel={allWheel[wheel]}
                            />
                          </div>
                        </div>
                        <div className="w-3/5">
                          <div className="flex mx-auto justify-center w-full h-full items-center">
                            <div className="flex flex-col">
                              {!user?.id && (
                                <p className="text-white text-2xl font-cookie">
                                  Please sign in to join game
                                </p>
                              )}
                              {user?.id && (
                                <>
                                  <button
                                    className={`relative w-[200px] ${
                                      (playing || auto || spinLoading) &&
                                      "grayscale"
                                    }`}
                                    onClick={() => {
                                      spin();
                                    }}
                                  >
                                    <img src="assets/wheel/btn_green.png" />
                                    <div className="items-center flex gap-2 justify-center absolute top-0 left-0 w-full h-full text-white text-4xl">
                                      <img
                                        src="assets/wheel/mag.png"
                                        className="w-12"
                                      />
                                      <p className="text-center font-cookie">
                                        {allWheel[wheel].spin_cost}
                                      </p>
                                    </div>
                                  </button>

                                  {!auto && (
                                    <button
                                      className="relative w-[200px] mt-4"
                                      onClick={() => {
                                        toggleAuto();
                                      }}
                                    >
                                      <img src="assets/wheel/btn_yellow.png" />
                                      <div className="items-center flex gap-2 justify-center absolute top-0 left-0 w-full h-full text-white text-4xl">
                                        <p className="text-center font-cookie">
                                          Auto
                                        </p>
                                      </div>
                                    </button>
                                  )}
                                  {auto && (
                                    <button
                                      className="relative w-[200px] mt-4"
                                      onClick={() => {
                                        toggleAuto();
                                      }}
                                    >
                                      <img src="assets/wheel/btn_red.png" />
                                      <div className="items-center flex gap-2 justify-center absolute top-0 left-0 w-full h-full text-white text-4xl">
                                        <p className="text-center font-cookie">
                                          Stop
                                        </p>
                                      </div>
                                    </button>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="w-1/5">
                          <div className="w-full">
                            <RewardCell
                              index={5}
                              current={current}
                              wheel={allWheel[wheel]}
                            />
                          </div>
                          <div className="w-full">
                            <RewardCell
                              index={6}
                              current={current}
                              wheel={allWheel[wheel]}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="w-1/5">
                          <RewardCell
                            index={11}
                            current={current}
                            wheel={allWheel[wheel]}
                          />
                        </div>
                        <div className="w-1/5">
                          <RewardCell
                            index={10}
                            current={current}
                            wheel={allWheel[wheel]}
                          />
                        </div>
                        <div className="w-1/5">
                          <RewardCell
                            index={9}
                            current={current}
                            wheel={allWheel[wheel]}
                          />
                        </div>
                        <div className="w-1/5">
                          <RewardCell
                            index={8}
                            current={current}
                            wheel={allWheel[wheel]}
                          />
                        </div>
                        <div className="w-1/5">
                          <RewardCell
                            index={7}
                            current={current}
                            wheel={allWheel[wheel]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="absolute bottom-6 w-full left-0 px-8">
                <History
                  items={items}
                  noti={noti}
                  page={page}
                  pageChange={setPage}
                  total={total}
                />
              </div>
            </>
          )}
        </div>
      </FitToViewport>
    </div>
  );
}
function WheelCell({ active, name }: { active: boolean; name: string }) {
  return (
    <button className="relative">
      {active && <img src="assets/wheel/wheel_active.png" />}
      {!active && <img src="assets/wheel/wheel_idle.png" />}
      <div className="absolute top-0 left-0 w-full h-full">
        <div className="flex h-full w-full items-center justify-center px-1">
          <p className=" text-white text-xl mt-3 font-bold">{name}</p>
        </div>
      </div>
    </button>
  );
}
const REWARD_NAME: any = {
  [RewardType.LOCK_MAG]: "sMAG",
  [RewardType.UNLOCK_MAG]: "MAG",
};
function RewardCell({
  index,
  current,
  wheel,
}: {
  index: number;
  current: number;
  wheel: WheelInterface;
}) {
  const active = current === index;

  return (
    <div
      className={`w-full h-[120px] border-4 rounded-3xl ${
        active ? "border-[#FFA919]" : "border-transparent"
      }`}
    >
      {wheel.wheel_pool[index].reward_type === RewardType.Jackpot ? (
        <div className="w-full overflow-hidden h-full">
          <img
            src="assets/wheel/jackpot_btn.png"
            className={`h-full w-full object-cover rounded-2xl ${
              active ? "grayscale-0" : "grayscale"
            }`}
          />
        </div>
      ) : (
        <div
          className={`border-[#0A0B1B] border-4  w-full font-cookie text-xl h-full flex justify-center items-center  rounded-3xl ${
            active
              ? "bg-[#FFDFA9] text-[#FFA919]"
              : "bg-[#2D3151] text-white text-center"
          }`}
        >
          <div className="relative">
            <img
              src={`img/reward/${getRewardIcon(wheel.wheel_pool[index])}`}
              className="w-12 mx-auto"
            />
            {wheel.wheel_pool[index].rarity !== null && (
              <img
                src={utils.getRarityIcon(wheel.wheel_pool[index].rarity)}
                alt="rarity"
                className="w-7 h-7 absolute -top-2 -left-2.5"
              />
            )}

            <p className="font-cookie">
              {wheel.wheel_pool[index].reward_quantity}{" "}
              {REWARD_NAME[wheel.wheel_pool[index].reward_type] ||
                RewardType[wheel.wheel_pool[index].reward_type]}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
function History({
  items,
  noti,
  page,
  pageChange,
  total,
}: {
  page: number;
  items: HistoryItem[];
  noti: NotiItem[];
  pageChange: Function;
  total: number;
}) {
  const [go, setGo] = useState<number>(0);
  useEffect(() => {
    setGo(page);
  }, [page]);
  function onChange(n: number) {
    if (n > total) {
      return;
    }
    setGo(n);
    pageChange(n);
  }
  return (
    <div className="flex gap-2 mt-4">
      <div className="w-8/12 bg-[#171A25] rounded-3xl py-4 px-12 font-normal h-[250px]">
        <div className="font-cookie text-white opacity-50 flex py-2 border-b border-[#242735]">
          <p className="w-1/3">Time</p>
          <p className="w-1/3">Amount</p>
          <p className="w-1/3">Received</p>
        </div>
        {items.map((i: HistoryItem, index: number) => (
          <div className="font-cookie text-white flex py-2 border-b border-[#242735]">
            <p className="w-1/3">
              {dayjs(i.created_at).format("YYYY-MM-DD HH:mm")}
            </p>
            <p className="w-1/3 text-[#FF3737]">{i.amount} MAG</p>
            <div className="w-1/3">
              <p className="font-cookie text-[#37FFC3]">
                {i.recv_reward.reward_quantity}{" "}
                {REWARD_NAME[i.recv_reward.reward_type] ||
                  RewardType[i.recv_reward.reward_type]}
              </p>
            </div>
          </div>
        ))}
        <div className="flex gap-1 items-center text-white mt-4 font-cookie">
          <p className="font-semibold">Page:</p>
          <input
            type="text"
            value={go}
            className="bg-[#373e58] p-1 rounded-md w-16"
            onChange={(evt: any) => {
              let n = Number(evt.target.value);
              onChange(n);
            }}
          />
          /{total}
        </div>
      </div>
      <div className="w-4/12 bg-[#171A25] rounded-3xl py-4 px-12 font-normal text-white h-[250px] overflow-auto">
        {noti.map((item: NotiItem) => (
          <p className="border-b border-[#242735] py-2">
            <CustomerName name={item.name} /> <span>spin</span>{" "}
            <span className="text-[#37FFC3]">{item.cost} MAG</span> and received{" "}
            <span className="font-cookie text-[#EC772A]">
              {item.win_reward.reward_quantity}{" "}
              {REWARD_NAME[item.win_reward.reward_type] ||
                RewardType[item.win_reward.reward_type]}
            </span>
          </p>
        ))}
      </div>
    </div>
  );
}
const getRewardIcon = (reward: RewardEntity) => {
  switch (reward.reward_type) {
    case RewardType.BATTLEPASS_EXP:
      return "bp-exp.png";
    case RewardType.LOCK_MAG:
      return "s-mag.png";
    case RewardType.UNLOCK_MAG:
      return "mag.png";
    case RewardType.DailyQuestPoint:
      return "quest-point.webp";
    case RewardType.MSTR:
      return "mstr.png";
    case RewardType.SOULCORE:
      //@ts-ignore
      if (reward.race === -1) {
        return "soulcore-icon.svg";
      } else {
        return `egg/${MongenRace[reward.race]?.toLowerCase()}-${
          reward.rarity
        }.webp`;
      }
    case RewardType.PLOT:
      switch (reward.plot_type) {
        // @ts-ignore
        case -1:
          return "plot-random.png";
        case 1:
          if (!MongenRace[reward.race]) {
            return "plot-2-icon.svg";
          }
          return `plots/${PlotType[
            reward.plot_type
          ].toLowerCase()}-${MongenRace[reward.race]?.toLowerCase()}-${
            reward.rarity
          }.webp`;
        case PlotType.Tower:
          if (reward.sub_plot_type) {
            return `plots/${TowerType[
              reward.sub_plot_type
            ].toLowerCase()}-${PlotType[reward.plot_type].toLowerCase()}-${
              reward.rarity
            }.webp`;
          } else {
            return `plots/${PlotType[reward.plot_type].toLowerCase()}-${
              reward.rarity
            }.webp`;
          }
        case PlotType.Trap:
          if (reward.sub_plot_type) {
            return `plots/${TrapType[
              reward.sub_plot_type
            ].toLowerCase()}-${PlotType[reward.plot_type].toLowerCase()}-${
              reward.rarity
            }.webp`;
          } else {
            return `plots/${PlotType[reward.plot_type].toLowerCase()}-${
              reward.rarity
            }.webp`;
          }
        case PlotType.Bombard:
          if (reward.sub_plot_type) {
            return `plots/${BombardType[
              reward.sub_plot_type
            ].toLowerCase()}-${PlotType[reward.plot_type].toLowerCase()}-${
              reward.rarity
            }.webp`;
          } else {
            return `plots/${PlotType[reward.plot_type].toLowerCase()}-${
              reward.rarity
            }.webp`;
          }
        case PlotType.Barracks:
          if (reward.sub_plot_type) {
            return `plots/${BarrackType[
              reward.sub_plot_type
            ].toLowerCase()}-${PlotType[reward.plot_type].toLowerCase()}-${
              reward.rarity
            }.webp`;
          } else {
            return `plots/${PlotType[reward.plot_type].toLowerCase()}-${
              reward.rarity
            }.webp`;
          }
        case PlotType.Camp:
          if (reward.sub_plot_type) {
            return `plots/${CampType[
              reward.sub_plot_type
            ].toLowerCase()}-${PlotType[reward.plot_type].toLowerCase()}-${
              reward.rarity
            }.webp`;
          } else {
            return `plots/${PlotType[reward.plot_type].toLowerCase()}-${
              reward.rarity
            }.webp`;
          }
        default:
          return `plots/${PlotType[reward.plot_type].toLowerCase()}-${
            reward.rarity
          }.webp`;
      }
    case RewardType.EVOLUTION_ITEM:
      return "evo-item-icon.svg";
    case RewardType.RerollRuneStone:
      return "rerolled-rune.png";
    case RewardType.NormalRuneStone:
      return "normal-rune.png";
    case RewardType.NormalStone:
      return "normal-stone.png";
    case RewardType.RerollStone:
      return "rerolled-stone.png";
    case RewardType.BOX:
      return getBoxIcon(reward);
    case RewardType.FOOD:
      return "food.png";
    case RewardType.EXP:
      return "exp.png";
    case RewardType.TICKET:
      switch (reward.rarity as number) {
        case TicketRarity.Common:
          return "ticket-0.png";
        case TicketRarity.Uncommon:
          return "ticket-1.png";
        case TicketRarity.Rare:
          return "ticket-2.png";
        case TicketRarity.Epic:
          return "ticket-3.png";
        case TicketRarity.Legendary:
          return "ticket-4.png";
        case TicketRarity.General:
          return "ticket-5.png";
        case TicketRarity.Global:
          return "ticket-6.png";
        default:
          return "question.webp";
      }
    case RewardType.TROPHY_SCORE:
      return "trophy.png";
    case RewardType.BattleFrontShield:
      return "shield.png";
    case RewardType.Energy:
      return "energy.png";
    case RewardType.DecorationList:
      return `deco/${reward.decoration_code}.webp`;
    case RewardType.Pigment:
      return "pigment.png";
    default:
      return "question.webp";
  }
};
